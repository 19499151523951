import Login from '@/components/backend/views/Login'
import Main from '@/components/backend/views/Main'
import Summary from '@/components/backend/views/Summary'
import Record from '@/components/backend/views/Record'
import RecordOpen from '@/components/backend/components/record/RecordOpen'
import RecordEnd from '@/components/backend/components/record/RecordEnd'
import RecordAdd from '@/components/backend/components/record/recordOperation/RecordAdd'
import RecordView from '@/components/backend/components/record/recordOperation/RecordView'
import RecordEdit from '@/components/backend/components/record/recordOperation/RecordEdit'
import RecordOperation from '@/components/backend/components/record/RecordOperation'
import RecordOperationViewPlan from '@/components/backend/components/record/recordOperation/view/Plan'
import RecordOperationViewUser from '@/components/backend/components/record/recordOperation/view/User'
import User from '@/components/backend/views/User'
import UserFamily from '@/components/backend/components/user/Family'
import UserTotal from '@/components/backend/components/user/Total'
import UserRecord from '@/components/backend/components/user/UserRecord'
import Authority from '@/components/backend/views/Authority'
import Equip from '@/components/backend/views/Equip'
import Point from '@/components/backend/views/Point'

export default [
  {
    path: '/backend',
    redirect: '/backend/login',
    component: Main,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'summary',
        name: 'summary',
        component: Summary,
        meta: {
          title: '首页'
        }
      },
      {
        path: 'record',
        name: 'record',
        component: Record,
        redirect: '/backend/record/open',
        children: [
          {
            path: 'open',
            name: 'recordOpen',
            component: RecordOpen,
            meta: {
              title: '筛查计划管理-已开启计划'
            }
          },
          {
            path: 'end',
            name: 'recordEnd',
            component: RecordEnd,
            meta: {
              title: '筛查计划管理-已结束计划'
            }
          },
          {
            path: 'recordOperation',
            name: 'recordOperation',
            component: RecordOperation,
            children: [
              {
                path: 'view',
                name: 'recordView',
                component: RecordView,
                meta: {
                  title: '筛查计划管理-查看'
                },
                children: [
                  {
                    path: 'plan',
                    name: 'RecordOperationViewPlan',
                    component: RecordOperationViewPlan
                  },
                  {
                    path: 'user',
                    name: 'RecordOperationViewUser',
                    component: RecordOperationViewUser
                  }
                ]
              },
              {
                path: 'edit',
                name: 'recordEdit',
                component: RecordEdit
              }
            ]
          },
          {
            path: 'add',
            name: 'recordAdd',
            component: RecordAdd,
            meta: {
              title: '筛查计划管理-新增'
            }
          }
        ]
      },
      {
        path: 'user',
        name: 'user',
        component: User,
        redirect: '/backend/user/total',
        meta: {
          title: '用户管理'
        },
        children: [
          {
            path: 'total',
            name: 'userTotal',
            component: UserTotal
          },
          {
            path: 'family',
            name: 'userFamily',
            component: UserFamily
          },
          {
            path: 'record',
            name: 'userRecord',
            component: UserRecord
          }
        ]
      },
      {
        path: 'authority',
        name: 'authority',
        component: Authority,
        meta: {
          title: '权限管理'
        }
      },
      {
        path: 'equip',
        name: 'equip',
        component: Equip,
        meta: {
          title: '设备管理'
        }
      },
      {
        path: 'point',
        name: 'point',
        component: Point,
        meta: {
          title: '筛查点管理'
        }
      }
    ]
  },
  {
    path: '/backend/login',
    name: 'login',
    component: Login
  }
]
