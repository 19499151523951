<template>
  <div class="record">
    <header class="header">
      <h3 class="component__title">关联家庭成员</h3>
      <div class="back" @click="back">
        <i class="icon icon__back"></i>
        返回
      </div>
    </header>
    <main class="main">
      <div class="table-wrapper">
        <div class="table" v-for="(table, index) in tableData" :key="table.name + index">
          <h3 class="table-name">{{table.name}}({{relationshipMap[table.relationship]}})</h3>
          <div class="table__inner">
            <el-table
              :data="table.record"
              v-loadmore="loadMore"
              height="100%"
              max-height="400"
            >
              <el-table-column type="index" label="序号" width="80" align="center" />
              <el-table-column label="报名状态" align="center">
                <template #default="scope">
                  {{parseInt(scope.row.signState) === 0 ? '取消报名' : '已报名'}}
                </template>
              </el-table-column>
              <el-table-column label="报名时间" align="center">
                <template #default="scope">
                  {{formatDateYYMMDDHHMMSS(scope.row.signTime)}}
                </template>
              </el-table-column>
              <el-table-column property="habitation" label="报名区域" align="center"></el-table-column>
              <el-table-column property="pointName" label="报名筛查点" align="center"></el-table-column>
              <el-table-column label="是否现场筛查" width="80" align="center">
                <template #default="scope">
                  {{parseInt(scope.row.isLive) === 1 ? '现场报名' : '线上报名'}}
                </template>
              </el-table-column>
              <el-table-column property="orderState" label="预约状态" align="center">
                <template #default="scope">
                  {{parseInt(scope.row.orderState) === 0 ? '未预约' : parseInt(scope.row.orderState) === 1 ? '已预约' : '取消预约'}}
                </template>
              </el-table-column>
              <el-table-column label="预约时间" align="center">
                <template #default="scope">
                  {{formatDateYYMMDDHHMMSS(scope.row.orderTime)}}
                </template>
              </el-table-column>
              <el-table-column label="预约筛查时间" width="120" align="center">
                <template #default="scope">
                  {{formatDateYYMMDDHHMMSS(scope.row.checkDate)}}
                </template>
              </el-table-column>
              <el-table-column property="planId" label="筛查计划ID" align="center"></el-table-column>
              <el-table-column label="筛查状态" align="center">
                <template #default="scope">
                  {{parseInt(scope.row.checkState) === 0 ? '未筛查' : parseInt(scope.row.checkState) === 1 ? '已筛查' : parseInt(scope.row.checkState) === 2 ? '已完成' : '已逾期'}}
                </template>
              </el-table-column>
              <el-table-column label="筛查结果" align="center">
                <template #default="scope">
                  {{parseInt(scope.row.result) === 0 ? '阳性' : parseInt(scope.row.result) === 1 ? '阴性' : '--'}}
                </template>
              </el-table-column>
              <el-table-column label="绑定试管时间" width="120" align="center">
                <template #default="scope">
                  {{formatDateYYMMDDHHMMSS(scope.row.bindingTime)}}
                </template>
              </el-table-column>
              <el-table-column property="bindingId" width="120" label="绑定试管ID" align="center"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { getUserRecord, getUserAndFamilyRecordList } from '@/components/backend/assets/api/request'
import { formatDateYYMMDDHasSplit, formatDateYYMMDDHHMMSS } from '@/components/backend/assets/js/util'
import { relationshipMap } from '@/components/backend/assets/js/config'

export default {
  name: 'UserRecord',
  directives: {
    loadmore: {
      mounted (el, binding) {
        const selectWrap = el.querySelector('.el-table__body-wrapper')
        selectWrap.addEventListener('scroll', function () {
          const sign = 0
          const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight
          if (scrollDistance <= sign) {
            binding.value()
          }
        })
      }
    }
  },
  setup () {
    onMounted(() => {
      if (route.query.uuid) {
        uuid.value = route.query.uuid
        handleGetUserRecord()
      } else if (route.query.userId) {
        userId.value = route.query.userId
        handleGetUserAndFamilyRecordList()
      } else {
        ElMessageBox.alert('参数错误！', '提示', {
          confirmButtonText: '确定'
        }).then(() => {
          router.push({
            name: 'userTotal'
          })
        })
      }
    })
    const route = useRoute()
    const router = useRouter()
    const tableData = reactive([])
    const userId = ref('')
    const uuid = ref('')

    /**
     * 点击返回上一页面
     */
    function back () {
      router.go(-1)
    }

    /**
     * table滚动到底时触发加载更多
     */
    function loadMore () {
      // console.log('load more!')
    }

    /**
     * 根据uuid获取用户记录
     */
    function handleGetUserRecord () {
      getUserRecord(
        {
          uuid: uuid.value
        },
        res => {
          tableData.splice(0, tableData.length)
          tableData.push({
            name: res.data.data[0].username,
            relationship: route.query.relationship,
            record: res.data.data
          })
        }
      )
    }

    /**
     * 根据主用户id获取用户与家庭成员的记录
     */
    function handleGetUserAndFamilyRecordList () {
      getUserAndFamilyRecordList(
        {
          id: userId.value
        },
        res => {
          tableData.splice(0, tableData.length)
          tableData.push({
            name: res.data.data.user.username,
            relationship: res.data.data.user.relationship,
            record: res.data.data.user.records
          })
          for (let i = 0; i < res.data.data.family.length; i++) {
            tableData.push({
              name: res.data.data.family[i].username,
              relationship: res.data.data.family[i].relationship,
              record: res.data.data.family[i].records
            })
          }
        },
        err => {
          ElMessageBox.alert(err.data.message, '提示', {
            confirmButtonText: '确定'
          }).then(() => {
            router.go(-1)
          })
        }
      )
    }

    return {
      back,
      tableData,
      loadMore,
      formatDateYYMMDDHasSplit,
      formatDateYYMMDDHHMMSS,
      relationshipMap
    }
  }
}
</script>

<style scoped>
.record {
  height: calc(100% - 36px);
}
.component__title {
  text-align: left;
  color: rgba(10, 36, 99, 1);
  font-size: 16px;
  font-weight: 600;
}
.header {
  display: flex;
  align-items: center;
  height: 36px;
  position: relative;
}
.back {
  position: absolute;
  top: 50%;
  right: 0;
  height: 34px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.icon__back {
  display: inline-block;
  width: 29px;
  height: 34px;
  margin-right: 8px;
  background: url("../../assets/images/icon__back.png") no-repeat center center;
  background-size: 85%;
}
.main {
  height: calc(100% - 36px);
  width: 100%;
}
.main .table-wrapper {
  max-height: 100%;
  /*overflow-y: scroll;*/
  overflow-x: hidden;
}
.main .table-wrapper .table {
  margin: 20px 0;
}
.main .table-wrapper .table .table__inner {
  width: calc(100% + 16px);
}
.main .table-wrapper .table-name {
  margin: 20px 0;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #0A2463;
}
.main :deep(.el-table th.el-table__cell) {
  font-size: 15px;
  color: #888888;
  background: #F2F3F7;
}
.main :deep(.el-table .el-table__body .cell) {
  /*height: 33px;*/
  line-height: 20px;
  font-size: 14px;
  color: #0A2463;
}
.main .operate-button {
  margin: 0;
  padding: 0 5px;
  height: 28px;
  font-size: 12px;
  background: #318AFF;
  color: #FFFFFF;
}
.pagination {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #7D7D7D;
}
.record {
  position: relative;
}
</style>
