<template>
  <div class="wrapper">
    <sidebar class="sidebar"></sidebar>
    <div class="main" id="main">
      <my-header></my-header>
      <el-tabs
        v-model="tabs.activeTab"
        type="card"
        closable
        @tab-click="handleTabsChange"
        @tab-remove="removeTab"
      >
        <el-tab-pane
          v-for="(tab, index) in tabs.value"
          :key="tab.value"
          :label="tab.label"
          :name="tab.component"
        >
          <router-view v-if="show[index]"></router-view>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import MyHeader from '@/components/backend/components/MyHeader'
import Sidebar from '@/components/backend/components/Sidebar'
import { reactive, watch, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { menuMap } from '@/components/backend/assets/js/config'
import { useStore } from 'vuex'
import Storage from '@/components/backend/assets/js/LocalStorage'
import { ElMessageBox } from 'element-plus'

export default {
  name: 'Main',
  setup () {
    onMounted(() => {
      if (JSON.stringify(user.value) === '{}') {
        const storageUser = storage.getItem('__user__')
        // console.log(storageUser.user)
        if (!(storageUser.user === undefined)) {
          store.dispatch('saveUser', storageUser.user)
          const mainRoute = route.path.split('/')[2]
          const routeName = route.name
          if (menuMap[mainRoute]) {
            tabs.activeTab = routeName
            show[0] = true
            tabs.value.push(
              {
                value: mainRoute,
                label: menuMap[mainRoute],
                component: routeName,
                mainRoute: mainRoute
              }
            )
          }
        } else {
          ElMessageBox.alert('未登录，请先登录！', '错误', {
            confirmButtonText: '确定',
            type: 'error'
          }).then(() => {
            router.push({
              name: 'login'
            })
          })
        }
      } else {
        const mainRoute = route.path.split('/')[2]
        const routeName = route.name
        if (menuMap[mainRoute]) {
          tabs.activeTab = routeName
          show[0] = true
          tabs.value.push(
            {
              value: mainRoute,
              label: menuMap[mainRoute],
              component: routeName,
              mainRoute: mainRoute
            }
          )
        }
      }
    })
    const store = useStore()
    const user = computed(() => store.state.user)
    const storage = new Storage()
    const tabs = reactive({
      activeTab: undefined,
      value: []
    })
    const show = reactive([])
    const tabsValue = computed(() => {
      return tabs.value.map(tab => { return tab.value })
    })
    const route = useRoute()
    const router = useRouter()

    watch(route, (newRoute) => {
      const mainRoute = newRoute.path.split('/')[2]
      const routeLabel = menuMap[mainRoute]
      const routeName = newRoute.name
      if (routeLabel) {
        const index = tabsValue.value.indexOf(mainRoute)
        for (let i = 0; i < show.length; i++) {
          if (show[i]) {
            show[i] = false
          }
        }
        if (index === -1) {
          tabs.value.push(
            {
              value: mainRoute,
              label: routeLabel,
              component: routeName,
              mainRoute: mainRoute
            }
          )
          show.push(true)
        } else {
          tabs.value[index].component = routeName
          show[index] = true
        }
      }
      tabs.activeTab = routeName
    })

    function handleTabsChange (tab) {
      router.push({
        name: tab.props.name
      })
    }

    function removeTab (tab) {
      // const mainRoute = newRoute.path.split('/')[2]
      for (let i = 0; i < tabs.value.length; i++) {
        const tabName = tabs.value[i].component
        if (tab === tabName) {
          if (tabs.value.length > 1) {
            tabs.value.splice(i, 1)
            show.splice(i, 1)
            if (tabs.activeTab === tabName) {
              // tabs.activeTab = tabs.value[0].component
              // show[0] = true
              router.push({
                name: tabs.value[0].mainRoute
              })
            }
          }
          break
        }
      }
    }

    return {
      tabs,
      show,
      handleTabsChange,
      removeTab
    }
  },
  components: {
    MyHeader,
    Sidebar
  }
}
</script>

<style scoped>
/* tabs的样式穿透了子组件中的tabs样式 */
.wrapper {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FBFBFB;
}
.sidebar {
  flex: 0 0 auto;
  flex-basis: 380px;
}
#main {
  position: relative;
}
.main {
  flex: 1 0 auto;
  width: calc(100% - 380px);
  min-height: 100%;
}
.main :deep(.el-tabs__nav-scroll .el-tabs__nav) {
  border: 0;
}
.main :deep(.el-tabs__nav-scroll) {
  background-color: #136FD1;
  padding-left: 40px;
}
.main :deep(.el-tabs__nav-scroll .el-tabs__nav .el-tabs__item) {
  /*line-height: 40px;*/
  color: #FFFFFF;
  border: 0;
  position: relative;
}
.main :deep(.el-tabs__nav-scroll .el-tabs__nav .el-tabs__item::after) {
  content: '';
  position: absolute;
  width: 0;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: #FFD11F;
  transition: all ease-in .2s;
}
.main :deep(.el-tabs__nav-scroll .el-tabs__nav .el-tabs__item.is-active::after) {
  width: 100%;
}
.main :deep(.el-tabs) {
  width: 100%;
  height: calc(100% - 68px);
  margin-bottom: 0;
}
.main :deep(.el-tabs__content) {
  width: 100%;
  height: calc(100% - 40px);
  overflow-x: hidden;
}
.main :deep(.el-tab-pane) {
  width: calc(100% + 16px);
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.main :deep(.el-tabs--card .el-tabs__header) {
  margin-bottom: 0;
}
/*:deep(.el-tabs__active-bar) {*/
/*  bottom: 2px;*/
/*  height: 4px;*/
/*  background-color: #FFD11F;*/
/*}*/
</style>
