<template>
  <div>
    <h3 class="title">筛查点：{{point}}</h3>
    <div class="calendar-picker">
      <div class="calendar__header">
        <div class="calendar__header-group">
          <i class="calendar__header-group__button icon-prev" @click="handlePrevYear"></i>
          <span class="calendar__header-group__text">{{date.selectYear}}年</span>
          <i class="calendar__header-group__button icon-next" @click="handleNextYear"></i>
        </div>
        <div class="refresh" @click="refresh">重置</div>
        <div class="calendar__header-group">
          <i class="calendar__header-group__button icon-prev" @click="handlePrevMonth"></i>
          <span class="calendar__header-group__text">{{date.selectMonth}}月</span>
          <i class="calendar__header-group__button icon-next" @click="handleNextMonth"></i>
        </div>
      </div>
      <div class="calendar__body">
        <table class="calendar-table">
          <thead>
            <tr>
              <th>一</th>
              <th>二</th>
              <th>三</th>
              <th>四</th>
              <th>五</th>
              <th>六</th>
              <th>日</th>
            </tr>
          </thead>
          <tbody>
            <tr class="calendar-table__row" v-for="(line, index) in dateList" :key="'line' + index">
              <td :class="dayCls(index, count)" v-for="count in line" :key="'day' + count">
                <div class="calendar-day" @click="handleSelectDate(index, count)">
                  <span>{{count}}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="time-picker">
      <div class="time-wrapper" v-for="time in canAppointTime.data" :key="time.time">
        <div class="time" :class="(time.num === 0 || !time.isAble) ? 'is-disable' : time.endTime === selectTime.endTime ? 'is-checked' : ''" @click="handleSelectTime(time)">
          <div class="time__inner-time">{{time.startTime}} ~ {{time.endTime}}</div>
          <div class="time__inner-num">(剩余{{time.num}}个)</div>
        </div>
      </div>
    </div>
    <el-button class="button__default" @click="handleSubmit">预约</el-button>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { pad, formatDateYYMMDDHasSplit, JudgeTime } from '@/assets/js/util'
import { getPointPlan, userOrder } from '@/assets/api/request'

export default {
  name: 'AppointmentDate',
  setup () {
    onMounted(() => {
      handleGetPointPlan()
    })
    const route = useRoute()
    const router = useRouter()
    const plan = reactive([])
    const canAppointDate = reactive([])
    const canAppointTime = reactive({ data: [] })
    const selectTime = reactive({
      date: '',
      startTime: '',
      endTime: '',
      planId: ''
    })
    const point = ref('')
    const today = new Date()
    const date = reactive({
      currentDay: today.getDate(),
      currentMonth: today.getMonth() + 1,
      currentYear: today.getFullYear(),
      selectMonth: today.getMonth() + 1,
      selectYear: today.getFullYear(),
      selectDay: ''
    })
    const preMonthDays = computed(() => {
      return new Date(date.selectYear, date.selectMonth - 1, 0).getDate()
    })
    const currentDays = computed(() => {
      return new Date(date.selectYear, date.selectMonth, 0).getDate()
    })
    const offsetDay = computed(() => {
      return (new Date(date.selectYear, date.selectMonth - 1, 1).getDay() - 1 + 7) % 7
    })

    const dateList = computed(() => {
      const result = []
      result[0] = []
      for (let i = 0; i < offsetDay.value; i++) {
        result[0].push(preMonthDays.value - i)
      }
      result[0].reverse()
      for (let i = 0; i < 7 - offsetDay.value; i++) {
        result[0].push(i + 1)
      }
      for (let i = 1; i < 4; i++) {
        result[i] = []
        for (let j = 1; j <= 7; j++) {
          result[i].push(j + (7 - offsetDay.value) + 7 * (i - 1))
        }
      }
      result[4] = []
      const len = currentDays.value - ((7 - offsetDay.value) + 21) <= 7 ? currentDays.value - ((7 - offsetDay.value) + 21) : 7
      for (let i = 1; i <= len; i++) {
        result[4].push(i + (7 - offsetDay.value) + 7 * 3)
      }
      const lenFour = result[4].length
      if (lenFour !== 7) {
        for (let i = 1; i <= 7 - lenFour; i++) {
          result[4].push(i)
        }
        result[5] = []
        for (let i = 7 - lenFour + 1; i <= 7 + 7 - lenFour; i++) {
          result[5].push(i)
        }
      } else {
        result[5] = []
        for (let i = 5 * 7 - offsetDay.value + 1; i <= currentDays.value; i++) {
          result[5].push(i)
        }
        const len5 = result[5].length
        for (let i = 1; i <= 7 - len5; i++) {
          result[5].push(i)
        }
      }
      return result
    })

    function dayCls (index, count) {
      if (index === 0 && count > 7) {
        return 'prev'
      } else if ((index === 4 || index === 5) && count <= 14) {
        return 'next'
      } else {
        for (let i = 0; i < canAppointDate.length; i++) {
          if (new Date(date.selectYear, date.selectMonth - 1, count, 8, 0).getTime() === new Date(canAppointDate[i]).getTime()) {
            if (count === date.selectDay) {
              return 'current is-active is-checked'
            }
            return 'current is-active'
          }
        }
        return 'current'
      }
    }

    function handleSelectDate (index, count) {
      if ((index === 0 && count > 7) || ((index === 4 || index === 5) && count <= 14)) {
        ElMessage({
          message: '这一天没有预约计划！',
          type: 'error',
          duration: 2000,
          center: true
        })
      } else {
        const dateStr = `${date.selectYear}-${pad(date.selectMonth)}-${pad(count)}`
        const index = canAppointDate.indexOf(dateStr)
        if (index !== -1) {
          date.selectDay = count
          canAppointTime.data = plan[index].canAppointTime
          for (let i = 0; i < canAppointTime.data.length; i++) {
            const str = `${date.selectYear}/${pad(date.selectMonth)}/${pad(date.selectDay)} ${canAppointTime.data[i].endTime}`
            canAppointTime.data[i].isAble = JudgeTime(str)
          }
          selectTime.date = ''
          selectTime.startTime = ''
          selectTime.endTime = ''
          selectTime.planId = ''
        } else {
          ElMessage({
            message: '这一天没有预约计划！',
            type: 'error',
            duration: 2000,
            center: true
          })
        }
        // for (let i = 0; i < canAppointDate.length; i++) {
        //   console.log(new Date(date.selectYear, date.selectMonth - 1, count, 8, 0).getTime())
        //   console.log(new Date(canAppointDate[i]).getTime())
        //   if (new Date(date.selectYear, date.selectMonth - 1, count, 8, 0).getTime() === new Date(canAppointDate[i]).getTime()) {
        //     date.selectDay = count
        //   }
        // }
      }
    }

    function handlePrevYear () {
      date.selectYear--
    }

    function handleNextYear () {
      date.selectYear++
    }

    function handlePrevMonth () {
      if (date.selectMonth === 1) {
        date.selectYear--
        date.selectMonth = 12
      } else {
        date.selectMonth--
      }
    }

    function handleNextMonth () {
      if (date.selectMonth === 12) {
        date.selectYear++
        date.selectMonth = 1
      } else {
        date.selectMonth++
      }
    }

    function refresh () {
      date.selectMonth = date.currentMonth
      date.selectYear = date.currentYear
    }

    function handleGetPointPlan () {
      if (route.query.pointId) {
        getPointPlan(
          {
            id: route.query.pointId
          },
          res => {
            // canAppointDate.data = []
            point.value = res.data.data.plan.pointName
            plan.splice(0, plan.length)
            canAppointDate.splice(0, canAppointDate.length)
            res.data.data.planByDays.forEach(_plan => {
              const dateStr = formatDateYYMMDDHasSplit(_plan.planDate)
              const index = canAppointDate.indexOf(formatDateYYMMDDHasSplit(_plan.planDate))
              if (index === -1) {
                canAppointDate.push(dateStr)
                plan.push({
                  canAppointDate: dateStr,
                  canAppointTime: [{
                    planId: _plan.planId,
                    startTime: _plan.startTime,
                    endTime: _plan.endTime,
                    num: _plan.maxNumber
                  }]
                })
                for (let i = 0; i < res.data.data.count.length; i++) {
                  if (_plan.planDate === res.data.data.count[i].checkDate && _plan.startTime === res.data.data.count[i].checkStartTime && _plan.endTime === res.data.data.count[i].checkEndTime) {
                    plan[plan.length - 1].canAppointTime[0].num = _plan.maxNumber - res.data.data.count[i].sum
                    break
                  }
                }
              } else {
                plan[index].canAppointTime.push({
                  planId: _plan.planId,
                  startTime: _plan.startTime,
                  endTime: _plan.endTime,
                  num: _plan.maxNumber
                })
                for (let i = 0; i < res.data.data.count.length; i++) {
                  if (_plan.planDate === res.data.data.count[i].checkDate && _plan.startTime === res.data.data.count[i].checkStartTime && _plan.endTime === res.data.data.count[i].checkEndTime) {
                    plan[index].canAppointTime[plan[index].canAppointTime.length - 1].num = _plan.maxNumber - res.data.data.count[i].sum
                    break
                  }
                }
              }
            })
            let flag = true
            for (let i = 0; i < plan[0].canAppointTime.length; i++) {
              if (JudgeTime(`${plan[0].canAppointDate} ${plan[0].canAppointTime[i].endTime}`)) {
                flag = false
                break
              }
            }
            if (flag) {
              plan.shift()
              canAppointDate.shift()
            }
            if (plan[0]) {
              handleSelectDate(1, new Date(plan[0].canAppointDate).getDate())
            }
            // for (let i = 0; i < plan.length; i++) {
            //   const len = plan[i].canAppointTime.length
            //   let minIndex, temp
            //   for (let j = 0; j < len - 1; j++) {
            //     minIndex = j
            //     for (let k = j + 1; k < len; k++) {
            //       if (new Date('2021-01-01' + plan[i].canAppointTime[k]).getTime() < new Date('2021-01-01' + plan[i].canAppointTime[minIndex]).getTime()) {
            //         minIndex = k
            //       }
            //     }
            //     temp = plan[i].canAppointTime[j]
            //     plan[i].canAppointTime[j] = plan[i].canAppointTime[minIndex]
            //     plan[i].canAppointTime[minIndex] = temp
            //   }
            // }
          }
        )
      } else {
        ElMessage({
          message: '未能查找到报名筛查点id，请回退重新进入预约！',
          type: 'error',
          duration: 2000,
          center: true
        })
      }
    }

    function handleSelectTime (time) {
      selectTime.date = `${date.selectYear}-${pad(date.selectMonth)}-${pad(date.selectDay)}`
      selectTime.startTime = time.startTime
      selectTime.endTime = time.endTime
      selectTime.planId = time.planId
    }

    function handleSubmit () {
      if (!selectTime.date || !selectTime.startTime || !selectTime.endTime) {
        ElMessage({
          message: '未选择预约时间！',
          type: 'error',
          duration: 2000,
          center: true
        })
      } else {
        userOrder(
          {
            id: route.query.recordId,
            planId: selectTime.planId,
            checkDate: selectTime.date,
            checkStartTime: selectTime.startTime,
            checkEndTime: selectTime.endTime
          },
          res => {
            ElMessage({
              message: '预约成功！',
              type: 'success',
              duration: 2000,
              center: true
            })
            setTimeout(() => {
              router.push({
                path: '/screenRecord',
                query: {
                  uuid: route.query.uuid,
                  username: route.query.username
                }
              })
            }, 1000)
          }
        )
      }
    }

    return {
      point,
      date,
      dateList,
      dayCls,
      handleSelectDate,
      handlePrevYear,
      handleNextYear,
      handlePrevMonth,
      handleNextMonth,
      refresh,
      canAppointTime,
      plan,
      canAppointDate,
      handleSelectTime,
      handleSubmit,
      selectTime
    }
  }
}
</script>

<style scoped>
.calendar-picker {
  width: 100%;
}
.title {
  padding: 20px 16px;
  text-align: left;
  font-size: 15px;
  font-weight: 700;
  color: #0A2463;
}
.calendar-picker {
  font-size: 15px;
  background-color: #F8FAFF;
  border-bottom: 1px solid #8491B1;
}
.calendar__header {
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
}
.calendar__header-group {
  display: flex;
  align-items: center;
}
.refresh {
  padding: 5px 10px;
  font-size: 10px;
  border-radius: 2px;
  color: #136FD1;
  border: 1px solid #8491B1;
  letter-spacing: 2px;
}
.calendar__header-group__text {
  padding: 0 25px;
  color: #0A2463;
  font-size: 15px;
}
.calendar__header-group__button {
  width: 9px;
  height: 18px;
}
.icon-prev {
  background: url("../../../assets/images/icon__prev.png") no-repeat center center;
  background-size: 100%;
}
.icon-next {
  background: url("../../../assets/images/icon__next.png") no-repeat center center;
  background-size: 100%;
}
.calendar-table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}
.calendar-table thead th {
  padding: 12px 0;
  font-weight: 400;
  color: #0A2463;
}
.calendar__body .prev, .current, .next {
  padding: 8px;
  text-align: center;
}
.calendar-table .calendar-day {
  display: inline-block;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  line-height: 25px;
  color: #8491B1;
}
.current.is-active .calendar-day{
  color: #136FD1;
}
.current.is-active.is-checked .calendar-day {
  background-color: #136FD1;
  color: #FFFFFF;
  border-radius: 2px;
}
.time-wrapper {
  margin: 12px 0;
  flex-basis: 50%;
  display: flex;
}
.time-wrapper:nth-child(2n + 1) {
  justify-content: flex-start;
}
.time-wrapper:nth-child(2n) {
  justify-content: flex-end;
}
.time {
  width: 150px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 14px;
  font-size: 14px;
  color: #0A2463;
  border: 1px solid #8491B1;
  border-radius: 4px;
}
.time.is-disable {
  color: #8491B1;
  border-color: #EDEDED;
}
.time.is-checked {
  color: #0A2463;
  background-color: #8491B1;
}
.time__inner-time {
  margin-bottom: 10px;
}
.time-picker {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
}
.button__default {
  width: calc(100% - 32px);
  margin: 20px auto;
}
@media screen and (max-width: 320px) {
  .time {
    width: 120px;
  }
}
</style>
