<template>
  <teleport to="#main">
    <div :class="show ? 'frame-wrapper' : 'frame-wrapper__close'">
      <div :class="show ? 'frame' : 'frame__close'">
        <header class="header">
          {{title}}
          <el-button type="text" class="header-button__text" @click="close">关闭</el-button>
        </header>
        <div class="main">
          {{text}}
        </div>
        <div class="footer">
          <el-button v-if="btnLeft" class="footer__btn footer__btn__left" @click="$emit('clickLeft')">{{btnLeft}}</el-button>
          <el-button v-if="btnRight" class="footer__btn footer__btn__right" @click="$emit('clickRight')">{{btnRight}}</el-button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'FrameOnlyText',
  emits: ['update:show', 'clickLeft', 'clickRight', 'frameClose'],
  props: {
    show: Boolean,
    text: String,
    title: String,
    btnLeft: String,
    btnRight: String
  },
  setup (props, context) {
    function close () {
      context.emit('frameClose')
      context.emit('update:show', false)
    }

    return {
      close
    }
  }
}
</script>

<style scoped>
.frame-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
  transition: all ease-in .3s;
}
.frame-wrapper__close {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0);
  transition: all ease-in .3s;
}
.frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  max-width: 90%;
  font-size: 15px;
  background: #EDEDED;
  transition: all ease-in .5s;
  box-sizing: border-box;
  overflow: hidden;
}
.frame__close {
  position: absolute;
  padding: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  max-height: 0;
  max-width: 0;
  transition: all ease-in .5s;
  overflow: hidden;
}
.header {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  color: #FFFFFF;
  background: #136FD1;
  position: relative;
}
.header-button__text {
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
}
.main {
  padding: 60px;
  width: 580px;
  box-sizing: border-box;
  text-align: center;
  background: #FFFFFF;
}
.footer {
  height: 50px;
  line-height: 50px;
  background: #F2F3F7;
}
.footer__btn {
  width: 108px;
  height: 34px;
  color: #FFFFFF;
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
}
.footer__btn__left {
  color: #000000;
  background: #FFFFFF;
}
.footer__btn__right {
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
}
</style>
