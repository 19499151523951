<template>
  <div class="recordOperate">
    <record-operation-header></record-operation-header>
    <router-view class="main"></router-view>
  </div>
</template>

<script>
import RecordOperationHeader from '@/components/backend/components/record/recordOperation/RecordOperationHeader'

export default {
  name: 'RecordOperate',
  components: {
    RecordOperationHeader
  }
}
</script>

<style scoped>
.recordOperate {
  margin: 0;
  height: calc(100% - 36px);
  font-size: 14px;
}
.main {
  height: calc(100% - 80px);
  overflow: inherit;
}
</style>
