<template>
  <div class="deal-dialog">
    <el-dialog
      v-model="visible"
      :show-close="false"
      :before-close="handleCancel"
      title="用户信息与隐私协议"
    >
      <div class="content">
        <p>一、适用范围</p>
        <p>1. 本政策仅适用于幽门螺杆菌筛查公众号的运营主体及服务可能存在的运营关联单位，具体指深圳甲田科技有限公司（简称甲田公司）及其关联公司。使用前述幽门螺杆菌筛查公众号服务的使用人在本协议中称为“用户”，或称为“您”。</p>
        <p>2.
          请您在使用幽门螺杆菌筛查公众号各项产品及服务前，仔细阅读并充分理解本隐私保护政策协议，重点内容我们已经用粗体表示，请您特别关注。您在点击“确认”按钮后，本政策即构成对双方有约束力的法律文件，即表示您同意甲田公司按照本政策收集、使用、处理和存储您的相关信息。
          如果您对本隐私政策有任何疑问、意见或建议，可通过本政策第5条提供的联系方式与我们联系。您和我们就本隐私权政策内容或其执行发生任何争议的，双方应友好协商解决；如双方无法协商解决争议时，双方同意应将争议提交至甲田公司所在地有管辖权的人民法院诉讼解决。</p>
        <p>3.
          甲田公司深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，并恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，甲田公司承诺，采取相应的安全保护措施来保护您的个人信息。</p>
        <p>二、隐私保护政策具体内容</p>
        <p>1 我们如何收集和使用您的个人信息</p>
        <p>我们会按照如下方式收集您在使用服务时主动提供的、以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息：</p>
        <p>1.1报名、登录：</p>
        <p>当您报名、关注幽门螺杆菌筛查公众号并使用相关功能及相关服务时，需要通过手机号创建帐号，因报名信息推送与幽门螺杆菌筛查相关要求，信息包括手机号、姓名、性别、出生年月日和住址。</p>
        <p>1.2预约</p>
        <p>您可在幽门螺杆菌筛查公众号进行在线预约。在您使用该功能的过程中，因预约信息推送与幽门螺杆菌筛查相关要求，需要您提供您的身份信息，包括手机号、姓名、性别、出生年月日和住址、详细地址信息。</p>
        <p>2.我们如何共享、转让、公开披露您的个人信息</p>
        <p>2.1共享</p>
        <p>我们不会向甲田公司以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：</p>
        <p>2.1.1在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
        <p>2.1.2我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</p>
        <p>2.1.3与我们的附属公司共享：您的个人信息可能会与甲田公司的附属公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。附属公司如要改变个人信息的处理目的，将再次征求您的授权同意。</p>
        <p>
          2.1.4
          与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和科学研究。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。
        </p>
        <p>
          2.1.5 我们将根据如下约定向甲田公司共享您的相关信息，但我们在传输过程中将运用各种安全技术和程序对数据进行加密处理：</p>
        <p>
          （1）您授权甲田公司，除法律另有规定之外，将您提供给甲田公司的信息、享受甲田公司服务产生的信息（包括本用户协议签署之前提供和产生的信息）以及甲田公司根据本条约定查询、收集的信息，用于甲田公司及其因服务必要委托的合作伙伴为您提供服务、推荐产品、开展科学研究、数据分析
        </p>
        <p>
          （2）您授权甲田公司，除法律另有规定之外，基于为您提供更优质服务和产品的目的，向甲田公司因服务必要开展合作的伙伴提供、查询、收集您的信息。
        </p>
        <p>
          （3）为确保您信息的安全，甲田公司及其合作伙伴对上述信息负有保密义务，并采 取各种措施保证信息安全。
        </p>
        <p>
          （4）本条款自本协议签署时生效，具有独立法律效力，不受合同成立与否及效力状态 变化的影响。
        </p>
        <p>
          2.2转让
        </p>
        <p>
          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </p>
        <p>
          3.2.1在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
        </p>
        <p>
          3.2.2在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p>
          3.3公开披露
        </p>
        <p>
          我们仅会在以下情况下，公开披露您的个人信息：
        </p>
        <p>
          3.3.1获得您明确同意后；
        </p>
        <p>
          3.3.2基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息，主要包括以下情景：
        </p>
        <p>
          a.与国家安全、国防安全直接相关的；
        </p>
        <p>
          b.与公共安全、公共卫生、重大公共利益直接相关的；
        </p>
        <p>
          c.与犯罪侦查、起诉、审判和判决执行等直接相关的；
        </p>
        <p>
          d.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        </p>
        <p>
          e.所收集的您的个人信息是您自行向社会公众公开的；
        </p>
        <p>
          f.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
        </p>
        <p>
          g.根据您的要求签订或履行合同所必需的；
        </p>
        <p>
          h.用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；
        </p>
        <p>i.为合法的新闻报道所必需的；</p>
        <p>j.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
        <p>k.法律法规规定的其他情形。</p>
        <p>
          特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。</p>
        <p>3.我们如何存储和保护您的个人信息</p>
        <p>3.1 存储地点</p>
        <p>我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将遵循相关国家规定或者征求您的同意。</p>
        <p>3.2存储期限</p>
        <p>我们仅在为提供“幽门螺杆菌筛查公众号”及服务之目的所必须的期间内保存您的个人信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。</p>
        <p>3.3 技术措施与数据保护措施</p>
        <p>
          我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</p>
        <p>3.4 安全事件处理</p>
        <p>
          在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
          同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
        <p>4.您的权利</p>
        <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>
        <p>4.1访问您的个人信息</p>
        <p>您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：</p>
        <p>幽门螺杆菌筛查公众号——个人中心</p>
        <p>4.2更正您的个人信息</p>
        <p>当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过4.1访问您的个人信息”中罗列的方式提出更正申请。</p>
        <p>4.3删除您的个人信息</p>
        <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <p>1、如果我们处理个人信息的行为违反法律法规；</p>
        <p>2、如果我们收集、使用您的个人信息，却未征得您的同意；</p>
        <p>3、如果我们处理个人信息的行为违反了与您的约定；</p>
        <p>4、如果您不再使用我们的产品或服务，或您注销了账号；</p>
        <p>5、如果我们不再为您提供产品或服务。</p>
        <p>若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</p>
        <p>当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。如果您想行使数据删除权，可通过本政策第5条提供的联系方式与我们联系。</p>
        <p>4.4改变您授权同意的范围</p>
        <p>
          每个业务功能需要一些基本的个人信息才能得以完成，对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意，终止定向推送授权可通过访问甲田公司—>我的—>消息—>消息设置，其他授权可通过本政策第5条提供的联系方式与我们联系进行授权变更。</p>
        <p>当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
        <p>4.5个人信息主体注销账户</p>
        <p>您随时可注销此前注册的账户，可通过访问甲田公司—>我的—>设置—>注销账户。</p>
        <p>在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。</p>
        <p>4.6个人信息主体获取个人信息副本</p>
        <p>您有权获取您的个人信息副本，可通过本政策第7条提供的联系方式与我们联系获得个人信息副本。</p>
        <p>在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的个人信息副本传输给您指定的第三方。</p>
        <p>4.7约束信息系统自动决策</p>
        <p>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。</p>
        <p>4.8响应您的上述请求</p>
        <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在十五天内做出答复。</p>
        <p>
          对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</p>
        <p>在以下情形中，我们将无法响应您的请求：</p>
        <p>1、与个人信息控制者履行法律法规规定的义务相关的；</p>
        <p>2、与国家安全、国防安全直接相关的；</p>
        <p>3、与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p>4、与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
        <p>5、个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
        <p>6、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
        <p>7、响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p>
        <p>8、涉及商业秘密的。</p>
        <p>5.如何联系我们</p>
        <p>我们已经设立了个人信息保护负责人员，如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：</p>
        <p>电话：0755 22674783</p>
        <p>如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。</p>
      </div>
      <div class="dialog-footer">
        <el-button
          class="button__default"
          @click="handlePrimary"
        >
          同意
          <span v-if="count >= 0">({{count}}S)</span>
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { ElMessage } from 'element-plus'

export default {
  name: 'DealDialog',
  emits: ['handlePrimary', 'handleCancel'],
  props: {
    dialogVisible: Boolean,
    primaryBtnText: String,
    cancelBtnText: String
  },
  setup (props, context) {
    const visible = ref(false)
    const count = ref(10)
    const ITV = ref(null)
    watch(() => props.dialogVisible, (val) => {
      // console.log(val)
      visible.value = val
      if (val === true) {
        ITV.value = setInterval(() => {
          if (count.value >= 0) {
            count.value = count.value - 1
          } else {
            clearInterval(ITV.value)
          }
        }, 1000)
      }
    })

    function handlePrimary () {
      if (count.value >= 0) {
        ElMessage({
          message: '请阅读同意用户信息与隐私协议！',
          type: 'error',
          duration: 2000,
          center: true
        })
      } else {
        handleClose()
        context.emit('handlePrimary')
      }
    }

    function handleCancel () {
      if (count.value >= 0) {
        ElMessage({
          message: '请阅读同意用户信息与隐私协议！',
          type: 'error',
          duration: 2000,
          center: true
        })
      } else {
        handleClose()
        context.emit('handleCancel')
      }
    }

    function handleClose () {
      if (ITV.value) {
        clearInterval(ITV.value)
      }
    }

    return {
      visible,
      handlePrimary,
      count,
      handleCancel
    }
  }
}
</script>

<style scoped>
.deal-dialog :deep(.el-dialog) {
  position: absolute;
  top: 40%;
  left: 50%;
  height: 60%;
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);
  padding: 0;
  margin: 0;
  border-radius: 4px;
}

.deal-dialog :deep(.el-dialog__header) {
  padding: 30px 0 10px;
}

.deal-dialog :deep(.el-dialog__title) {
  font-weight: 700;
  color: #0A2463;
}

.deal-dialog :deep(.el-dialog__body) {
  height: calc(100% - 84px);
  padding: 0 20px;
  margin-bottom: 20px;
  line-height: 24px;
  color: #8491B1;
  overflow-y: scroll;
}

.deal-dialog :deep(.el-dialog__body p) {
  text-align: justify;
  text-align-last: left;
}

.deal-dialog :deep(.dialog-footer) {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -100px;
}

.deal-dialog .dialog__footer-btn {
  width: 100%;
  height: 56px;
  color: #FFFFFF;
  font-size: 15px;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
  border: 0;
  border-radius: 28px;
}
</style>
