<template>
  <div v-loading="loading">
    <el-form
      class="form-box"
      ref="infoRef"
      :model="infoValid"
      :rules="infoRule"
    >
      <el-form-item
        v-for="(info) in infoForm"
        :key="info.paramName"
        :prop="info.paramName">
        <div class="form-item form-item__input" v-if="info.type === 'String'">
          <div class="form-item__label" :class="info.required ? 'form-item__label__required' : ''">{{info.label}}</div>
          <el-input
            v-model="info.value"
            @keydown.enter="handleSubmit"
            :placeholder="info.placeholder"
            :disabled="info.disabled"
          ></el-input>
        </div>
        <div class="form-item form-item__radio" v-else-if="info.type === 'Radio'">
          <div class="form-item__label" :class="info.required ? 'form-item__label__required' : ''">{{info.label}}</div>
          <el-radio-group v-model="info.value">
            <el-radio
              v-for="radio in info.options"
              :key="radio.value"
              :label="radio.value"
            >{{radio.label}}</el-radio>
          </el-radio-group>
        </div>
        <div class="form-item form-item__date" v-else-if="info.type === 'Date'">
          <div class="form-item__label" :class="info.required ? 'form-item__label__required' : ''">{{info.label}}</div>
          <el-date-picker
            v-model="info.value"
            type="date"
            :placeholder="info.placeholder"
            :disabledDate="disabledDate"
            :default-value="info.defaultDate"
          ></el-date-picker>
        </div>
        <div class="form-item form-item__city" v-else-if="info.type === 'City'">
          <div class="form-item__label" :class="info.required ? 'form-item__label__required' : ''">{{info.label}}</div>
<!--          <china-area-select-->
<!--            class="china-area-select"-->
<!--            :label="info.label"-->
<!--            :area-num="info.areaNum"-->
<!--            v-model="info.value"-->
<!--            @change="handleAreaChange($event, index)"-->
<!--          ></china-area-select>-->
          <div class="china-area-drawer">
            <china-area-drawer
              :title="info.label"
              v-model:value="info.value"
              :area-num="info.areaNum"
              :placeholder="info.placeholder"
            ></china-area-drawer>
          </div>
<!--          <china-area-select-->
<!--            class="china-area-select"-->
<!--            :label="info.label"-->
<!--            :area-num="info.areaNum"-->
<!--            v-model:value="info.value"-->
<!--          ></china-area-select>-->
        </div>
        <div class="form-item form-item__select" v-else-if="info.type === 'Select'">
          <div class="form-item__label" :class="info.required ? 'form-item__label__required' : ''">{{info.label}}</div>
          <el-select v-model="info.value" :placeholder="info.placeholder">
            <el-option
              v-for="item in info.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </el-form>
    <span class="warning-tips" v-if="role === 0">已同意 <span @click="dealVisible = true">《用户信息与隐私协议》</span></span>
    <el-button class="button__default" @click="handleSubmit">确认</el-button>
<!--    <my-dialog-->
<!--      :dialog-visible="dialogVisible"-->
<!--      title="提示"-->
<!--      primary-btn-text="报名"-->
<!--      cancel-btn-text="添加筛查人"-->
<!--      @handlePrimary="goApply"-->
<!--      @handleCancel="reFill"-->
<!--    >-->
<!--      <p class="dialog__context__center">-->
<!--        是否继续添加筛查人-->
<!--      </p>-->
<!--    </my-dialog>-->
    <deal-dialog :dialog-visible="dealVisible" @handle-primary="dealVisible = false" @handleCancel="dealVisible = false"></deal-dialog>
<!--    <ChinaAreaDrawer title="请选择籍贯" v-model:value="value"></ChinaAreaDrawer>-->
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { updateUserInfo, addFamily, updateFamily, getFamilyInfo } from '@/assets/api/request'
import { formatDateYYMMDDHasSplit, verifyingPhone, verifyingZh, verifyingSpecialCharacter } from '@/assets/js/util'
// import ChinaAreaSelect from '@/components/ChinaAreaSelect'
import ChinaAreaDrawer from '@/components/ChinaAreaDrawer'
// import MyDialog from '@/components/MyDialog'
import DealDialog from '@/components/DealDialog'
import Storage from '@/assets/js/LocalStorage'

export default {
  name: 'ScreenInfo',
  components: {
    // ChinaAreaSelect,
    // MyDialog,
    DealDialog,
    ChinaAreaDrawer
  },
  setup () {
    onMounted(() => {
      init()
    })
    const loading = ref(false)
    const storage = new Storage()
    // const dialogVisible = ref(false)
    const dealVisible = ref(false)
    const route = useRoute()
    const router = useRouter()
    const role = ref(undefined)
    const infoRef = ref(null)
    const infoForm = reactive([
      {
        label: '姓名',
        paramName: 'username',
        placeholder: '请输入姓名',
        value: '',
        required: true,
        type: 'String'
      },
      {
        label: '联系电话',
        paramName: 'phone',
        placeholder: '请输入联系电话',
        value: '',
        required: true,
        type: 'String',
        disabled: false
      },
      {
        label: '性别',
        paramName: 'sex',
        placeholder: '请选择性别',
        value: '',
        required: true,
        type: 'Radio',
        options: [
          {
            label: '男',
            value: 1
          },
          {
            label: '女',
            value: 0
          }
        ]
      },
      {
        label: '出生年月',
        paramName: 'birthday',
        placeholder: '请选择出生年月',
        value: '',
        required: true,
        type: 'Date',
        defaultDate: new Date((new Date().getFullYear() - 18), new Date().getMonth(), 1)
      },
      {
        label: '民族',
        paramName: 'nationality',
        placeholder: '请输入民族',
        value: '',
        required: true,
        type: 'String'
      },
      {
        label: '籍贯',
        paramName: 'nativePlace',
        placeholder: '请选择籍贯',
        value: '',
        required: true,
        type: 'City',
        areaNum: 2
      },
      {
        label: '现居住地',
        paramName: 'habitation',
        placeholder: '请选择现居住地',
        value: '',
        required: true,
        type: 'City',
        areaNum: 3
      },
      {
        label: '居住地详址',
        paramName: 'address',
        placeholder: '请输入居住地详址',
        value: '',
        required: false,
        type: 'String'
      }
    ])
    const validatePhone = (rule, value, callback) => {
      if (role.value === '0') {
        if (!value) {
          callback(new Error('请输入联系电话！'))
        } else if (!verifyingPhone(value)) {
          callback(new Error('请输入正确的联系电话！'))
        } else {
          callback()
        }
      } else {
        if (value) {
          if (!verifyingPhone(value)) {
            callback(new Error('请输入正确的联系电话或者不输入联系电话！'))
          } else {
            callback()
          }
        } else {
          callback()
        }
      }
    }
    const validateNativePlace = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择籍贯！'))
      } else if (value.split('/').length !== 2) {
        callback(new Error('请选择精确到市级！'))
      } else {
        callback()
      }
    }
    const validateHabitation = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择现居住地！'))
      } else if (value.split('/').length !== 3) {
        callback(new Error('请选择精确到区县级！'))
      } else {
        callback()
      }
    }
    const validateNationality = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请填写民族！'))
      } else if (!verifyingZh(value)) {
        callback(new Error('请选填写正确的民族！'))
      } else {
        callback()
      }
    }
    const validateName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入姓名！'))
      } else if (!verifyingSpecialCharacter(value)) {
        callback(new Error('姓名输入请勿包含数字和特殊字符！'))
      } else {
        callback()
      }
    }
    const infoRule = reactive({
      username: [
        {
          required: true,
          trigger: 'blur',
          validator: validateName
        }
      ],
      phone: [
        {
          required: true,
          // message: '请输入联系方式！',
          trigger: 'blur',
          validator: validatePhone
        }
      ],
      sex: [
        {
          required: true,
          message: '请选择性别！',
          trigger: 'blur'
        }
      ],
      birthday: [
        {
          required: true,
          message: '请选择出生年月！',
          trigger: 'blur'
        }
      ],
      nationality: [
        {
          required: true,
          trigger: 'blur',
          validator: validateNationality
        }
      ],
      nativePlace: [
        {
          required: true,
          // message: '请选择籍贯！',
          trigger: 'blur',
          validator: validateNativePlace
        }
      ],
      habitation: [
        {
          required: true,
          // message: '请选择现居住地！',
          trigger: 'blur',
          validator: validateHabitation
        }
      ],
      address: [
        {
          required: false,
          message: '请输入现居住地详址！',
          trigger: 'blur'
        }
      ]
    })
    const infoValid = computed(() => {
      const result = {}
      infoForm.forEach(info => {
        result[info.paramName] = info.value
        // result.push({ [info.paramName]: info.value })
      })
      return result
    })
    const _userInfo = reactive({
      data: {
        id: '',
        phone: '',
        username: '',
        uuid: ''
      }
    })

    function init () {
      loading.value = true
      role.value = route.query.role || undefined
      const phone = route.query.phone || undefined
      if (role.value === '0') {
        infoForm.unshift({
          label: '与本人的关系',
          paramName: 'relationship',
          placeholder: '请输入关系',
          value: '本人',
          required: false,
          type: 'String',
          disabled: true
        })
        infoRule.relationship = [
          {
            required: false,
            message: '请选择与本人的关系！',
            trigger: 'blur'
          }
        ]
        for (let i = 0; i < infoForm.length; i++) {
          if (infoForm[i].paramName === 'phone') {
            infoForm[i].disabled = true
            if (phone) {
              infoForm[i].value = phone
            }
            break
          }
        }
      } else {
        infoForm.unshift({
          label: '与本人的关系',
          paramName: 'relationship',
          placeholder: '请选择关系',
          value: '',
          required: true,
          type: 'Select',
          options: [
            {
              label: '父母',
              value: 1
            },
            {
              label: '子女',
              value: 2
            },
            {
              label: '夫妻',
              value: 3
            },
            {
              label: '兄弟',
              value: 4
            },
            {
              label: '姐妹',
              value: 5
            }
          ]
        })
        infoRule.relationship = [
          {
            required: true,
            message: '请选择与本人的关系！',
            trigger: 'blur'
          }
        ]
        for (let i = 0; i < infoForm.length; i++) {
          if (infoForm[i].paramName === 'phone') {
            infoForm[i].disabled = false
            infoForm[i].required = false
            break
          }
        }
        infoRule.phone[0].required = false
      }
      if (route.query.edit) {
        const userInfo = storage.getItem('__userInfo__')
        const id = route.query.role === '0' ? userInfo.userId : route.query.familyId
        getFamilyInfo(
          {
            id: id,
            relationship: route.query.relationship
          },
          res => {
            infoForm.forEach(info => {
              if (info.paramName === 'birthday') {
                info.value = new Date(res.data.data[info.paramName])
              } else if (info.paramName === 'relationship') {
                if (role.value === '1') {
                  info.value = res.data.data[info.paramName]
                }
              } else if (info.paramName === 'phone') {
                if (role.value === '1') {
                  info.required = false
                }
                info.value = res.data.data[info.paramName]
              } else {
                info.value = res.data.data[info.paramName]
              }
            })
            loading.value = false
          },
          () => {
            loading.value = false
          }
        )
      }
      loading.value = false
    }

    function disabledDate (date) {
      return date.getTime() > Date.now()
    }

    // function handleAreaChange (value, index) {
    //   infoForm[index].value = value.text.join('/')
    // }
    function handleSubmit () {
      loading.value = true
      infoRef.value.validate((valid) => {
        if (valid) {
          // 提交添加的筛查人信息
          const userInfo = storage.getItem('__userInfo__')
          if (role.value === '0') {
            updateUserInfo(
              {
                id: userInfo.userId,
                phone: infoValid.value.phone,
                username: infoValid.value.username,
                sex: infoValid.value.sex,
                birthday: formatDateYYMMDDHasSplit(infoValid.value.birthday),
                nationality: infoValid.value.nationality,
                nativePlace: infoValid.value.nativePlace,
                habitation: infoValid.value.habitation,
                address: infoValid.value.address
              },
              res => {
                // console.log(res)
                if (route.query.edit) {
                  ElMessage({
                    message: '更新个人信息完成',
                    type: 'success',
                    duration: 2000,
                    center: true
                  })
                  setTimeout(() => {
                    router.push({
                      path: '/screenManage'
                    })
                  }, 1000)
                } else {
                  if (route.query.first) {
                    ElMessage({
                      message: '添加个人信息完成',
                      type: 'success',
                      duration: 2000,
                      center: true
                    })
                    // 报名需要的参数
                    _userInfo.data.id = res.data.data.id
                    _userInfo.data.phone = res.data.data.phone
                    _userInfo.data.username = res.data.data.username
                    _userInfo.data.uuid = res.data.data.uuid
                    setTimeout(() => {
                      goApply()
                    }, 1000)
                    // dialogVisible.value = true
                  } else {
                    ElMessage({
                      message: '添加个人信息完成',
                      type: 'success',
                      duration: 2000,
                      center: true
                    })
                    setTimeout(() => {
                      router.push({
                        path: '/screenManage'
                      })
                    }, 1000)
                  }
                }
                loading.value = false
              },
              () => {
                loading.value = false
              }
            )
          } else {
            if (route.query.familyId && route.query.edit) {
              updateFamily(
                {
                  id: route.query.familyId,
                  phone: infoValid.value.phone,
                  username: infoValid.value.username,
                  sex: infoValid.value.sex,
                  relationship: infoValid.value.relationship,
                  birthday: formatDateYYMMDDHasSplit(infoValid.value.birthday),
                  nationality: infoValid.value.nationality,
                  nativePlace: infoValid.value.nativePlace,
                  habitation: infoValid.value.habitation,
                  address: infoValid.value.address
                },
                res => {
                  ElMessage({
                    message: '更新家人信息完成',
                    type: 'success',
                    duration: 2000,
                    center: true
                  })
                  setTimeout(() => {
                    router.push({
                      path: '/screenManage'
                    })
                  }, 1000)
                  loading.value = false
                },
                () => {
                  loading.value = false
                }
              )
            } else {
              addFamily(
                {
                  userId: userInfo.userId,
                  phone: infoValid.value.phone,
                  username: infoValid.value.username,
                  sex: infoValid.value.sex,
                  relationship: infoValid.value.relationship,
                  birthday: formatDateYYMMDDHasSplit(infoValid.value.birthday),
                  nationality: infoValid.value.nationality,
                  nativePlace: infoValid.value.nativePlace,
                  habitation: infoValid.value.habitation,
                  address: infoValid.value.address
                },
                res => {
                  ElMessage({
                    message: '添加家人信息完成',
                    type: 'success',
                    duration: 2000,
                    center: true
                  })
                  setTimeout(() => {
                    router.push({
                      path: '/screenManage'
                    })
                  }, 1000)
                  loading.value = false
                },
                () => {
                  loading.value = false
                }
              )
            }
          }
          loading.value = false
        } else {
          ElMessage({
            message: '请您按要求填写正确信息',
            type: 'error',
            duration: 2000,
            center: true
          })
          loading.value = false
        }
      })
    }

    function goApply () {
      // dialogVisible.value = false
      router.push({
        path: '/site',
        query: {
          id: _userInfo.data.id,
          uuid: _userInfo.data.uuid,
          phone: _userInfo.data.phone,
          username: _userInfo.data.username,
          relationship: 0,
          first: 1
        }
      })
      // console.log('去报名')
    }

    // function reFill () {
    //   dialogVisible.value = false
    //   router.push({
    //     path: '/screenInfo',
    //     query: {
    //       role: 1,
    //       add: 1
    //     }
    //   })
    // }

    return {
      loading,
      role,
      infoRef,
      infoForm,
      infoRule,
      infoValid,
      disabledDate,
      // handleAreaChange,
      handleSubmit,
      // dialogVisible,
      goApply,
      // reFill,
      dealVisible
    }
  }
}
</script>

<style scoped>
.form-box :deep(.el-form-item) {
  margin: 0;
  max-width: 100%;
  height: 54px;
  border-bottom: 1px solid rgba(10, 36, 99, 0.1);
}
.form-box .form-item {
  display: flex;
  align-items: center;
  height: 54px;
}
.form-box :deep(.el-form-item__error) {
  top: calc(100% - 18px);
  padding-left: 15px;
}
.form-box .form-item__label {
  height: 54px;
  width: 120px;
  padding: 0 10px 0 20px;
  box-sizing: border-box;
  line-height: 54px;
  font-size: 15px;
  color: #0A2463;
  text-align: left;
  border: 0;
  background-color: transparent;
  position: relative;
}
.form-box .form-item__label__required:before {
  content: '*';
  position: absolute;
  top: 1px;
  left: 10px;
  color: #C91616;
  font-size: 22px;
}
.form-box .form-item :deep(.el-input__suffix) {
  display: none;
}
.form-box .form-item__input :deep(.el-input), .form-box .form-item__radio :deep(.el-radio-group), .form-box .form-item__date :deep(.el-date-editor), .form-box .form-item__select :deep(.el-select), .china-area-drawer {
  width: calc(100% - 120px);
  box-sizing: border-box;
  padding: 0 15px;
  text-align: right;
}
.form-box :deep(.el-input__inner) {
  height: 54px;
  padding: 0;
  font-size: 15px;
  color: #0A2463;
  border: 0;
  text-align: right;
  background-color: transparent;
}
.form-box :deep(.el-input__prefix) {
  display: none;
}
.form-box :deep(.el-input--prefix .el-input__inner) {
  padding-left: 0;
}
.form-box :deep(.el-radio) {
  height: 54px;
}
.form-box :deep(.el-radio .el-radio__inner) {
  width: 24px;
  height: 24px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #F3F3F3;
}
.form-box :deep(.el-radio__input.is-checked .el-radio__inner) {
  background-color: inherit;
  border-color: transparent;
}
.form-box :deep(.el-radio .is-checked .el-radio__inner::after) {
  width: 10px;
  height: 10px;
  background-color: #5AFF25;
}
.form-box :deep(.el-cascader .el-input) {
  width: 100%;
  padding: 0;
}
.warning-tips {
  display: block;
  margin-top: 10px;
  margin-left: 20px;
  text-align: left;
  font-size: 14px;
  color: #8491B1;
}
.button__default {
  width: calc(100% - 40px);
  margin-top: 50px;
}
.form-box {
  font-size: 15px;
}
.dialog__context__center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
  box-sizing: border-box;
  font-size: 15px;
  color: #0A2463;
}
</style>
