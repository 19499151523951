<template>
  <div class="user" v-loading="loading">
    <header class="header">
      <div class="header-block">
        报名日期：
        <el-date-picker v-model="searchDate" @change="handleChange"></el-date-picker>
      </div>
      <div class="header-block">
        报名状态：
        <el-select class="header-block-select" v-model="applyStatus.value" @change="handleChange">
          <el-option
            v-for="item in applyStatus.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="header-block">
        预约状态：
        <el-select class="header-block-select" v-model="orderStatus.value" @change="handleChange">
          <el-option
            v-for="item in orderStatus.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="header-block">
        筛查状态：
        <el-select class="header-block-select" v-model="checkStatus.value" @change="handleChange">
          <el-option
            v-for="item in checkStatus.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="header-block">
        <el-input
          class="user-search"
          v-model="searchText"
          clearable
          placeholder="请输入用户id或手机号"
          @change="handleChange"
        >
          <template #suffix>
            <i class="icon icon-search"></i>
          </template>
        </el-input>
      </div>
      <div class="report">
        <el-button @click="dialogVisible = true">导出</el-button>
      </div>
    </header>
    <main class="main">
      <div class="table-wrapper">
        <div class="table">
          <el-table
            :data="tableData"
            height="100%"
          >
            <el-table-column type="index" label="序号" width="80" align="center" />
            <el-table-column property="uuid" label="用户ID" align="center"></el-table-column>
            <el-table-column property="username" label="用户姓名" align="center"></el-table-column>
            <el-table-column property="phone" label="联系方式" align="center"></el-table-column>
            <el-table-column label="报名状态" align="center">
              <template #default="scope">
                {{parseInt(scope.row.signState) === 0 ? '取消报名' : '已报名'}}
              </template>
            </el-table-column>
            <el-table-column label="报名时间" align="center">
              <template #default="scope">
                {{formatDateYYMMDDHHMMSS(scope.row.signTime)}}
              </template>
            </el-table-column>
            <el-table-column label="是否现场筛查" width="80" align="center">
              <template #default="scope">
                {{parseInt(scope.row.isLive) === 1 ? '现场报名' : '线上报名'}}
              </template>
            </el-table-column>
            <el-table-column property="orderState" label="预约状态" align="center">
              <template #default="scope">
                {{parseInt(scope.row.orderState) === 0 ? '未预约' : parseInt(scope.row.orderState) === 1 ? '已预约' : '取消预约'}}
              </template>
            </el-table-column>
            <el-table-column label="预约时间" align="center">
              <template #default="scope">
                {{formatDateYYMMDDHHMMSS(scope.row.orderTime)}}
              </template>
            </el-table-column>
            <el-table-column label="预约筛查时间" width="120" align="center">
              <template #default="scope">
                {{formatDateYYMMDDHHMMSS(scope.row.checkDate)}}
              </template>
            </el-table-column>
            <el-table-column label="筛查状态" align="center">
              <template #default="scope">
                {{parseInt(scope.row.checkState) === 0 ? '未筛查' : parseInt(scope.row.checkState) === 1 ? '已筛查' : parseInt(scope.row.checkState) === 2 ? '已完成' : '已逾期'}}
              </template>
            </el-table-column>
            <el-table-column label="筛查结果" align="center">
              <template #default="scope">
                {{parseInt(scope.row.result) === 0 ? '阳性' : parseInt(scope.row.result) === 1 ? '阴性' : '--'}}
              </template>
            </el-table-column>
            <el-table-column label="绑定试管时间" width="120" align="center">
              <template #default="scope">
                {{formatDateYYMMDDHHMMSS(scope.row.bindingTime)}}
              </template>
            </el-table-column>
            <el-table-column property="bindingId" width="120" label="绑定试管ID" align="center"></el-table-column>
          </el-table>
        </div>
      </div>
    </main>
    <div class="pagination">
      <span>显示第{{(pagination.currentPage - 1) * pagination.pageSize + 1}}到{{pagination.currentPage * pagination.pageSize}}条记录，总共{{pagination.total}}条记录，每页显示{{pagination.pageSize}}条记录</span>
      <el-pagination
        v-model:currentPage="pagination.currentPage"
        :page-size="pagination.pageSize"
        background
        layout="prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <div class="export-dialog">
      <el-dialog
        v-model="dialogVisible"
        width="30%"
        center
        :show-close="false"
      >
        <div>
          <el-date-picker
            v-model="exportDate"
            type="daterange"
            range-separator="To"
            start-placeholder="Start date"
            end-placeholder="End date"
          />
          <div style="margin-top: 10px; font-size: 12px; color: #888888">若导出的数据量过大，可能导致导出失败！</div>
        </div>
        <template #title>
          <div class="dialog-header">
            <span>提示</span>
          </div>
        </template>
        <template #footer>
          <div class="dialog-footer">
            <el-button
              class="dialog__footerbtn"
              @click="dialogVisible = false"
            >取消
            </el-button>
            <el-button
              class="dialog__footerbtn dialog__footerbtn_primary"
              type="primary"
              @click="handleDownloadExcel"
            >确定导出
            </el-button
            >
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import { getUserListByPlanId, getExcel } from '@/components/backend/assets/api/request'
import { useStore } from 'vuex'
import { formatDateYYMMDDHasSplit2, formatDateYYMMDDHHMMSS } from '@/components/backend/assets/js/util'
// import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { reportMap, planMap } from '@/components/backend/assets/js/config'

const PAGESIZE = 10

export default {
  name: 'User',
  setup () {
    onMounted(() => {
      handleGetUserListByPlanId()
    })
    const loading = ref(false)
    const store = useStore()
    // const router = useRouter()
    const plan = computed(() => store.state.plan)
    const searchText = ref('')
    const searchDate = ref(new Date())
    const applyStatus = reactive({
      value: '',
      options:
        [
          {
            label: '全部',
            value: ''
          },
          {
            label: '已报名',
            value: '1'
          },
          {
            label: '取消报名',
            value: '0'
          }
        ]
    })
    const orderStatus = reactive({
      value: '',
      options:
        [
          {
            label: '全部',
            value: ''
          },
          {
            label: '未预约',
            value: '0'
          },
          {
            label: '已预约',
            value: '1'
          },
          {
            label: '取消预约',
            value: '2'
          }
        ]
    })
    const checkStatus = reactive({
      value: '',
      options:
        [
          {
            label: '全部',
            value: ''
          },
          {
            label: '未筛查',
            value: '0'
          },
          {
            label: '已筛查',
            value: '1'
          },
          {
            label: '已完成',
            value: '2'
          },
          {
            label: '已逾期',
            value: '3'
          }
        ]
    })
    const tableData = reactive([])
    const pagination = reactive({
      currentPage: 1,
      pageSize: PAGESIZE,
      total: 100
    })
    const dialogVisible = ref(false)
    const exportDate = ref([])

    function handleGetUserListByPlanId () {
      loading.value = true
      let signTime
      if (searchDate.value instanceof Date) {
        signTime = formatDateYYMMDDHasSplit2(searchDate.value.getTime())
      }
      const data = {
        pointId: plan.value.pointId,
        signTime: signTime,
        signState: applyStatus.value,
        orderState: orderStatus.value,
        checkState: checkStatus.value,
        searchValue: searchText.value,
        pageNum: pagination.currentPage,
        pageSize: pagination.pageSize
      }
      getUserListByPlanId(
        data,
        res => {
          if (res.data && res.data.data && res.data.data.list) {
            tableData.length = 0
            tableData.push(...res.data.data.list)
            pagination.total = parseInt(res.data.data.total)
          } else {
            tableData.length = 0
            pagination.total = 0
          }
          loading.value = false
        },
        () => {
          loading.value = false
        }
      )
    }

    /**
     * 获取导出Excel数据
     * */
    function handleGetExcel () {
      loading.value = true
      const data = {
        planId: plan.value.id,
        pointId: plan.value.pointId,
        startTime: `${formatDateYYMMDDHasSplit2(exportDate.value[0].getTime())} 00:00:00`,
        endTime: `${formatDateYYMMDDHasSplit2(exportDate.value[1].getTime())} 23:59:59`
      }
      getExcel(
        data,
        res => {
          // console.log(res)
          const recordSection = []
          if (res.data && res.data.data && res.data.data.list) {
            recordSection.length = 0
            recordSection.push(...res.data.data.list)
          } else {
            recordSection.length = 0
          }
          if (recordSection.length === 0) {
            ElMessageBox.alert('所选日期内无可导出的数据', '错误', {
              confirmButtonText: '确定'
            })
            dialogVisible.value = false
            loading.value = false
            return
          }
          const extra = []
          if (res.data && res.data.data && res.data.data.screeningPlan) {
            const screeningPlan = res.data.data.screeningPlan
            extra[0] = []
            extra[1] = []
            extra[2] = []
            for (let i = 0; i < planMap.length; i++) {
              extra[0].push(planMap[i].label)
              if (planMap[i].transfer) {
                extra[1].push(planMap[i].transfer[screeningPlan[planMap[i].key]])
              } else {
                if (planMap[i].key === 'startDay') {
                  extra[1].push(formatDateYYMMDDHasSplit2(screeningPlan[planMap[i].key]))
                } else {
                  extra[1].push(screeningPlan[planMap[i].key])
                }
              }
              extra[2].push('')
            }
          }

          // 导出表哥的表头设置
          const allColumns = reportMap
          const columnNames = []
          const columnValues = []
          for (let i = 0; i < allColumns.length; i++) {
            columnNames[i] = allColumns[i].label
            columnValues[i] = allColumns[i].key
          }
          require.ensure([], () => {
            // eslint-disable-next-line camelcase
            const { export_json_to_excel } = require('../../../../assets/js/Export2Excel')
            const tHeader = columnNames
            const filterVal = columnValues

            const list = []
            recordSection.forEach(item => {
              const temp = { ...item }
              temp.signState = parseInt(item.signState) === 1 ? '已报名' : '取消报名'
              temp.isLive = parseInt(item.isLive) === 1 ? '现场报名' : '线上报名'
              temp.result = parseInt(item.result) === 1 ? '阴性' : '阳性'
              temp.orderState = parseInt(item.orderState) === 0 ? '未预约' : parseInt(item.orderState) === 1 ? '已预约' : '取消预约'
              temp.checkState = parseInt(item.checkState) === 0 ? '未筛查' : parseInt(item.checkState) === 1 ? '已筛查' : parseInt(item.checkState) === 2 ? '已完成' : '已逾期'
              temp.signTime = formatDateYYMMDDHHMMSS(temp.signTime)
              temp.orderTime = formatDateYYMMDDHHMMSS(temp.orderTime)
              temp.checkDate = formatDateYYMMDDHHMMSS(temp.checkDate)
              temp.bindingTime = formatDateYYMMDDHHMMSS(temp.bindingTime)
              list.push(temp)
            })
            const data = formatJson(filterVal, list)
            export_json_to_excel(tHeader, data, `筛查计划-报名用户详情 ${formatDateYYMMDDHasSplit2(exportDate.value[0].getTime())}~${formatDateYYMMDDHasSplit2(exportDate.value[1].getTime())}`, extra)
          })
          dialogVisible.value = false
          loading.value = false
        },
        () => {
          loading.value = false
        }
      )
    }

    /**
     * 搜索时触发
     * */
    function handleChange () {
      handleGetUserListByPlanId()
    }

    /**
     * 用户表格翻页时触发
     * */
    function handleCurrentChange (page) {
      pagination.currentPage = page
      handleGetUserListByPlanId()
    }

    /**
     * 导出excel数据
     * */
    function handleDownloadExcel () {
      handleGetExcel()
    }

    function formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    }

    return {
      loading,
      searchText,
      searchDate,
      handleChange,
      applyStatus,
      orderStatus,
      checkStatus,
      tableData,
      pagination,
      handleCurrentChange,
      formatDateYYMMDDHHMMSS,
      dialogVisible,
      exportDate,
      handleDownloadExcel
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  height: 65px;
  align-items: center;
}
.header-block + .header-block {
  margin-left: 30px;
}
/*.header .user-search {*/
/*  position: absolute;*/
/*  width: 224px;*/
/*  top: -34px;*/
/*  left: 350px;*/
/*}*/
.header .report {
  position: absolute;
  right: 0;
}
.header .report .el-button {
  min-height: 36px;
  padding: 10px 25px;
}
.header .user-search .icon-search {
  display: inline-block;
  width: 19px;
  height: 100%;
  vertical-align: top;
  background: url("../../../../assets/images/icon__search.png") no-repeat center;
  background-size: 100%;
}
.header-block-select {
  width: 120px;
}
.main {
  height: calc(100% - 120px);
  width: 100%;
}
.main .table-wrapper {
  height: 100%;
  overflow: hidden;
}
.main .table-wrapper .table {
  height: 100%;
  width: calc(100% + 16px);
}
.main :deep(.el-table th.el-table__cell) {
  font-size: 15px;
  color: #888888;
  background: #F2F3F7;
}
.main :deep(.el-table .el-table__body .cell) {
  /*height: 33px;*/
  line-height: 20px;
  font-size: 14px;
  color: #0A2463;
}
.pagination {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #7D7D7D;
}
.user {
  position: relative;
}
.export-dialog :deep(.el-dialog__header),
.export-dialog :deep(.el-dialog__footer) {
  padding: 0;
}

.export-dialog :deep(.el-dialog__body) {
  display: flex;
  padding: 0;
  min-height: 172px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-align: center;
}

.export-dialog .dialog-header {
  position: relative;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  color: #FFF;
  background-color: #136FD1;
}

.export-dialog .dialog-header .dialog__headerbtn {
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 15px;
}

.export-dialog .dialog-footer {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-around;
  background-color: #F2F3F7;
}

.export-dialog .dialog-footer .dialog__footerbtn {
  width: 108px;
  min-height: 34px;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

.export-dialog .dialog-footer .dialog__footerbtn_primary {
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
}
</style>
