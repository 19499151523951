<template>
  <header class="header" id="record-open-header">
    <span class="header-block">筛查点：{{plan.pointName}}</span>
    <span class="header-block">所属地：{{plan.habitation}}</span>
    <span class="header-block">报名方式：{{parseInt(plan.applyWay) ? '仅支持线上报名' : '支持现场和线上报名'}}</span>
    <div class="back" @click="back">
      <i class="icon icon__back"></i>
      返回
    </div>
  </header>
</template>

<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'recordOperationHeader',
  setup () {
    const router = useRouter()
    const store = useStore()
    const plan = computed(() => store.state.plan)

    function back () {
      router.push({
        name: 'recordOpen'
      })
    }

    return {
      back,
      plan
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  height: 80px;
  align-items: center;
  color: #0A2463;
  font-size: 14px;
  position: relative;
  border-bottom: 1px solid #DDDDDD;
}
.header-block + .header-block {
  margin-left: 80px;
}
.back {
  position: absolute;
  top: 50%;
  right: 0;
  height: 34px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.icon__back {
  display: inline-block;
  width: 29px;
  height: 34px;
  margin-right: 8px;
  background: url("../../../assets/images/icon__back.png") no-repeat center center;
  background-size: 85%;
}
</style>
