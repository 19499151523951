<template>
  <div class="login">
    <div class="login-box">
      <h3 class="sys__title">幽门螺杆菌筛查后台管理系统</h3>
      <el-form
        ref="loginRef"
        :model="loginForm"
        :rules="loginRules"
      >
        <el-form-item prop="user">
          <el-input v-model="loginForm.user" @keydown.enter="login('loginForm')" placeholder="账号" clearable></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="loginForm.password" @keydown.enter="login('loginForm')" placeholder="密码" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="login-btn" @click="login('loginForm')">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessageBox } from 'element-plus'
import { managerLogin } from '@/components/backend/assets/api/request'
// import Storage from '@/assets/js/LocalStorage'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'PcLogin',
  setup () {
    onMounted(() => {
    })
    const loginRef = ref(null)
    const store = useStore()
    const router = useRouter()
    const loginForm = reactive({
      user: '',
      password: '',
      verify: ''
    })
    const loginRules = reactive({
      user: [
        {
          required: true,
          message: '请输入账号',
          trigger: 'blur'
        }
      ],
      password: [
        {
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }
      ]
    })

    function login () {
      loginRef.value.validate((valid) => {
        if (valid) {
          managerLogin(
            {
              username: loginForm.user,
              password: loginForm.password
            },
            res => {
              store.dispatch('saveUser', res.data.data)
              router.push({
                name: 'summary'
              })
            }
          )
        } else {
          ElMessageBox.alert('请完整输入账号/密码', '提示', {
            confirmButtonText: '确定'
          })
          return false
        }
      })
    }

    return {
      loginForm,
      loginRules,
      loginRef,
      login
    }
  }
}
</script>

<style scoped>
.login {
  height: 100%;
  background-color: #FFF;
}
.sys__title {
  margin-bottom: 52px;
  font-size: 35px;
  font-weight: 600;
  color: #212121;
  line-height: 42px;
  text-align: center;
}

.login-box {
  position: absolute;
  width: 683px;
  height: 521px;
  padding: 120px 0;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  text-align: center;
  transform: translate(-50%, -50%);
  background-color: #F8FBFF;
  /*width: 300px;*/
}

.login-box :deep(.el-input) {
  width: 300px;
  height: 36px;
  margin: 0 auto;
}

.login-box :deep(.el-input .el-input__inner) {
  height: 100%;
}

.login-box :deep(.el-form-item__error) {
  width: 300px;
  left: calc(50% - 150px);
  text-align: left;
}

.login-box .login-btn {
  width: 300px;
  height: 36px;
  margin: 52px auto 0;
  color: #FFF;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.login-box :deep(.el-input-group__append) {
  padding: 0;
  border: 0;
}
</style>
