import ScreenSearch from '@/views/WeChat/ScreenSearch'
import Login from '@/views/WeChat/user/Login'
import ScreenManage from '@/views/WeChat/user/Screen/ScreenManage'
import ScreenInfo from '@/views/WeChat/user/Screen/ScreenInfo'
import ScreenCode from '@/views/WeChat/user/Screen/ScreenCode'
import ScreenRecord from '@/views/WeChat/user/Screen/ScreenRecord'
import ScreenChange from '@/views/WeChat/user/Screen/ScreenChange'
import Apply from '@/views/WeChat/apply/Apply'
import Site from '@/views/WeChat/apply/Site'
import AppointmentDate from '@/views/WeChat/appointment/AppointmentDate'
import Appointment from '@/views/WeChat/appointment/Appointment'

export default [
  {
    path: '/',
    redirect: '/backend'
  },
  {
    path: '/searchScreen',
    name: 'ScreenSearch',
    component: ScreenSearch,
    meta: {
      title: '筛查点查询'
    }
  },
  {
    path: '/screenManage',
    name: 'ScreenManage',
    component: ScreenManage,
    meta: {
      title: '筛查人列表',
      requiresAuth: true
    }
  },
  {
    path: '/screenInfo',
    name: 'ScreenInfo',
    component: ScreenInfo,
    meta: {
      title: '筛查人信息',
      requiresAuth: true
    }
  },
  {
    path: '/screenCode',
    name: 'ScreenCode',
    component: ScreenCode,
    meta: {
      title: '筛查码',
      requiresAuth: true
    }
  },
  {
    path: '/screenRecord',
    name: 'ScreenRecord',
    component: ScreenRecord,
    meta: {
      title: '筛查记录',
      requiresAuth: true
    }
  },
  {
    path: '/screenChange',
    name: 'ScreenChange',
    component: ScreenChange,
    meta: {
      title: '切换筛查人',
      requiresAuth: true
    }
  },
  {
    path: '/apply',
    name: 'Apply',
    component: Apply,
    meta: {
      title: '报名筛查',
      requiresAuth: true
    }
  },
  {
    path: '/site',
    name: 'Site',
    component: Site,
    meta: {
      title: '选择筛查点',
      requiresAuth: true
    }
  },
  {
    path: '/appointmentDate',
    name: 'AppointmentDate',
    component: AppointmentDate,
    meta: {
      title: '选择筛查时间',
      requiresAuth: true
    }
  },
  {
    path: '/appointment',
    name: 'Appointment',
    component: Appointment,
    meta: {
      title: '预约筛查',
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录'
    }
  }
]
