import axios from 'axios'
import { ElMessage } from 'element-plus'

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = '/api'
}

const POST = 'POST'
// const GET = 'GET'

function post (url, data, success, failure) {
  axios.post(url, data)
    .then(res => {
      if (res.data.status === 200) {
        if (success) {
          success(res)
        }
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error',
          duration: 2000,
          center: true
        })
        if (failure) {
          failure(res)
        }
      }
    })
}

function get (url, data, success, failure) {
  axios.get(url, {
    params: data
  })
    .then(res => {
      if (res.data.status === 200) {
        if (success) {
          success(res)
        }
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error',
          duration: 2000,
          center: true
        })
        if (failure) {
          failure(res)
        }
      }
    })
}

function request (url, data, method, success, failure) {
  if (method === POST) {
    post(url, data, success, failure)
  } else {
    get(url, data, success, failure)
  }
}

/**
 * 管理员获取用户列表
 * @param data
 * @param success
 * @param failure
 */
export function getUserList (data, success, failure) {
  const url = '/manager/getUserList'
  request(url, data, POST, success, failure)
}

/**
 * 根据用户ID获取用户家庭成员列表
 * @param data
 * @param success
 * @param failure
 */
export function getFamilyList (data, success, failure) {
  const url = '/manager/getFamilyList'
  request(url, data, POST, success, failure)
}

/**
 * 获取个人报名预约筛查记录
 * @param data：uuid
 * @param success
 * @param failure
 */
export function getUserRecord (data, success, failure) {
  const url = '/screening/getUserRecord'
  request(url, data, POST, success, failure)
}

/**
 * 获取用户及家庭成员筛查记录
 * @param data：id
 * @param success
 * @param failure
 */
export function getUserAndFamilyRecordList (data, success, failure) {
  const url = '/manager/getUserAndFamilyRecordList'
  request(url, data, POST, success, failure)
}

/**
 * 管理员获取筛查点列表
 * @param data
 * @param success
 * @param failure
 */
export function getScreeningPointList (data, success, failure) {
  const url = '/manager/getScreeningPointList'
  request(url, data, POST, success, failure)
}

/**
 * 更新筛查点信息
 * @param data
 * @param success
 * @param failure
 */
export function updateScreeningPointInfo (data, success, failure) {
  const url = '/manager/updateScreeningPointInfo'
  request(url, data, POST, success, failure)
}

/**
 * 获取筛查点详细信息
 * @param data：id
 * @param success
 * @param failure
 */
export function getScreeningPointInfoById (data, success, failure) {
  const url = '/manager/getScreeningPointInfoById'
  request(url, data, POST, success, failure)
}

/**
 * 管理员增加筛查点及负责人
 * @param data
 * @param success
 * @param failure
 */
export function addScreeningPoint (data, success, failure) {
  const url = '/manager/addScreeningPoint'
  request(url, data, POST, success, failure)
}

/**
 * 获取筛查计划列表
 * @param data
 * @param success
 * @param failure
 */
export function getPlanList (data, success, failure) {
  const url = '/manager/getPlanList'
  request(url, data, POST, success, failure)
}

/**
 * 获取月度筛查计划列表
 * @param data: {month: 年月, planId: 筛查计划Id}
 * @param success
 * @param failure
 */
export function getPlanListByDay (data, success, failure) {
  const url = '/manager/getPlanListByDay'
  request(url, data, POST, success, failure)
}

/**
 * 编辑页--按月查询每日预约计划详情
 * @param data: {month: 年月, planId: 筛查计划Id}
 * @param success
 * @param failure
 */
export function getPlanListByMonth (data, success, failure) {
  const url = '/manager/getPlanListByMonth'
  request(url, data, POST, success, failure)
}

/**
 * 编辑页--按日更新预约计划
 * @param data
 * @param success
 * @param failure
 */
export function updatePlanByDay (data, success, failure) {
  const url = '/manager/updatePlanByDay'
  request(url, data, POST, success, failure)
}

/**
 * 新增筛查计划
 * @param data: {month: 年月, planId: 筛查计划Id}
 * @param success
 * @param failure
 */
export function addPlan (data, success, failure) {
  const url = '/manager/addPlan'
  request(url, data, POST, success, failure)
}

/**
 * 更新筛查计划
 * @param data: {month: 年月, planId: 筛查计划Id}
 * @param success
 * @param failure
 */
export function updatePlan (data, success, failure) {
  const url = '/manager/updatePlan'
  request(url, data, POST, success, failure)
}

/**
 * 获取报名用户详情列表
 * @param data
 * @param success
 * @param failure
 */
export function getUserListByPlanId (data, success, failure) {
  const url = '/manager/getUserListByPlanId'
  request(url, data, POST, success, failure)
}

/**
 * 获取首页数据
 * @param data
 * @param success
 * @param failure
 */
export function getHome (data, success, failure) {
  const url = '/manager/getHome'
  request(url, data, POST, success, failure)
}

/**
 * 管理员登录
 * @param data
 * @param success
 * @param failure
 */
export function managerLogin (data, success, failure) {
  const url = '/manager/managerLogin'
  request(url, data, POST, success, failure)
}

/**
 * 导出Excel所需数据
 * @param data
 * @param success
 * @param failure
 */
export function getExcel (data, success, failure) {
  const url = '/manager/getExcel'
  request(url, data, POST, success, failure)
}
