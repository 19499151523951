<template>
  <div class="record" v-loading="loading">
    <header class="header" v-if="user.roleFlag !== 2">
      <div class="header-left">
        <el-button @click="showAdd()">新增</el-button>
      </div>
      <div class="header-right">
        <el-input
          v-model="searchText"
          clearable
          placeholder="请输入计划ID或筛查点"
          @change="handleSearchChange"
        >
          <template #suffix>
            <i class="icon icon-search"></i>
          </template>
        </el-input>
        <div class="refresh" @click="handleRefresh">
          <i class="icon refresh__inner"></i>
        </div>
      </div>
    </header>
    <main class="main">
      <div class="table-wrapper">
        <div class="table">
          <el-table
            :data="tableData"
            height="100%"
          >
            <el-table-column type="index" label="序号" width="80" align="center" />
            <el-table-column property="pointName" label="筛查点" align="center"/>
            <el-table-column property="id" label="计划ID" align="center"/>
            <el-table-column property="planName" label="计划名称" align="center"/>
            <el-table-column property="habitation" label="所属地" align="center"/>
            <el-table-column label="计划开启日期" align="center">
              <template #default="scope">
                {{formatDateYYMMDDHHMMSS(scope.row.createTime)}}
              </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
              <template #default="scope">
                {{parseInt(scope.row.state) === 1 ? '开启' : '已结束'}}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template #default="scope">
                <i class="operate-icon operate-icon-detail" @click="showDetail(scope.$index, scope.row)"></i>
                <i class="operate-icon operate-icon-edit" @click="showEdit(scope.$index, scope.row)"></i>
                <i class="operate-icon operate-icon-delete" v-if="parseInt(user.roleFlag) !== 2" @click="showDelete(scope.$index, scope.row)"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </main>
    <div class="pagination">
      <span>显示第{{(pagination.currentPage - 1) * pagination.pageSize + 1}}到{{pagination.currentPage * pagination.pageSize}}条记录，总共{{pagination.total}}条记录，每页显示{{pagination.pageSize}}条记录</span>
      <el-pagination
        v-model:currentPage="pagination.currentPage"
        :page-size="pagination.pageSize"
        background
        layout="prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <frame-only-text
      title="删除筛查计划"
      text="是否要删除该筛查计划？"
      btn-left="是"
      btn-right="否"
      v-model:show="showDeleteFlag"
      @clickLeft="handleDelete"
      @clickRight="handleCancelDelete"
      @frameClose="handleFrameClose"
    ></frame-only-text>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { getPlanList, updatePlan } from '@/components/backend/assets/api/request'
import { formatDateYYMMDDHHMMSS } from '@/components/backend/assets/js/util'
import { useStore } from 'vuex'
import FrameOnlyText from '@/components/backend/components/FrameOnlyText'

const PAGESIZE = 10

export default {
  name: 'RecordOpen',
  components: {
    FrameOnlyText
  },
  setup () {
    onMounted(() => {
      store.dispatch('savePlan', {})
      if (parseInt(user.value.roleFlag) === 2) {
        searchText.value = user.value.pointName
      }
      handleGetPlanList()
    })
    const loading = ref(false)
    const router = useRouter()
    const store = useStore()
    const user = computed(() => store.state.user)
    const tableData = reactive([])
    const searchText = ref('')
    const pagination = reactive({
      currentPage: 1,
      pageSize: PAGESIZE,
      total: 100
    })
    const deleteId = ref(-1)
    const showDeleteFlag = ref(false)

    /**
     * 获取筛查计划列表
     * */
    function handleGetPlanList () {
      loading.value = true
      try {
        getPlanList(
          {
            searchValue: searchText.value,
            pageNum: pagination.currentPage,
            pageSize: pagination.pageSize,
            state: 1
          },
          res => {
            tableData.length = 0
            tableData.push(...res.data.data.list)
            pagination.total = parseInt(res.data.data.total)
            loading.value = false
          },
          () => {
            loading.value = false
          }
        )
      } catch (e) {
        loading.value = false
      }
    }

    /**
     * data只传id与enableFlag删除状态，删除计划
     * */
    function handleUpdatePlan (id) {
      loading.value = true
      updatePlan(
        {
          id: id,
          enableFlag: 0
        },
        () => {
          handleGetPlanList()
          loading.value = false
        },
        () => {
          loading.value = false
        }
      )
    }

    /**
     * 新增计划
     * */
    function showAdd () {
      router.push({
        name: 'recordAdd'
      })
    }

    /**
     * 显示计划详情
     * */
    function showDetail (index, scope) {
      store.dispatch('savePlan', scope)
      router.push({
        name: 'recordView'
      })
    }

    /**
     * 编辑计划
     * */
    function showEdit (index, scope) {
      store.dispatch('savePlan', scope)
      router.push({
        name: 'recordEdit'
      })
    }

    // function handleDelete (index, scope) {
    //   console.log(scope)
    //   deleteId.value = scope.id
    //   // handleUpdatePlan(scope.id)
    // }

    /**
     * 要删除筛查计划时触发
     * */
    function showDelete (index, scope) {
      deleteId.value = scope.id
      showDeleteFlag.value = !showDeleteFlag.value
    }

    /**
     * 确定删除筛查计划
     * */
    function handleDelete () {
      showDeleteFlag.value = !showDeleteFlag.value
      handleUpdatePlan(deleteId.value)
      deleteId.value = -1
    }

    /**
     * 取消删除筛查点
     * */
    function handleCancelDelete () {
      showDeleteFlag.value = !showDeleteFlag.value
      deleteId.value = -1
    }

    /**
     * 弹窗关闭，重置选中筛查点，刷新筛查点列表
     * */
    function handleFrameClose () {
      deleteId.value = -1
    }

    /**
     * 刷新
     * */
    function handleRefresh () {
      handleGetPlanList()
    }

    /**
     * 搜索时触发
     * */
    function handleSearchChange () {
      handleGetPlanList()
    }

    /**
     * 用户表格翻页时触发
     * */
    function handleCurrentChange (page) {
      pagination.currentPage = page
      handleGetPlanList()
    }

    return {
      loading,
      tableData,
      showAdd,
      showDetail,
      showEdit,
      showDelete,
      handleDelete,
      showDeleteFlag,
      handleCancelDelete,
      handleFrameClose,
      handleRefresh,
      searchText,
      handleSearchChange,
      pagination,
      handleCurrentChange,
      formatDateYYMMDDHHMMSS,
      user
    }
  }
}
</script>

<style scoped>
.record {
  margin-top: 30px;
  height: calc(100% - 66px);
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.header .header-left :deep(.el-button) {
  width: 115px;
  height: 36px;
  color: #FFFFFF;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
  border-radius: 17px;
}
.header .header-right {
  display: flex;
}
.header .header-right :deep(.el-input__inner) {
  height: 100%;
}
.header .header-right .icon-search {
  display: inline-block;
  width: 19px;
  height: 100%;
  vertical-align: top;
  background: url("../../assets/images/icon__search.png") no-repeat center;
  background-size: 100%;
}
.header .header-right .refresh {
  min-width: 36px;
  height: 36px;
  margin-left: 68px;
  cursor: pointer;
}
.header .header-right .refresh .refresh__inner {
  display: inline-block;
  height: 100%;
  width: 100%;
  background: url("../../assets/images/icon__refresh.png") no-repeat center center;
  background-size: 70%;
}
.main {
  height: calc(100% - 120px);
  width: 100%;
}
.main .table-wrapper {
  height: 100%;
  overflow: hidden;
}
.main .table-wrapper .table {
  height: 100%;
  width: calc(100% + 16px);
}
.main :deep(.el-table th.el-table__cell) {
  font-size: 15px;
  color: #888888;
  background: #F2F3F7;
}
.main :deep(.el-table .el-table__body .cell) {
  height: 33px;
  line-height: 33px;
  font-size: 14px;
  color: #0A2463;
}
.main .operate-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 0 8px;
  vertical-align: middle;
  cursor: pointer;
}
.main .operate-icon-detail {
   background: url("../../assets/images/icon__operate-detail.png");
 }
.main .operate-icon-edit {
  background: url("../../assets/images/icon__operate-edit.png");
}
.main .operate-icon-delete {
  background: url("../../assets/images/icon__operate-delete.png");
}
.pagination {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #7D7D7D;
}
.record {
  position: relative;
}
</style>
