<template>
  <div class="ph-header">
    <el-avatar :size="40" :src="circleUrl"></el-avatar>
    <span class="name">{{user.username}}</span>
    <el-button class="logout-btn" type="text" @click="logout">退出</el-button>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { useStore } from 'vuex'
// import Dialog from "@/components/Dialog";

export default {
  name: 'PhHeader',
  setup () {
    const store = useStore()
    const user = computed(() => store.state.user)
    const router = useRouter()
    const dialogStatus = ref(false)
    const circleUrl = ref(require('../assets/images/avatar_default.png'))
    function logout () {
      ElMessageBox.confirm('确认退出登录？', '注销', {
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(() => {
        store.dispatch('saveUser', {})
        router.push({
          name: 'login'
        })
      }).catch(() => {})
    }
    return {
      dialogStatus,
      circleUrl,
      user,
      logout
    }
  }
  // components: {
  //   Dialog
  // }
}
</script>

<style scoped>
.ph-header {
  display: flex;
  height: 68px;
  padding: 14px 80px;
  align-items: center;
  box-sizing: border-box;
  justify-content: right;
  font-size: 15px;
  box-shadow: 0 3px 6px 0 #DEDEDE;
}
.ph-header .name {
  margin: 0 10px;
}
.ph-header .logout-btn {
  color: #000
}
</style>
