<template>
  <div class="screen" v-loading="loading">
    <div class="screen-list" v-if="screenList.data.length">
      <div class="screen-list__inner" v-for="screen in screenList.data" :key="screen.id" @click="handleDisplayInfo(screen)">
        <div class="screen-list__inner-left">
          <div class="screen-list__inner-left-top">{{screen.name}}</div>
          <div class="screen-list__inner-left-bottom">与本人关系：{{screen.relation}}</div>
        </div>
        <div class="screen-list__inner-right">
          <i class="icon__QRCode"></i>
          <span>打开二维码</span>
        </div>
      </div>
    </div>
    <el-button class="button__default" v-if="screenList.data.length" @click="handleAddScreen">添加筛查人</el-button>
    <div class="screen__none" v-if="!screenList.data.length">
      <img class="screen__none-bg" src="../../../../assets/images/screen__none.png" alt="">
      <span class="screen__none__inner" @click="handleAddScreen">未查询到筛查人信息，请录入筛查人信息</span>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { getFamily } from '@/assets/api/request'
import Storage from '@/assets/js/LocalStorage'
import { relationShipMap } from '@/assets/js/config'

export default {
  name: 'ScreenManage',
  setup () {
    onMounted(() => {
      loading.value = true
      const userInfo = storage.getItem('__userInfo__')
      getFamily(
        {
          id: userInfo.userId
        },
        res => {
          // console.log(res)
          screenList.data = []
          if (res.data.data.user.length > 0) {
            screenList.data.push({
              id: res.data.data.user[0].id,
              name: res.data.data.user[0].username,
              relation: '本人',
              relationValue: 0,
              phone: res.data.data.user[0].phone
            })
          }
          res.data.data.family.forEach(family => {
            screenList.data.push({
              id: family.id,
              name: family.username,
              relation: relationShipMap[family.relationship],
              relationValue: family.relationship,
              phone: family.phone
            })
          })
          uPhone.value = res.data.data.phone
          loading.value = false
        },
        () => {
          loading.value = false
        }
      )
    })
    const uPhone = ref('')
    const loading = ref(false)
    const router = useRouter()
    const storage = new Storage()
    const screenList = reactive({
      data: []
    })

    function handleAddScreen () {
      const query = {}
      if (screenList.data.length <= 0 || screenList.data[0].relationValue !== 0) {
        query.role = 0
        query.phone = uPhone.value
      } else {
        query.role = 1
      }
      router.push({
        path: '/screenInfo',
        query: query
      })
    }

    function handleDisplayInfo (screen) {
      const query = {}
      if (screen.id) {
        query.id = screen.id
      }
      if (screen.relationValue !== 0) {
        query.relationship = screen.relationValue
      }
      router.push({
        path: '/screenCode',
        query: query
      })
    }

    return {
      loading,
      screenList,
      handleAddScreen,
      handleDisplayInfo
    }
  }
}
</script>

<style scoped>
.screen {
  padding: 20px 16px;
  height: 100%;
  box-sizing: border-box;
  background-color: #F8FAFF;
  position: relative;
}
.screen-list {
  max-height: 70%;
  overflow-y: scroll;
}
.screen-list__inner {
  margin-bottom: 20px;
  padding: 20px 15px 17px 24px;
  height: 83px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background: #FDFEFF;
  box-shadow: 0 1px 4px 0 rgba(10, 36, 99, 0.12), 0 0 8px 0 rgba(10, 36, 99, 0.06);
  border-radius: 8px;
}
.screen-list__inner-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.screen-list__inner-left-top {
  font-size: 15px;
  font-weight: 600;
  color: #0A2463;
}
.screen-list__inner-left-bottom {
  font-size: 14px;
  color: #8491B1;
}
.screen-list__inner-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 14px;
  color: #136FD1;
}
.icon__QRCode {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../../../../assets/images/icon__qrcode.png") no-repeat center center;
  background-size: 100%;
}
.button__default {
  position: absolute;
  bottom: calc(20% - 56px);
  left: 50%;
  width: calc(100% - 32px);
  transform: translateX(-50%);
}
.screen__none {
  position: absolute;
  top: 40%;
  left: 50%;
  width: calc(100% - 40px);
  transform: translate(-50%, -50%);
  text-align: center;
}
.screen__none__inner {
  font-size: 15px;
  color: #0A2463;
}
.screen__none-bg {
  display: block;
  margin: 0 auto 32px;
}
</style>
