<template>
  <div v-loading="loading" class="record">
    <h3 class="component__title">筛查点列表</h3>
    <header class="header" v-if="user.roleFlag !== 2">
      <div class="header-left">
        <el-button class="header-cell" @click="showAdd()"><i class="icon-add"></i>新增</el-button>
<!--        <div class="select-picker header-cell">-->
<!--          <span class="select-label">所属地：</span>-->
<!--          <el-cascader-->
<!--            :options="areaOptions"-->
<!--            v-model="selectedAreaOptions"-->
<!--            @change="handleChangeArea"-->
<!--          ></el-cascader>-->
<!--        </div>-->
        <div class="select-picker header-cell">
          <span class="select-label">所属地：</span>
          <china-area-data v-model="selectedAreaOptions.arr" @change="handleChangeArea"></china-area-data>
        </div>
        <div class="select-picker header-cell">
          <span class="select-label">报名方式：</span>
          <el-select v-model="enrollWay" @change="handleChangeEnrollWay" placeholder="请选择报名方式">
            <el-option
              v-for="item in enrollWayOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-picker header-cell">
          <span class="select-label">状态：</span>
          <el-select v-model="status" @change="handleChangeStatus" placeholder="请选择报名方式">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-input
          class="header-cell"
          v-model="searchText"
          clearable
          placeholder="请输入筛查点名称"
          @change="handleSearchChange"
        >
          <template #suffix>
            <i class="icon icon-search"></i>
          </template>
        </el-input>
      </div>
      <div class="header-right">
        <div class="refresh" @click="handleRefresh">
          <i class="icon refresh__inner"></i>
        </div>
      </div>
    </header>
    <main class="main">
      <div class="table-wrapper">
        <div class="table">
          <el-table
            :data="tableData"
            height="100%"
          >
            <el-table-column type="index" label="序号" width="80" align="center" />
            <el-table-column property="id" label="筛查点Id" align="center" />
            <el-table-column property="name" label="筛查点名称" align="center" />
            <el-table-column property="habitation" label="所属地" align="center" />
            <el-table-column property="address" label="详细地址" align="center" />
            <el-table-column label="类型" align="center">
              <template #default="scope">
                {{parseInt(scope.row.typeId) === 1 ? '医院' : '社康'}}
              </template>
            </el-table-column>
            <el-table-column label="报名方式" align="center">
              <template #default="scope">
                {{parseInt(scope.row.enrollWay) === 1 ? '仅支持线上报名' : '支持现场和线上报名'}}
              </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
              <template #default="scope">
                {{parseInt(scope.row.state) === 1 ? '正常' : '停用'}}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template #default="scope">
                <i class="operate-icon operate-icon-detail" @click="showDetail(scope.$index, scope.row)"></i>
                <i class="operate-icon operate-icon-edit" @click="showEdit(scope.$index, scope.row)"></i>
                <i class="operate-icon operate-icon-delete" v-if="user.roleFlag !== 2" @click="showDelete(scope.$index, scope.row)"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </main>
    <div class="pagination">
      <span>显示第{{(pagination.currentPage - 1) * pagination.pageSize + 1}}到{{pagination.currentPage * pagination.pageSize}}条记录，总共{{pagination.total}}条记录，每页显示{{pagination.pageSize}}条记录</span>
      <el-pagination
        v-model:currentPage="pagination.currentPage"
        :page-size="pagination.pageSize"
        background
        layout="prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <point-frame
      v-model:show="show"
      :point-id="selectedPoint.point.id"
      :operate="operate"
      @frameClose="handleFrameClose"
    ></point-frame>
    <frame-only-text
      title="删除筛查点"
      text="是否要删除该筛查点？"
      btn-left="是"
      btn-right="否"
      v-model:show="showDeleteFlag"
      @clickLeft="handleDelete"
      @clickRight="handleCancelDelete"
      @frameClose="handleFrameClose"
    ></frame-only-text>
  </div>
</template>
<script>
import { ref, reactive, onMounted, computed } from 'vue'
import PointFrame from '@/components/backend/components/point/PointFrame'
import FrameOnlyText from '@/components/backend/components/FrameOnlyText'
import ChinaAreaData from '@/components/backend/components/ChinaAreaData'
import { ElMessage } from 'element-plus'
import { getScreeningPointList, updateScreeningPointInfo } from '@/components/backend/assets/api/request'
import { useStore } from 'vuex'

const PAGESIZE = 10

export default {
  name: 'Point',
  components: {
    PointFrame,
    FrameOnlyText,
    ChinaAreaData
  },
  setup () {
    onMounted(() => {
      if (parseInt(user.value.roleFlag) === 2) {
        searchText.value = user.value.pointName
      }
      handleGetScreeningPointList()
    })
    const loading = ref(false)
    const store = useStore()
    const user = computed(() => store.state.user)
    const area = reactive([])
    const tableData = reactive([])
    const searchText = ref('')
    const pagination = reactive({
      currentPage: 1,
      pageSize: PAGESIZE,
      total: 100
    })
    const authorityStatus = ref(-1)
    const authorityStatusOptions = reactive([
      {
        value: -1,
        label: '全部'
      },
      {
        value: 1,
        label: '已授权'
      },
      {
        value: 0,
        label: '未授权'
      }
    ])
    const enrollWay = ref('')
    const enrollWayOptions = reactive([
      {
        value: '',
        label: '全部'
      },
      {
        value: 1,
        label: '仅支持线上报名'
      },
      {
        value: 2,
        label: '支持现场和线上报名'
      }
    ])
    const status = ref('')
    const statusOptions = reactive([
      {
        value: '',
        label: '全部'
      },
      {
        value: 1,
        label: '正常'
      },
      {
        value: 0,
        label: '停用'
      }
    ])
    const selectedAreaOptions = reactive({ arr: [''] })
    const show = ref(false)
    const operate = ref(0)
    const showDeleteFlag = ref(false)
    const selectedPoint = reactive({ point: {} })

    /**
     * 获取筛查点列表
     * */
    function handleGetScreeningPointList () {
      loading.value = true
      try {
        getScreeningPointList(
          {
            name: searchText.value,
            habitation: selectedAreaOptions.arr.join(''),
            enrollWay: enrollWay.value,
            state: status.value,
            pageNum: pagination.currentPage,
            pageSize: pagination.pageSize
          },
          res => {
            pagination.total = parseInt(res.data.data.total)
            tableData.splice(0, tableData.length)
            tableData.push(...res.data.data.list)
            loading.value = false
          },
          () => {
            loading.value = false
          }
        )
      } catch (e) {
        loading.value = false
      }
    }

    /**
     * 删除筛查点，与更新共用一个接口，只需传递筛查点id和删除标志
     * */
    function handleUpdateScreeningPointInfo (point) {
      loading.value = true
      const data = {
        screeningPoint: {
          id: point.id,
          enableFlag: 0
        }
      }
      try {
        updateScreeningPointInfo(data, () => {
          ElMessage({
            message: '删除筛查点完成',
            type: 'success',
            duration: 2000,
            center: true
          })
          loading.value = false
        }, () => {
          loading.value = false
        })
      } catch (e) {
        loading.value = false
      }
    }

    /**
     * 筛查点所属地改变时触发
     * */
    function handleChangeArea (value) {
      // selectedAreaOptions.splice(0, selectedAreaOptions.length)
      // selectedAreaOptions.push(...value)
      handleGetScreeningPointList()
    }

    /**
     * 筛查点状态改变时触发
     * */
    function handleChangeStatus (value) {
      status.value = value
      handleGetScreeningPointList()
    }

    /**
     * 报名方法改变时触发
     * */
    function handleChangeEnrollWay (value) {
      enrollWay.value = value
      handleGetScreeningPointList()
    }

    /**
     * 要删除筛查点时触发
     * */
    function showDelete (index, row) {
      selectedPoint.point = row
      showDeleteFlag.value = !showDeleteFlag.value
    }

    /**
     * 确定删除筛查点
     * */
    function handleDelete () {
      showDeleteFlag.value = !showDeleteFlag.value
      selectedPoint.point.enableFlag = 0
      handleUpdateScreeningPointInfo(selectedPoint.point)
      selectedPoint.point = {}
    }

    /**
     * 取消删除筛查点
     * */
    function handleCancelDelete () {
      showDeleteFlag.value = !showDeleteFlag.value
      selectedPoint.point = {}
    }

    /**
     * 弹窗关闭，重置选中筛查点，刷新筛查点列表
     * */
    function handleFrameClose () {
      selectedPoint.point = {}
      handleGetScreeningPointList()
    }

    /**
     * 新增筛查点
     * */
    function showAdd () {
      selectedPoint.point = { id: -1 }
      operate.value = 1
      show.value = !show.value
    }

    /**
     * 编辑筛查点
     * */
    function showEdit (index, scope) {
      selectedPoint.point = scope
      operate.value = 1
      show.value = !show.value
    }

    /**
     * 展示筛查点详细信息
     * */
    function showDetail (index, scope) {
      selectedPoint.point = scope
      operate.value = 0
      show.value = true
    }

    /**
     * 刷新
     * */
    function handleRefresh () {
      handleGetScreeningPointList()
    }

    /**
     * 搜索时触发
     * */
    function handleSearchChange () {
      handleGetScreeningPointList()
    }

    /**
     * 用户表格翻页时触发
     * */
    function handleCurrentChange (page) {
      pagination.currentPage = page
      handleGetScreeningPointList()
    }

    return {
      loading,
      tableData,
      show,
      showEdit,
      showDetail,
      handleRefresh,
      searchText,
      authorityStatus,
      authorityStatusOptions,
      enrollWay,
      enrollWayOptions,
      handleChangeEnrollWay,
      status,
      statusOptions,
      handleChangeStatus,
      handleSearchChange,
      pagination,
      handleCurrentChange,
      selectedAreaOptions,
      handleChangeArea,
      showDelete,
      showDeleteFlag,
      handleDelete,
      handleCancelDelete,
      selectedPoint,
      handleFrameClose,
      operate,
      showAdd,
      area,
      user
    }
  }
}
</script>

<style scoped>
.record {
  margin-top: 30px;
  padding: 0 50px;
  height: calc(100% - 66px);
  box-sizing: border-box;
}
.component__title {
  margin-bottom: 20px;
  padding-left: 10px;
  text-align: left;
  color: rgba(10, 36, 99, 1);
  font-size: 16px;
  font-weight: 600;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.header .icon-add {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background: url("../assets/images/icon__add.png") no-repeat center center;
  background-size: 100%;
}
.header .header-cell {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.header .header-cell + .header-cell {
  margin-left: 40px;
}
.header .header-left :deep(.el-button) {
  width: 115px;
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  color: #FFFFFF;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
  border-radius: 17px;
}
.header .header-left :deep(.el-button span) {
  display: flex;
  align-items: center;
}
.header .header-right, .header .header-left {
  display: flex;
  align-items: center;
}
.header :deep(.el-input) {
  max-width: 225px;
  height: 36px;
}
.header :deep(.el-input__inner) {
  height: 100%;
}
.header .icon-search {
  display: inline-block;
  width: 19px;
  height: 100%;
  vertical-align: top;
  background: url("../assets/images/icon__search.png") no-repeat center;
  background-size: 100%;
}
.header .header-right .refresh {
  min-width: 36px;
  height: 36px;
  margin-left: 68px;
  cursor: pointer;
}
.header .header-right .refresh .refresh__inner {
  display: inline-block;
  height: 100%;
  width: 100%;
  background: url("../assets/images/icon__refresh.png") no-repeat center center;
  background-size: 70%;
}
.main {
  height: calc(100% - 120px);
  width: 100%;
}
.main .table-wrapper {
  height: 100%;
  overflow: hidden;
}
.main .table-wrapper .table {
  height: 100%;
  width: calc(100% + 16px);
}
.main :deep(.el-table th.el-table__cell) {
  font-size: 15px;
  color: #888888;
  background: #F2F3F7;
}
.main :deep(.el-table .el-table__body .cell) {
  height: 33px;
  line-height: 33px;
  font-size: 14px;
  color: #0A2463;
}
.main .operate-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 0 8px;
  vertical-align: middle;
  cursor: pointer;
}
.main .operate-icon-detail {
  background: url("../assets/images/icon__operate-detail.png");
}
.main .operate-icon-edit {
  background: url("../assets/images/icon__operate-edit.png");
}
.main .operate-icon-delete {
  background: url("../assets/images/icon__operate-delete.png");
}
.pagination {
  position: absolute;
  bottom: 0;
  display: flex;
  width: calc(100% - 100px);
  align-items: center;
  justify-content: space-between;
  color: #7D7D7D;
}
.record {
  position: relative;
}
</style>
