<template>
  <div class="template-main" v-loading="loading">
    <div class="header">
      <div class="header-select">
        <span class="demonstration">筛查点：</span>
        <el-select
          v-if="parseInt(user.roleFlag) !== 2"
          v-model="pointList.value"
          @change="handleChangePoint"
          placeholder="请选择筛查点"
        >
          <el-option
            v-for="item in pointList.data"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <span v-else>{{pointList.name}}</span>
      </div>
    </div>
    <div class="sys-sum">
      <div class="sys-sum-item apply">
        <div class="sys-sum-item-text">
          <span class="item-sum-key">报名总人数</span>
          <span class="item-sum-value">{{summary.apply}}</span>
        </div>
      </div>
      <div class="sys-sum-item newApply">
        <div class="sys-sum-item-text">
          <span class="item-sum-key">今日新增报名人数</span>
          <span class="item-sum-value">{{summary.newApply}}</span>
        </div>
      </div>
      <div class="sys-sum-item screen">
        <div class="sys-sum-item-text">
          <span class="item-sum-key">今日已经筛查人数</span>
          <span class="item-sum-value">{{summary.screen}}</span>
        </div>
      </div>
      <div class="sys-sum-item applyLive">
        <div class="sys-sum-item-text">
          <span class="item-sum-key">今日现场报名人数</span>
          <span class="item-sum-value">{{summary.applyLive}}</span>
        </div>
      </div>
    </div>
    <div class="sys-statistics">
      <div class="sys-statistics__chart" ref="lineChartRef"></div>
      <div class="chart-date-group">
        <div class="chart-date" :class="chartDate === 1 ? 'is-active' : ''" @click="handleChangeChartDate(1)">近七日</div>
        <div class="chart-date" :class="chartDate === 2 ? 'is-active' : ''" @click="handleChangeChartDate(2)">近30日</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import * as echarts from 'echarts'
import { getScreeningPointList, getHome } from '@/components/backend/assets/api/request'
import { useStore } from 'vuex'
import { formatDateYYMMDDHasSplit2 } from '@/components/backend/assets/js/util'

export default {
  name: 'Summary',
  setup () {
    onMounted(() => {
      if (parseInt(user.value.roleFlag) === 2) {
        pointList.value = user.value.pointId
        pointList.name = user.value.pointName
      }
      handleGetScreeningPointList()
      handleGetHome()
    })
    const loading = ref(false)
    const store = useStore()
    const user = computed(() => store.state.user)
    const pointList = reactive({
      value: 0,
      name: '',
      data: []
    })
    const summary = reactive({
      apply: 12345,
      newApply: 123,
      screen: 1234,
      applyLive: 234
    })
    const weekSum = reactive({
      x: [],
      sum: []
    })
    const monthSum = reactive({
      x: [],
      sum: []
    })
    const lineChart = reactive({ chart: undefined })
    const lineChartRef = ref(null)
    const chartDate = ref(1)
    const lineOptions = {
      color: ['#136FD1'],
      title: {
        show: true,
        text: '报名筛查趋势',
        padding: 20,
        textStyle: {
          color: '#757575',
          fontWeight: 400,
          fontSize: 16
        }
      },
      grid: {
        top: 90
      },
      tooltip: {
        trigger: 'axis',
        formatter: '{b}<br />报名人数：{c}',
        backgroundColor: '#fff',
        borderColor: '#136FD1',
        borderWidth: 1,
        padding: [10, 45, 10, 10],
        textStyle: {
          color: '#212121',
          fontSize: 15,
          fontFamily: 'PingFangSC-Regular'
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: '#212121',
          fontSize: 15,
          padding: 15
        },
        data: ['1月1日']
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          color: '#212121',
          fontSize: 15,
          padding: 15
        }
      },
      series: [
        {
          name: 'turnoverTrend',
          type: 'line',
          // smooth: true, // 圆滑曲线
          lineStyle: {
            width: 3
          },
          symbolSize: 8,
          data: [50]
        }
      ]
    }

    function handleGetStatistics () {
      if (lineChart.chart) {
        lineChart.chart.dispose()
      }
      lineChart.chart = echartInit(lineChartRef.value, lineOptions)
      // handleChangeChartDate(1)
    }

    function handleChangeChartDate (num) {
      chartDate.value = num
      handleDataChange()
    }

    function handleDataChange () {
      if (chartDate.value === 1) {
        lineOptions.xAxis.data = weekSum.x
        lineOptions.series[0].data = weekSum.sum
      } else {
        lineOptions.xAxis.data = monthSum.x
        lineOptions.series[0].data = monthSum.sum
      }
      handleGetStatistics()
    }

    /**
     * 获取筛查点列表
     */
    function handleGetScreeningPointList () {
      loading.value = true
      getScreeningPointList(
        {
          pageNum: 1,
          pageSize: 999
        },
        res => {
          pointList.data.push(...res.data.data.list)
          pointList.data.unshift({
            id: 0,
            name: '全部'
          })
          loading.value = false
        },
        () => {
          loading.value = false
        }
      )
    }

    function handleGetHome () {
      loading.value = true
      const today = formatDateYYMMDDHasSplit2(new Date().getTime())
      // signTime默认为当天
      const data = {
        signTime: today
      }
      if (pointList.value !== 0) {
        data.pointId = pointList.value
      }
      getHome(
        data,
        res => {
          if (res.data && res.data.data && res.data.data.todaySum) {
            summary.apply = res.data.data.todaySum.sum
            summary.screen = res.data.data.todaySum.checkToday
            summary.newApply = res.data.data.todaySum.signToday
            summary.applyLive = res.data.data.todaySum.sumLive
          }
          if (res.data && res.data.data && res.data.data.sumBy7day) {
            let dateTamp = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 8, 0, 0).getTime()
            const sumBy7DayTamp = res.data.data.sumBy7day.map(day => new Date(day.day).getTime())
            weekSum.x = []
            weekSum.sum = []
            for (let i = 0; i < 7; i++) {
              const index = sumBy7DayTamp.indexOf(dateTamp)
              const date = new Date(dateTamp)
              weekSum.x.push(`${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`)
              if (index !== -1) {
                weekSum.sum.push(res.data.data.sumBy7day[index].sum)
              } else {
                weekSum.sum.push(0)
              }
              dateTamp -= 1000 * 60 * 60 * 24
            }
            weekSum.x.reverse()
            weekSum.sum.reverse()
          }
          if (res.data && res.data.data && res.data.data.sumBy30day) {
            let dateTamp = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 8, 0, 0).getTime()
            const sumBy30DayTamp = res.data.data.sumBy30day.map(day => new Date(day.day).getTime())
            monthSum.x = []
            monthSum.sum = []
            for (let i = 0; i < 30; i++) {
              const index = sumBy30DayTamp.indexOf(dateTamp)
              const date = new Date(dateTamp)
              monthSum.x.push(`${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`)
              if (index !== -1) {
                monthSum.sum.push(res.data.data.sumBy30day[index].sum)
              } else {
                monthSum.sum.push(0)
              }
              dateTamp -= 1000 * 60 * 60 * 24
            }
            monthSum.x.reverse()
            monthSum.sum.reverse()
          }
          handleDataChange()
          loading.value = false
        },
        () => {
          loading.value = false
        }
      )
    }

    /**
     * 选择筛查点时触发，更改筛查点信息
     */
    function handleChangePoint () {
      handleGetHome()
    }

    /**
     * echarts初始化
     * @param ref
     * @param options
     * @returns {EChartsType}
     */
    function echartInit (ref, options) {
      const chart = echarts.init(ref)
      chart.setOption(options)
      return chart
    }

    return {
      loading,
      pointList,
      handleChangePoint,
      summary,
      lineChartRef,
      handleChangeChartDate,
      chartDate,
      user
    }
  }
}
</script>

<style scoped>
.template-main {
  height: 100%;
  background-color: #FBFBFB;
}

.template-main .header {
  display: flex;
  padding: 19px 16px;
  align-items: center;
  font-size: 15px;
  color: #757575
}

.template-main .header .demonstration {
  margin: 0 20px;
}

.template-main .sys-sum {
  display: flex;
  height: 319px;
  margin-left: 20px;
  align-items: center;
}

.template-main .sys-sum .sys-sum-item {
  position: relative;
  flex-basis: 25%;
  height: 319px;
  font-size: 15px;
  color: #FFF
}

.template-main .sys-sum .sys-sum-item .sys-sum-item-text {
  position: absolute;
  left: 60px;
  top: 50%;
  transform: translateY(-100%);
  text-align: left;
}

.template-main .sys-sum .sys-sum-item .item-sum-key {
  display: block;
  padding-bottom: 15px;
  font-size: 18px;
}

.template-main .sys-sum .sys-sum-item .item-sum-value {
  display: inline-block;
  font-size: 40px;
}

.template-main .sys-sum .apply {
  background: url("../assets/images/1.png") no-repeat;
}
.template-main .sys-sum .newApply {
  background: url("../assets/images/2.png") no-repeat;
}
.template-main .sys-sum .screen {
  background: url("../assets/images/3.png") no-repeat;
}
.template-main .sys-sum .applyLive {
  background: url("../assets/images/4.png") no-repeat;
}
.template-main .sys-statistics {
  padding: 10px 40px 96px 40px;
  box-sizing: border-box;
  position: relative;
}

.template-main .sys-statistics .sys-statistics__chart {
  width: 100%;
  height: 463px;
  background: #FFF;
}

.template-main .sys-statistics .chart-date-group {
  position: absolute;
  right: 180px;
  top: 30px;
  display: flex;
  align-items: center;
}

.template-main .sys-statistics .chart-date-group .chart-date {
  width: 93px;
  height: 32px;
  line-height: 32px;
  font-size: 15px;
  color: #212121;
  border-radius: 4px;
  cursor: pointer;
}

.template-main .sys-statistics .chart-date-group .chart-date + .chart-date {
  margin-left: 30px;
}

.template-main .sys-statistics .chart-date-group .chart-date.is-active {
  background-color: #FFD11F;
  border-color: #FFD11F;
}
</style>
