<template>
  <div class="record">
    <h3 class="component__title">设备列表</h3>
    <header class="header">
      <div class="header-left">
        <el-button class="header-cell" @click="showEdit()"><i class="icon-add"></i>新增设备</el-button>
        <div class="select-picker header-cell">
          <span class="select-label">授权状态：</span>
          <el-select v-model="authorityStatus" placeholder="请选择授权状态">
            <el-option
              v-for="item in authorityStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-picker header-cell">
          <span class="select-label">设备使用状态：</span>
          <el-select v-model="usageStatus" placeholder="请选择设备使用状态">
            <el-option
              v-for="item in usageStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-input
          class="header-cell"
          v-model="searchText"
          clearable
          placeholder="请输入设备编号或筛查点名称"
          @change="handleSearchChange"
        >
          <template #suffix>
            <i class="icon icon-search"></i>
          </template>
        </el-input>
      </div>
      <div class="header-right">
        <div class="refresh" @click="handleRefresh">
          <i class="icon refresh__inner"></i>
        </div>
      </div>
    </header>
    <main class="main">
      <div class="table-wrapper">
        <div class="table">
          <el-table
            :data="tableData"
            height="100%"
          >
            <el-table-column type="index" label="序号" width="80" align="center" />
            <el-table-column property="equipId" label="设备编号" align="center" />
            <el-table-column property="equipName" label="设备名称" align="center" />
            <el-table-column property="equipType" label="产品型号" align="center" />
            <el-table-column property="point" label="所属筛查点" align="center" />
            <el-table-column property="authorityStatus" label="授权状态" align="center">
              <template #default="scope">
                {{scope.row.authorityStatus === 1 ? '已授权' : '未授权'}}
              </template>
            </el-table-column>
            <el-table-column property="status" label="设备使用状态" align="center">
              <template #default="scope">
                {{scope.row.status === 1 ? '正常' : '禁用'}}
              </template>
            </el-table-column>
            <el-table-column property="date" label="授权时间" align="center" />
            <el-table-column label="操作" align="center" width="180">
              <template #default="scope">
                <i class="operate-icon operate-icon-detail" @click="showEdit(scope.$index, scope.row)"></i>
                <i class="operate-icon operate-icon-edit" @click="showEdit(scope.$index, scope.row)"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </main>
    <div class="pagination">
      <span>显示第{{(pagination.currentPage - 1) * pagination.pageSize + 1}}到{{pagination.currentPage * pagination.pageSize}}条记录，总共{{pagination.total}}条记录，每页显示{{pagination.pageSize}}条记录</span>
      <el-pagination
        v-model:currentPage="pagination.currentPage"
        :page-size="pagination.pageSize"
        background
        layout="prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <equip-frame  v-model:show="show"></equip-frame>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import EquipFrame from '@/components/backend/components/equip/EquipFrame'

const PAGESIZE = 10

export default {
  name: 'Equip',
  components: {
    EquipFrame
  },
  setup () {
    onMounted(() => {
      tableData.push({
        equipId: 'LKHP0000001',
        equipName: '幽门螺杆菌PDA',
        equipType: 'LKHP01',
        point: '深圳大学总医院',
        authorityStatus: 1,
        status: 1,
        date: '2021/11/29'
      })
      for (let i = 0; i < 20; i++) {
        tableData.push({
          equipId: 'LKHP0000001',
          equipName: '幽门螺杆菌PDA',
          equipType: 'LKHP01',
          point: '深圳大学总医院',
          authorityStatus: 1,
          status: 1,
          date: '2021/11/29'
        })
      }
    })
    const tableData = reactive([])
    const searchText = ref('')
    const pagination = reactive({
      currentPage: 1,
      pageSize: PAGESIZE,
      total: 100
    })
    const authorityStatus = ref(-1)
    const authorityStatusOptions = reactive([
      {
        value: -1,
        label: '全部'
      },
      {
        value: 1,
        label: '已授权'
      },
      {
        value: 0,
        label: '未授权'
      }
    ])
    const usageStatus = ref(-1)
    const usageStatusOptions = reactive([
      {
        value: -1,
        label: '全部'
      },
      {
        value: 1,
        label: '正常'
      },
      {
        value: 0,
        label: '禁用'
      }
    ])
    const show = ref(false)

    /**
     * 编辑设备
     * */
    function showEdit (index, scope) {
      show.value = !show.value
    }

    /**
     * 刷新
     * */
    function handleRefresh () {}

    /**
     * 搜索时触发
     * */
    function handleSearchChange () {
    }

    /**
     * 用户表格翻页时触发
     * */
    function handleCurrentChange (page) {
      pagination.currentPage = page
      /**
       * 调用数据请求接口
       */
    }

    return {
      tableData,
      show,
      showEdit,
      handleRefresh,
      searchText,
      authorityStatus,
      authorityStatusOptions,
      usageStatus,
      usageStatusOptions,
      handleSearchChange,
      pagination,
      handleCurrentChange
    }
  }
}
</script>

<style scoped>
.record {
  margin-top: 30px;
  padding: 0 50px;
  height: calc(100% - 66px);
  box-sizing: border-box;
}
.component__title {
  margin-bottom: 20px;
  padding-left: 10px;
  text-align: left;
  color: rgba(10, 36, 99, 1);
  font-size: 16px;
  font-weight: 600;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.header .icon-add {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background: url("../assets/images/icon__add.png") no-repeat center center;
  background-size: 100%;
}
.header .header-left :deep(.el-button span) {
  display: flex;
  align-items: center;
}
.header .header-cell {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.header .header-cell + .header-cell {
  margin-left: 40px;
}
.header .header-left :deep(.el-button) {
  width: 115px;
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  color: #FFFFFF;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
  border-radius: 17px;
}
.header .header-right, .header .header-left {
  display: flex;
  align-items: center;
}
.header :deep(.el-input) {
  max-width: 225px;
  height: 36px;
}
.header :deep(.el-input__inner) {
  height: 100%;
}
.header .icon-search {
  display: inline-block;
  width: 19px;
  height: 100%;
  vertical-align: top;
  background: url("../assets/images/icon__search.png") no-repeat center;
  background-size: 100%;
}
.header .header-right .refresh {
  min-width: 36px;
  height: 36px;
  margin-left: 68px;
  cursor: pointer;
}
.header .header-right .refresh .refresh__inner {
  display: inline-block;
  height: 100%;
  width: 100%;
  background: url("../assets/images/icon__refresh.png") no-repeat center center;
  background-size: 70%;
}
.main {
  height: calc(100% - 120px);
  width: 100%;
}
.main .table-wrapper {
  height: 100%;
  overflow: hidden;
}
.main .table-wrapper .table {
  height: 100%;
  width: calc(100% + 16px);
}
.main :deep(.el-table th.el-table__cell) {
  font-size: 15px;
  color: #888888;
  background: #F2F3F7;
}
.main :deep(.el-table .el-table__body .cell) {
  height: 33px;
  line-height: 33px;
  font-size: 14px;
  color: #0A2463;
}
.main .operate-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 0 8px;
  vertical-align: middle;
  cursor: pointer;
}
.main .operate-icon-detail {
  background: url("../assets/images/icon__operate-detail.png");
}
.main .operate-icon-edit {
  background: url("../assets/images/icon__operate-edit.png");
}
.main .operate-icon-delete {
  background: url("../assets/images/icon__operate-delete.png");
}
.pagination {
  position: absolute;
  bottom: 0;
  display: flex;
  width: calc(100% - 100px);
  align-items: center;
  justify-content: space-between;
  color: #7D7D7D;
}
.record {
  position: relative;
}
</style>
