<template>
  <div class="main" v-loading="loading">
    <div class="select-wrapper">
      <span>区域</span>
      <el-select size="mini" v-model="area" @change="handleAreaChange" placeholder="Select">
        <el-option
          v-for="item in shenzhenArea"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="site-wrapper">
      <div class="site__label">筛查点</div>
      <div class="box-list">
        <div
          class="box"
          v-for="site in siteList"
          :key="site.id"
          :class="selectedSite.data && selectedSite.data.id === site.id ? 'is-checked' : ''"
          @click="handleChangeSite(site)"
        >
          <div class="site-name">{{site.name}}</div>
          <div class="site-address">{{site.address}}</div>
          <div class="site-type">
            <span v-if="site.enrollWay === 2">支持现场和线上报名
              <span v-if="site.onSiteState === '2'" style="color: #C91616;">非现场报名时间</span>
              <span v-else-if="site.onSiteState === '3'" style="color: #C91616;">现场报名人数已满</span>
              <span v-else-if="site.onSiteState === '4'" style="color: #C91616;">非现场报名时间</span>
            </span>
            <span v-else>仅支持线上报名</span>
          </div>
        </div>
        <div class="none-tip" v-if="siteList.length === 0">抱歉，该地区暂无筛查点</div>
      </div>
    </div>
    <div class="scene" v-if="selectedSite.data && selectedSite.data.onSiteState === '1'">
      <h3 class="scene-title">是否已到筛查点现场?</h3>
      <p class="scene__text">若已到筛查点现场，请点击“是”，则可直接当场进行筛查；若需要预约其他时间筛查，请点击“否”。</p>
      <el-radio-group v-model="scene">
        <el-radio :label=true>是</el-radio>
        <el-radio :label=false>否</el-radio>
      </el-radio-group>
    </div>
    <el-button class="button__default" @click="handleSubmit">确认报名</el-button>
    <my-dialog
      :dialog-visible="questionnaireDialogVisible"
      title="幽门螺杆菌筛查问卷调查"
      primary-btn-text="确定"
      @handlePrimary="handleSubmitQuestionnaire"
      @handleCancel="questionnaireDialogVisible = false"
      @handleCancelByOutsize="questionnaireDialogVisible = false"
    >
      <div class="form-box">
        <div class="form-item">
          <p>1、是否满18岁及以上，且非孕产妇和哺乳期妇女之外的人群？</p>
          <el-radio-group v-model="questionnaire[0].answer">
            <el-radio :label=true>是</el-radio>
            <el-radio :label=false>否</el-radio>
          </el-radio-group>
        </div>
        <div class="form-item">
          <p>2、是否在一个月内未服用抗生素、PPI等药物？</p>
          <el-radio-group v-model="questionnaire[1].answer">
            <el-radio :label=true>是</el-radio>
            <el-radio :label=false>否</el-radio>
          </el-radio-group>
        </div>
        <div class="agree" @click="questionnaire[2].answer = !questionnaire[2].answer ">
          <i class="icon__radio" :class="questionnaire[2].answer ? 'is-checked' : ''"></i>
          <p class="agree__inner">
            本人承诺对上述信息内容的真实性和完整性负责。如果信息不实或缺失引起不良后果，本人愿意承担由此带来的全部风险责任。
          </p>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { getScreeningPoint, userSignUp, userOrder, getPointPlan } from '@/assets/api/request'
import { formatDateYYMMDDHasSplit } from '@/assets/js/util'
import MyDialog from '@/components/MyDialog'

export default {
  name: 'Site',
  components: {
    MyDialog
  },
  setup () {
    onMounted(() => {
      if (route.query.first) {
        questionnaireDialogVisible.value = true
      }
      handleGetScreeningPoint('')
    })
    const loading = ref('false')
    const route = useRoute()
    const router = useRouter()
    const area = ref('')
    const shenzhenArea = reactive([
      {
        label: '全部',
        code: 440301,
        value: ''
      },
      {
        label: '罗湖区',
        code: 440303,
        value: '罗湖区'
      },
      {
        label: '福田区',
        code: 440304,
        value: '福田区'
      },
      {
        label: '南山区',
        code: 440305,
        value: '南山区'
      },
      {
        label: '宝安区',
        code: 440306,
        value: '宝安区'
      },
      {
        label: '龙岗区',
        code: 440307,
        value: '龙岗区'
      },
      {
        label: '盐田区',
        code: 440308,
        value: '盐田区'
      },
      {
        label: '龙华区',
        code: 440309,
        value: '龙华区'
      },
      {
        label: '坪山区',
        code: 440310,
        value: '坪山区'
      },
      {
        label: '光明区',
        code: 440311,
        value: '光明区'
      },
      {
        label: '大鹏新区',
        code: 440312,
        value: '大鹏新区'
      }
    ])
    const scene = ref(undefined)
    const siteList = reactive([])
    const selectedSite = reactive({ data: {} })
    const questionnaireDialogVisible = ref(false)
    const questionnaire = reactive([
      {
        answer: undefined
      },
      {
        answer: undefined
      },
      {
        answer: false
      }
    ])

    function handleSubmitQuestionnaire () {
      if (questionnaire[0].answer === undefined || questionnaire[1].answer === undefined || !questionnaire[2].answer) {
        ElMessage({
          message: '请完成问卷调查！',
          type: 'error',
          duration: 2000,
          center: true
        })
      } else if (!questionnaire[0].answer || !questionnaire[1].answer) {
        // questionnaireDialogVisible.value = false
        ElMessage({
          message: '对不起，您不符合幽门螺杆菌筛查条件,将回到筛查人列表界面',
          type: 'error',
          duration: 2000,
          center: true
        })
        setTimeout(() => {
          router.push({
            path: '/screenManage'
          })
        }, 1000)
      } else {
        questionnaireDialogVisible.value = false
      }
    }

    function handleAreaChange () {
      handleGetScreeningPoint(area.value)
    }

    function handleChangeSite (site) {
      selectedSite.data = site
    }

    function handleSubmit () {
      if (!selectedSite.data.id) {
        ElMessage({
          message: '请选择筛查点!',
          type: 'error',
          duration: 2000,
          center: true
        })
      } else {
        if (selectedSite.data.onSiteState === '1') {
          if (scene.value === undefined) {
            ElMessage({
              message: '请选择是否现场筛查!',
              type: 'error',
              duration: 2000,
              center: true
            })
          } else {
            handleUserSignUp()
          }
        } else {
          scene.value = false
          handleUserSignUp()
        }
      }
    }

    function handleUserSignUp () {
      loading.value = true
      userSignUp(
        {
          uuid: route.query.uuid,
          phone: route.query.phone,
          username: route.query.username,
          pointId: selectedSite.data.id,
          habitation: selectedSite.data.habitation,
          pointName: selectedSite.data.name,
          address: selectedSite.data.address,
          isLive: scene.value ? 1 : 2
        },
        res => {
          if (scene.value) {
            applyAtSite(res.data.data, selectedSite.data.id)
          } else {
            ElMessage({
              message: '报名成功!',
              type: 'success',
              duration: 2000,
              center: true
            })
            setTimeout(() => {
              router.push({
                path: '/screenRecord',
                query: {
                  uuid: route.query.uuid,
                  username: route.query.username
                }
              })
            }, 1000)
          }
        }
      )
      loading.value = false
    }

    /**
     * 获取筛查点列表状态
     * @param habitation： 筛查点所在行政区, ''表示全部
     */
    function handleGetScreeningPoint (habitation) {
      loading.value = true
      getScreeningPoint(
        {
          habitation
        },
        res => {
          siteList.splice(0, siteList.length)
          res.data.data.screeningPoints.forEach(point => {
            point.screeningPoint.onSiteState = point.onSiteState
            siteList.push(point.screeningPoint)
          })
        }
      )
      loading.value = false
    }

    function applyAtSite (recordId, siteId) {
      getPointPlan(
        { id: siteId },
        res => {
          for (let i = 0; i < res.data.data.planByDays.length; i++) {
            if (new Date(res.data.data.planByDays[i].planDate).toDateString() === new Date().toDateString()) {
              userOrder(
                {
                  id: recordId,
                  planId: res.data.data.planByDays[i].planId,
                  checkDate: formatDateYYMMDDHasSplit(res.data.data.planByDays[i].planDate),
                  checkStartTime: res.data.data.planByDays[i].startTimeLive,
                  checkEndTime: res.data.data.planByDays[i].endTimeLive
                },
                res => {
                  ElMessage({
                    message: '现场报名成功！',
                    type: 'success',
                    duration: 2000,
                    center: true
                  })
                  setTimeout(() => {
                    const query = {}
                    if (route.query.id) {
                      query.id = route.query.id
                    }
                    if (route.query.relationship !== '0') {
                      query.relationship = route.query.relationship
                    }
                    router.push({
                      path: '/screenCode',
                      query: query
                    })
                  }, 1000)
                }
              )
              break
            }
          }
        }
      )
    }

    return {
      loading,
      area,
      shenzhenArea,
      handleAreaChange,
      handleChangeSite,
      siteList,
      selectedSite,
      scene,
      handleSubmit,
      handleUserSignUp,
      questionnaireDialogVisible,
      questionnaire,
      handleSubmitQuestionnaire
    }
  }
}
</script>

<style scoped>
.select-wrapper {
  text-align: left;
}
.select-wrapper :deep(.el-select--mini) {
  width: 137px;
  height: 27px;
  margin-left: 16px;
}
.select-wrapper :deep(.el-input__inner) {
  color: #136FD1;
  font-size: 15px;
}
.site-wrapper {
  height: calc(100% - 260px);
  margin: 15px 0;
}
.site__label {
  margin-bottom: 9px;
  font-size: 18px;
  font-weight: 600;
}
.box-list {
  height: calc(100% - 30px);
  overflow: scroll;
  border-radius: 10px;
  background-color: #FFFFFF;
}
.none-tip {
  margin: 50px auto;
  text-align: center;
}
.box {
  padding: 17px 20px;
  border-bottom: 1px solid rgba(10, 36, 99, 0.1);
  position: relative;
}
.box.is-checked::after {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border: 1px solid #F3F3F3;
  border-radius: 50%;
  background: url("../../../assets/images/checked.png") no-repeat center center;
  background-size: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
.site-address {
  margin: 4px 0 10px 16px;
  font-size: 14px;
  color: #8491B1;
}
.site-address::before {
  content: '';
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-left: -16px;
  margin-right: 4px;
  background: url('../../../assets/images/icon__addr.png') no-repeat center center;
  background-size: 100%;
}
.site-type {
  font-size: 14px;
}
.scene {
  margin: 15px 0 22px;
  padding: 0 16px;
}
.scene-title {
  margin-bottom: 10px;
}
.scene__text {
  font-size: 14px;
  color: #8491B1;
}
.main {
  height: 100%;
  padding: 15px 16px;
  text-align: left;
  line-height: 20px;
  font-size: 15px;
  color: #0A2463;
  box-sizing: border-box;
}
.button__default {
  position: absolute;
  width: calc(100% - 30px);
  bottom: 30px
}
.form-box {
  font-size: 15px;
  line-height: 22px;
}
.agree {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.agree__inner::before {
  content: '*';
  display: inline-block;
  height: 15px;
  font-size: 20px;
  color: #C91616;
  vertical-align: middle;
}
.icon__radio {
  display: block;
  margin-right: 8px;
  min-width: 24px;
  height: 24px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #F3F3F3;
  border-radius: 50%;
  position: relative;
}
.form-box :deep(.el-radio .el-radio__inner) {
  width: 24px;
  height: 24px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #F3F3F3;
}
.form-box :deep(.el-radio + .el-radio) {
  margin-left: 60px;
}
.form-box :deep(.el-radio__input.is-checked .el-radio__inner) {
  background-color: inherit;
  border-color: transparent;
}
.form-box :deep(.el-radio .is-checked .el-radio__inner::after) {
  width: 10px;
  height: 10px;
  background-color: #5AFF25;
  box-shadow: 0 1px 3px 0 rgba(90, 255, 37, 0.5);
}
.icon__radio.is-checked::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #5AFF25;
  box-shadow: 0 1px 3px 0 rgba(90, 255, 37, 0.5);
  border: 1px solid #F3F3F3;
  border-radius: 50%;
}
@media screen and (max-width: 320px) {
  .box.is-checked::after {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border: 1px solid #F3F3F3;
    border-radius: 50%;
    background: url("../../../assets/images/checked.png") no-repeat center center;
    background-size: 100%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }
}
</style>
