<template>
  <div class="screen-code" v-loading="loading">
    <div class="screen-box">
      <img :src="info.encode" alt="" class="screen-box__code">
      <div>ID:{{info.uid}}</div>
      <div>请注意保护好您的个人隐私</div>
      <div class="line"></div>
      <div class="screen-box__name">{{info.name}}</div>
      <div>可截图保存当前筛查码</div>
      <div>筛查时出示给医护人员核实</div>
    </div>
    <el-button class="button__default" @click="handleSubmit">修改筛查人信息</el-button>
    <el-button class="button__text" type="text" @click="dialogVisible = true">删除筛查人信息</el-button>
    <my-dialog
      :dialog-visible="dialogVisible"
      primary-btn-text="取消"
      cancel-btn-text="确认"
      @handlePrimary="dialogVisible = false"
      @handleCancel="handleDeleteInfo"
      @handleCancelByOutsize="dialogVisible = false"
    >
      <p class="dialog__context__center">
        是否确认删除该筛查人信息记录
      </p>
    </my-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { getFamilyInfo, deleteFamily, getEncodeByName } from '@/assets/api/request'
import MyDialog from '@/components/MyDialog'

export default {
  name: 'ScreenCode',
  components: {
    MyDialog
  },
  setup () {
    onMounted(() => {
      loading.value = true
      const id = route.query.id
      const relationship = route.query.relationship
      getFamilyInfo(
        {
          id,
          relationship
        },
        res => {
          // console.log(res)
          info.name = res.data.data.username
          info.uid = res.data.data.uuid
          info.id = res.data.data.id
          getEncodeByName({ uuid: res.data.data.uuid }).then(res => {
            info.encode = res
          })
          loading.value = false
        },
        () => {
          loading.value = false
        }
      )
    })
    const loading = ref(false)
    const route = useRoute()
    const router = useRouter()
    const dialogVisible = ref(false)
    const info = reactive({
      name: '程晓明',
      id: '266688',
      uid: '',
      encode: ''
    })

    function handleSubmit () {
      const query = {
        role: 0,
        edit: 1
      }
      if (route.query.relationship) {
        query.role = 1
        query.familyId = info.id
        query.relationship = route.query.relationship
      }
      router.push({
        path: '/screenInfo',
        query: query
      })
    }

    function handleDeleteInfo () {
      loading.value = true
      const id = route.query.id
      const relationship = route.query.relationship
      deleteFamily(
        {
          id,
          relationship
        },
        res => {
          // console.log(res)
          ElMessage({
            message: '删除个人信息完成',
            type: 'success',
            duration: 2000,
            center: true
          })
          loading.value = false
        },
        () => {
          loading.value = false
        }
      )
      router.push({
        path: '/screenManage'
      })
    }

    return {
      loading,
      info,
      handleSubmit,
      dialogVisible,
      handleDeleteInfo
    }
  }
}
</script>

<style scoped>
.screen-code {
  min-height: 100%;
  background-color: rgba(10, 36, 99, .95);
  position: relative;
}
.screen-code .screen-box {
  position: absolute;
  top: 30px;
  right: 25px;
  left: 23px;
  padding: 24px 16px 16px;
  line-height: 25px;
  font-size: 14px;
  color: #8491B1;
  text-align: center;
  border-radius: 6px;
  background-color: #FDFEFF;
}
.screen-box__code {
  width: 184px;
  height: 184px;
  margin: 0 auto;
}
.line {
  height: 0;
  border-bottom: 2px dashed #0A2463;
  position: relative;
}
.line:before {
  content: '';
  position: absolute;
  width: 18px;
  height: 36px;
  left: -18px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #0A2463;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}
.line:after {
  content: '';
  position: absolute;
  width: 18px;
  height: 36px;
  right: -18px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #0A2463;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}
.screen-box__name {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 600;
  color: #0A2463;
}
.button__default {
  position: absolute;
  width: calc(100% - 32px);
  left: 50%;
  bottom: 91px;
  transform: translate(-50%);
}
.button__text {
  color: #FFFFFF;
  position: absolute;
  width: calc(100% - 32px);
  padding: 0;
  margin: 0;
  left: 50%;
  bottom: 43px;
  transform: translate(-50%);
}
.dialog__context__center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
  box-sizing: border-box;
  font-size: 15px;
  color: #0A2463;
}
@media screen and (max-width: 320px) {
  .button__default {
    bottom: 60px;
  }
  .button__text {
    bottom: 13px;
  }
}
</style>
