<template>
  <div class="component-main">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'User'
}
</script>

<style scoped>
.component-main {
  height: 100%;
  background-color: #FBFBFB;
  padding: 16px 60px;
  box-sizing: border-box;
}
</style>
