<template>
  <div class="ChinaAreaDrawer">
    <div class="ChinaAreaDrawer__inner" :class="value ? '' : 'placeholder'" @click="drawer = true">{{value ? value : placeholder}}</div>
    <el-drawer
      v-model="drawer"
      :title="title"
      direction="btt"
      :before-close="handleClose"
    >
      <div class="title">{{display ? display : placeholder}}</div>
      <div class="area-box">
        <div class="area">
          <div class="area__title">省份</div>
          <div class="area-scroll">
            <span
              class="area-scroll__inner"
              @click="handleSelectProvince('广东省', 440000)"
            >广东省</span>
            <span
              class="area-scroll__inner"
              v-for="(name, code, index) in province"
              :key="code + index"
              @click="handleSelectProvince(name, code)"
            >{{name}}</span>
          </div>
        </div>
        <div class="area">
          <div class="area__title">城市</div>
          <div class="area-scroll">
            <span
              class="area-scroll__inner"
              v-for="(name, code, index) in town"
              :key="code + index"
              @click="handleSelectTown(name, code)"
            >{{name}}</span>
          </div>
        </div>
        <div class="area" v-if='areaNum === 3'>
          <div class="area__title">区县</div>
          <div class="area-scroll">
            <span
              class="area-scroll__inner"
              v-for="(name, code, index) in region"
              :key="code + index"
              @click="handleSelectRegion(name, code)"
            >{{name}}</span>
          </div>
        </div>
      </div>
      <el-button class="button__default" @click="handleSubmit">确定</el-button>
    </el-drawer>
  </div>
</template>

<script>
import { ref, reactive, computed, watch } from 'vue'
const ChinaAreaData = require('../assets/js/ChinaAreaData.json')

export default {
  name: 'ChinaAreaDrawer',
  emits: ['update:value'],
  props: {
    title: String,
    value: String,
    placeholder: String,
    areaNum: {
      type: Number,
      default: 3,
      required: true
    }
  },
  setup (props, context) {
    const drawer = ref(false)
    const province = ChinaAreaData['86']
    delete province[440000]
    const town = computed(() => {
      return ChinaAreaData[result.province.code]
    })
    const region = computed(() => {
      return ChinaAreaData[result.town.code]
    })
    const display = computed(() => {
      return joint()
    })
    const result = reactive({
      province: {
        code: '',
        name: ''
      },
      town: {
        code: '',
        name: ''
      },
      region: {
        code: '',
        name: ''
      }
    })

    watch(() => props.value, val => {
      if (val !== joint()) {
        const codeArr = getCodeByName(val)
        if (codeArr[0]) {
          result.province.code = codeArr[0]
          result.province.name = val.split('/')[0]
        } else {
          result.province.code = ''
          result.province.name = ''
        }
        if (codeArr[1]) {
          result.town.code = codeArr[0]
          result.town.name = val.split('/')[1]
        } else {
          result.town.code = ''
          result.town.name = ''
        }
        if (codeArr[2]) {
          result.region.code = codeArr[0]
          result.region.name = val.split('/')[2]
        } else {
          result.region.code = ''
          result.region.name = ''
        }
      }
    })

    function joint () {
      return `${result.province.name ? result.province.name : ''}${result.town.name ? '/' + result.town.name : ''}${result.region.name ? '/' + result.region.name : ''}`
    }

    function getCodeByName (str) {
      const _result = []
      const valArr = str.split('/')
      for (let i = 0; i < valArr.length; i++) {
        if (i === 0) {
          _result[i] = getCode('86', valArr[i])
        } else {
          _result[i] = getCode(_result[i - 1], valArr[i])
        }
      }
      return _result
    }

    function getCode (preCode, area) {
      let _result
      const obj = ChinaAreaData[preCode]
      for (const param in obj) {
        if (area === obj[param]) {
          _result = param
        }
      }
      return _result
    }

    function handleSelectProvince (name, code) {
      result.town.code = ''
      result.town.name = ''
      result.region.code = ''
      result.region.name = ''
      result.province.code = code
      result.province.name = name
      // context.emit('update:value', joint())
    }

    function handleSelectTown (name, code) {
      result.region.code = ''
      result.region.name = ''
      result.town.code = code
      result.town.name = name
      // context.emit('update:value', joint())
    }

    function handleSelectRegion (name, code) {
      result.region.code = code
      result.region.name = name
      // context.emit('update:value', joint())
    }

    function handleSubmit () {
      context.emit('update:value', joint())
      handleClose()
    }

    function handleClose () {
      // console.log('close')
      drawer.value = false
    }

    return {
      drawer,
      province,
      town,
      region,
      result,
      handleSelectProvince,
      handleSelectTown,
      handleSelectRegion,
      handleClose,
      handleSubmit,
      display
    }
  }
}
</script>

<style scoped>
.ChinaAreaDrawer {
  width: 100%;
  height: 100%;
  text-align: center !important;
}
.ChinaAreaDrawer__inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  overflow: hidden;
  /*text-overflow: ellipsis;*/
}
.placeholder {
  color: #8491B1;
}
:deep(.el-drawer) {
  height: 360px !important;
}
:deep(.el-drawer__header) {
  height: 40px;
  padding: 12px;
  margin-bottom: 0;
  box-sizing: border-box;
  font-size: 14px;
  position: relative;
}
:deep(.el-drawer__close-btn) {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
:deep(.el-drawer__body) {
  padding: 0 12px;
}
.title {
  font-size: 16px;
  font-weight: 700;
}
.area {
  /*padding: 12px;*/
  height: 210px;
  flex-basis: 30%;
  flex-grow: 1;
  box-sizing: border-box;
  border-top: 1px solid #EDEDED;
  /*border: 1px solid #EDEDED;*/
}
.area + .area {
  border-left: 1px solid #EDEDED;
}
.area__title {
  padding: 12px;
  font-size: 15px;
  font-weight: 700;
  border-bottom: 1px solid #EDEDED;
}
.area-scroll {
  display: flex;
  height: 170px;
  flex-direction: column;
  overflow-y: scroll;
}
.area-scroll__inner {
  line-height: 25px;
}
.area-box {
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  box-shadow: 0 3px 6px 0 #DEDEDE;
  padding-bottom: 10px;
}
.button__default {
  margin-top: 10px;
  height: 30px;
}
</style>
