<template>
  <div v-loading="loading">
    <header class="header">
      <div class="header-block">计划ID：{{displayPlan.id}}</div>
      <div class="header-block">
        计划名称：
        <el-input v-model="displayPlan.planName" placeholder="请输入计划名称"></el-input>
      </div>
      <div class="header-block">
        制定人员：{{displayPlan.creator}}
      </div>
      <div class="header-block">
        计划状态：
        <el-switch
          v-model="displayPlan.state"
          active-color="#136FD1"
          inactive-color="#898989"
        />
        {{displayPlan.state ? '开启' : '结束'}}
      </div>
      <div class="header-block">
        计划开启日期：{{displayPlan.startDay}}
<!--        <el-date-picker-->
<!--          v-model="displayPlan.startDayDate"-->
<!--          type="date"-->
<!--          :clearable="false"-->
<!--        >-->
<!--        </el-date-picker>-->
      </div>
    </header>
    <my-time-picker class="time-picker" @changeMonth="handleChangeMonth" :plan="planList.data">
      <template v-slot:header-slot>
        <el-button class="button__edit" @click="openSetting">设置</el-button>
      </template>
    </my-time-picker>
    <el-button class="button__submit" @click="handleSubmit">确认</el-button>
    <record-edit-frame v-model:show="showSetting"></record-edit-frame>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElMessageBox, ElMessage } from 'element-plus'
import { formatDateYYMMDDHasSplit, pad, verifyingSpecialCharacter } from '@/components/backend/assets/js/util'
import { getPlanListByDay, updatePlan } from '@/components/backend/assets/api/request'
import MyTimePicker from '@/components/backend/components/MyTimePicker'
import RecordEditFrame from '@/components/backend/components/record/recordOperation/RecordEditFrame'

export default {
  name: 'RecordEdit',
  components: {
    MyTimePicker,
    RecordEditFrame
  },
  setup () {
    onMounted(() => {
      if (!plan.value.id) {
        ElMessageBox.alert('筛查点Id不能为空！', '提示', {
          confirmButtonText: '确定'
        }).then(() => {
          router.push({
            name: 'recordOpen'
          })
        }).catch(() => {})
      } else {
        displayPlan.id = plan.value.id
        displayPlan.planName = plan.value.planName
        displayPlan.creator = plan.value.creator
        displayPlan.state = !parseInt(plan.value.state === 0)
        displayPlan.startDay = formatDateYYMMDDHasSplit(plan.value.startDay)
        displayPlan.startDayDate = new Date(plan.value.startDay)
        const data = {
          month: `${new Date().getFullYear()}-${pad(new Date().getMonth() + 1)}`,
          planId: displayPlan.id
        }
        handleGetPlanListByDay(data)
      }
    })
    const loading = ref(false)
    const router = useRouter()
    const store = useStore()
    const plan = computed(() => store.state.plan)
    const displayPlan = reactive({})
    const planList = reactive({ data: [] })

    const showSetting = ref(false)

    watch(showSetting, value => {
      if (!value) {
        const data = {
          month: `${new Date().getFullYear()}-${pad(new Date().getMonth() + 1)}`,
          planId: displayPlan.id
        }
        handleGetPlanListByDay(data)
      }
    })

    function openSetting () {
      showSetting.value = true
    }

    function handleChangeMonth (value) {
      const data = {
        month: value,
        planId: displayPlan.id
      }
      handleGetPlanListByDay(data)
    }

    function handleGetPlanListByDay (data) {
      loading.value = true
      getPlanListByDay(
        data,
        res => {
          planList.data = []
          for (let i = 0; i < res.data.data.length; i++) {
            planList.data.push({
              date: formatDateYYMMDDHasSplit(res.data.data[i].planDate),
              rest: parseInt(res.data.data[i]) === 0,
              time: `${res.data.data[i].startTime}~${res.data.data[i].endTime}`,
              live: res.data.data[i].sumLive,
              maxLive: res.data.data[i].maxNumberLive,
              onLine: res.data.data[i].sum,
              maxOnLine: res.data.data[i].maxNumber
            })
          }
          loading.value = false
        },
        () => {
          loading.value = false
        }
      )
    }

    /**
     * 更新筛查计划信息
     * */
    function handleUpdatePlan () {
      loading.value = true
      const confirmText = displayPlan.state ? '确认提交修改？' : '计划将被关闭，确认提交修改？'
      ElMessageBox.confirm(confirmText, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        if (!verifyingSpecialCharacter(displayPlan.planName)) {
          ElMessage({
            message: '计划名称请勿携带特殊字符!',
            type: 'error'
          })
          loading.value = false
        } else {
          updatePlan(
            {
              id: displayPlan.id,
              planName: displayPlan.planName,
              state: displayPlan.state ? 1 : 0
            },
            () => {
              const data = {
                month: `${new Date().getFullYear()}-${pad(new Date().getMonth() + 1)}`,
                planId: displayPlan.id
              }
              ElMessage({
                message: '更新成功!',
                type: 'success'
              })
              handleGetPlanListByDay(data)
              loading.value = false
            },
            () => {
              loading.value = false
            }
          )
        }
      }).catch(() => {
        loading.value = false
      })
    }
    function handleSubmit () {
      handleUpdatePlan()
    }

    return {
      loading,
      displayPlan,
      showSetting,
      openSetting,
      handleChangeMonth,
      planList,
      handleSubmit
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  height: 62px;
  align-items: center;
  justify-content: space-between;
}
.header-block {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.header-block :deep(.el-input__inner) {
  color: #136FD1;
}
.button__edit {
  width: 134px;
  height: 36px;
  margin-left: 40px;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 15px;
}
.time-picker {
  height: calc(100% - 60px);
  overflow-y: scroll;
}
.button__submit {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-30%);
  width: 301px;
  height: 36px;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 15px;
}
</style>
