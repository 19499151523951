import { createRouter, createWebHistory } from 'vue-router'
// import { ElMessage } from 'element-plus'
import { authorize } from '@/assets/api/request'
import Storage from '@/assets/js/LocalStorage'
import routerEmsCnpl from '@/router/routerEmsCnpl'
import routerBackend from '@/router/routerBackend'

const routes = routerEmsCnpl.concat(routerBackend)

const storage = new Storage()

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('to:', to)
  // console.log('from:', from)
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.query.userId && to.query.userToken) {
    storage.setItem(
      {
        name: '__userInfo__',
        value: {
          userId: to.query.userId,
          userToken: to.query.userToken
        },
        expires: 300000 // 有效时间5分钟
      }
    )
  }
  const __userInfo__ = storage.getItem('__userInfo__')
  // console.log(__userInfo__)
  // if (!__userInfo__ || !__userInfo__.userId || !__userInfo__.userToken) {
  // 当用户打开localhost, to.matched === [],匹配到的是空路由,此时需要重定向到login
  // 重定向到login后，to.matched === [name: 'login', path: '/login'...]就是上一步的login页面
  // !to.matched.some(item => item.meta.requiresAuth)指进入的是不需要登录认证的页面，直接进入
  if (to.matched.length > 0 && !to.matched.some(item => item.meta.requiresAuth)) {
    next() // 跳过，进入下一个导航钩子
  } else {
    const urlPrefix = to.fullPath.split('/')[1]
    if ((!__userInfo__ || !__userInfo__.userId || !__userInfo__.userToken) && urlPrefix !== 'backend') {
      // LocalStorage中没有userInfo,进行微信授权请求
      authorize(to.path)
    }
    next()
    // next()
    // ElMessage.error('未登录或者登录验证错误，请重新登录！')
    // next({ path: '/login' })
  }
  // }
})

export default router
