<template>
  <div class="record">
    <header class="header">
      <div class="component__title">角色与权限管理</div>
      <div class="header__inner">
        <el-button class="header__inner__block" @click="showFrame()">新增</el-button>
        <div class="select-picker header__inner__block">
          <span class="select-label">用户类型：</span>
          <el-select v-model="userType" placeholder="请选择用户类型">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <el-input
          class="header__inner__block"
          v-model="searchText"
          clearable
          placeholder="用户ID/手机号"
          @change="handleSearchChange"
        >
          <template #suffix>
            <i class="icon icon-search"></i>
          </template>
        </el-input>
      </div>
    </header>
    <main class="main">
      <div class="table-wrapper">
        <div class="table">
          <el-table
            :data="tableData"
            height="100%"
          >
            <el-table-column type="index" label="序号" width="80" align="center" />
            <el-table-column property="username" label="用户姓名" align="center"></el-table-column>
            <el-table-column property="phone" label="联系方式" align="center"></el-table-column>
            <el-table-column
              label="用户类型"
              align="center"
            >
              <template #default="scope">
                {{userTypeMap[scope.row.type].label}}
              </template>
            </el-table-column>
            <el-table-column
              label="类型说明"
              width="225"
              align="center"
            >
              <template #default="scope">
                {{userTypeMap[scope.row.type].desc}}
              </template>
            </el-table-column>
            <el-table-column property="point" label="筛查点" align="center"></el-table-column>
            <el-table-column
              label="状态"
              align="center"
            >
              <template #default="scope">
                {{scope.row.status === 1 ? '启用' : '禁用'}}
                <el-switch
                  active-color="#318AFF"
                />
              </template>
            </el-table-column>
            <el-table-column property="createTime" label="添加时间" align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template #default="scope">
                <el-button class="operate-button" @click="showFrame(scope.$index, scope.row)">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </main>
    <div class="pagination">
      <span>显示第{{(pagination.currentPage - 1) * pagination.pageSize + 1}}到{{pagination.currentPage * pagination.pageSize}}条记录，总共{{pagination.total}}条记录，每页显示{{pagination.pageSize}}条记录</span>
      <el-pagination
        v-model:currentPage="pagination.currentPage"
        :page-size="pagination.pageSize"
        background
        layout="prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <add v-model:show="show"></add>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { userTypeMap } from '@/components/backend/assets/js/config'
import Add from '@/components/backend/components/authority/Add'

const PAGESIZE = 10

export default {
  name: 'Authority',
  components: {
    Add
  },
  setup () {
    onMounted(() => {
      tableData.push({
        username: '张三',
        phone: '13760109514',
        type: 1,
        typeDesc: '负责筛查执行医护和有关工作人员，使用登记APP，无法使用后台系统',
        point: '前海时代社区健康服务中心',
        status: 1,
        createTime: '2022/02/15'
      })
      for (let i = 0; i < 20; i++) {
        tableData.push({
          username: '张三',
          phone: '13760109514',
          type: 1,
          typeDesc: '负责筛查执行医护和有关工作人员，使用登记APP，无法使用后台系统',
          point: '前海时代社区健康服务中心',
          status: 1,
          createTime: '2022/02/15'
        })
      }
    })
    const router = useRouter()
    const tableData = reactive([])
    const searchText = ref('')
    const userType = ref(0)
    const typeOptions = reactive([
      {
        value: 0,
        label: '系统管理员'
      },
      {
        value: 1,
        label: '医院管理员'
      },
      {
        value: 2,
        label: '社康管理员'
      },
      {
        value: 3,
        label: '鸿美工作人员'
      }
    ])
    const searchDate = reactive([])
    const pagination = reactive({
      currentPage: 1,
      pageSize: PAGESIZE,
      total: 100
    })
    const show = ref(false)

    /**
     * 编辑
     * */
    function showFrame (index, scope) {
      show.value = !show.value
    }

    /**
     * 搜索时触发
     * */
    function handleSearchChange () {
    }

    /**
     * 用户表格翻页时触发
     * */
    function handleCurrentChange (page) {
      pagination.currentPage = page
      /**
       * 调用数据请求接口
       */
    }

    /**
     * 点击返回上一页面
     */
    function back () {
      router.go(-1)
    }

    return {
      back,
      tableData,
      showFrame,
      searchText,
      userType,
      typeOptions,
      searchDate,
      handleSearchChange,
      pagination,
      handleCurrentChange,
      userTypeMap,
      show
    }
  }
}
</script>

<style scoped>
.record {
  height: calc(100% - 36px);
  padding: 0 50px;
  box-sizing: border-box;
}
.header {
  /*display: flex;*/
  /*align-items: center;*/
  margin-bottom: 30px;
  position: relative;
}
.component__title {
  margin: 30px 0;
  text-align: left;
  color: rgba(10, 36, 99, 1);
  font-size: 16px;
  font-weight: 600;
}
.header .header__inner {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.header .header__inner .header__inner__block + .header__inner__block {
   margin-left: 60px;
 }
.header :deep(.el-button) {
  width: 115px;
  height: 36px;
  color: #FFFFFF;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
  border-radius: 17px;
}
.header .header__inner :deep(.el-input) {
  max-width: 225px;
  height: 36px;
}
.header :deep(.el-input__inner) {
  height: 100%;
}
.header .icon-search {
  display: inline-block;
  width: 19px;
  height: 100%;
  vertical-align: top;
  background: url("../assets/images/icon__search.png") no-repeat center;
  background-size: 100%;
}
.main {
  height: calc(100% - 156px);
  width: 100%;
}
.main .table-wrapper {
  height: 100%;
  overflow: hidden;
}
.main .table-wrapper .table {
  height: 100%;
  width: calc(100% + 16px);
}
.main :deep(.el-table th.el-table__cell) {
  font-size: 15px;
  color: #888888;
  background: #F2F3F7;
}
.main :deep(.el-table .el-table__body .cell) {
  /*height: 33px;*/
  line-height: 20px;
  font-size: 14px;
  color: #0A2463;
}
.main .operate-button {
  margin: 0;
  padding: 0 25px;
  height: 28px;
  font-size: 12px;
  background: #318AFF;
  color: #FFFFFF;
}
.pagination {
  position: absolute;
  bottom: 0;
  display: flex;
  width: calc(100% - 100px);
  align-items: center;
  justify-content: space-between;
  color: #7D7D7D;
}
.record {
  position: relative;
}
</style>
