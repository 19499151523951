<template>
  <teleport to="#main">
    <div :class="show ? 'frame-wrapper' : 'frame-wrapper__close'">
      <div :class="show ? 'frame' : 'frame__close'">
        <header class="header">
          <el-button class="header-button" @click="handleOpenSetting">{{editFlag ? '保存设置' : '开启设置'}}</el-button>
          <el-button type="text" class="header-button__text" @click="closeSetting">关闭</el-button>
        </header>
        <main class="main" v-if="!editFlag">
          <div class="main-left">
            <div class="main-left-top">
              <div class="box-title-wrapper">
                <span class="box-title">线上预约设置</span>
                <span class="check-date" v-if="selectDate.date">{{selectDate.date}}</span>
              </div>
              <div class="box-item">
                <span class="box-item__label">上午：</span>
                <span class="box-item__value">{{selectDate.onLine.rest ? '休息' : '筛查'}}</span>
              </div>
              <div class="box-item" v-if="!selectDate.onLine.rest">
                <span class="box-item__label">起止时段：</span>
                <span class="box-item__value">{{selectDate.onLine.time}}</span>
              </div>
              <div class="box-item" v-if="!selectDate.onLine.rest">
                <span class="box-item__label">可预约筛查人数：</span>
                <span class="box-item__value">{{selectDate.onLine.maxSum}}人</span>
              </div>
            </div>
            <div class="main-left-bottom">
              <div class="box-title-wrapper">
                <span class="box-title">现场报名预约设置</span>
              </div>
              <div class="box-item">
                <span class="box-item__label">上午：</span>
                <span class="box-item__value">{{selectDate.live.maxSum === 0 ? '休息' : '筛查'}}</span>
              </div>
              <div class="box-item" v-if="!(selectDate.live.maxSum === 0)">
                <span class="box-item__label">起止时段：</span>
                <span class="box-item__value">{{selectDate.live.time}}</span>
              </div>
              <div class="box-item" v-if="!(selectDate.live.maxSum === 0)">
                <span class="box-item__label">可现场报名预约筛查人数：</span>
                <span class="box-item__value">{{selectDate.live.maxSum}}人</span>
              </div>
            </div>
          </div>
          <div class="main-right">
            <div class="calendar-picker">
              <div class="calendar__header">
                <div class="calendar__header-group">
                  <i class="calendar__header-group__button icon-prev" @click="handlePrevYear"></i>
                  <span class="calendar__header-group__text">{{date.selectYear}}年</span>
                  <i class="calendar__header-group__button icon-next" @click="handleNextYear"></i>
                </div>
                <div class="calendar__header-group">
                  <i class="calendar__header-group__button icon-prev" @click="handlePrevMonth"></i>
                  <span class="calendar__header-group__text">{{date.selectMonth}}月</span>
                  <i class="calendar__header-group__button icon-next" @click="handleNextMonth"></i>
                </div>
              </div>
              <div class="calendar__body">
                <table class="calendar-table">
                  <thead>
                  <tr>
                    <th>一</th>
                    <th>二</th>
                    <th>三</th>
                    <th>四</th>
                    <th>五</th>
                    <th>六</th>
                    <th>日</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="calendar-table__row" v-for="(line, index) in dateList" :key="'line' + index">
                    <td v-for="count in line" :class="dayCls(index, count)" :key="'day' + count.value">
                      <div class="calendar-day" @click="handleSelectDate(index, count)">
                        <div class="calendar-day__inner">
                          <div class="calendar-day__inner-count">{{count.value}}</div>
                          <div class="calendar-day__plan">
                            <span>{{count.planStatus}}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
        <main class="main" v-else>
          <div class="main-left">
            <div class="main-left-top">
              <div class="box-title-wrapper">
                <span class="box-title">线上预约设置</span>
                <span class="check-date" v-if="editPlanList.data.length === 1">{{getFormatDate(editPlanList.data[0])}}</span>
              </div>
              <div>
                <div class="box-item">
                  <span class="box-item__label">上午：</span>
                  <div class="box-item__value">
                    <span>{{setting.onLine.setting ? '筛查 ' : '休息 '}}</span>
                    <el-switch
                      v-model="setting.onLine.setting"
                      @change="handleOnLineRest"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                    />
                  </div>
                </div>
                <div class="box-item" v-if="setting.onLine.setting">
                  <span class="box-item__label">起止时段：</span>
                  <div class="box-item__value">
                    <el-time-picker
                      v-model="setting.onLine.time"
                      is-range
                      :disabled-hours="disableHours"
                      :disabled-minutes="disableMinutesAndSeconds"
                      :disabled-seconds="disableMinutesAndSeconds"
                      range-separator="~"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                    >
                    </el-time-picker>
                  </div>
                </div>
                <div class="box-item" v-if="setting.onLine.setting">
                  <span class="box-item__label">可预约筛查人数：</span>
                  <el-input class="box-item__value" v-model="setting.onLine.num">
                    <template #append>人</template>
                  </el-input>
                </div>
              </div>
            </div>
            <div class="main-left-bottom">
              <div class="box-title-wrapper">
                <span class="box-title">现场报名预约设置</span>
              </div>
              <div>
                <div class="box-item">
                  <span class="box-item__label">上午：</span>
                  <span>{{setting.live.setting ? '筛查 ' : '休息 '}}</span>
                  <el-switch
                    v-model="setting.live.setting"
                    @change="handleLiveRest"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  />
                </div>
                <div class="box-item" v-if="setting.live.setting">
                  <span class="box-item__label">起止时段：</span>
                  <div class="box-item__value">
                    <el-time-picker
                      v-model="setting.live.time"
                      is-range
                      :disabled-hours="disableHours"
                      :disabled-minutes="disableMinutesAndSeconds"
                      :disabled-seconds="disableMinutesAndSeconds"
                      range-separator="~"
                      start-placeholder="开始时间"
                      end-placeholder="结束时间"
                    >
                    </el-time-picker>
                  </div>
                </div>
                <div class="box-item" v-if="setting.live.setting">
                  <span class="box-item__label">可现场报名预约筛查人数：</span>
                  <el-input class="box-item__value" v-model="setting.live.num">
                    <template #append>人</template>
                  </el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="main-right">
            <div class="calendar-picker">
              <div class="calendar__header">
                <div class="calendar__header-group">
                  <i class="calendar__header-group__button icon-prev" @click="handlePrevYear"></i>
                  <span class="calendar__header-group__text">{{date.selectYear}}年</span>
                  <i class="calendar__header-group__button icon-next" @click="handleNextYear"></i>
                </div>
                <div class="calendar__header-group">
                  <i class="calendar__header-group__button icon-prev" @click="handlePrevMonth"></i>
                  <span class="calendar__header-group__text">{{date.selectMonth}}月</span>
                  <i class="calendar__header-group__button icon-next" @click="handleNextMonth"></i>
                </div>
              </div>
              <div class="calendar__body">
                <table class="calendar-table">
                  <thead>
                  <tr>
                    <th>一</th>
                    <th>二</th>
                    <th>三</th>
                    <th>四</th>
                    <th>五</th>
                    <th>六</th>
                    <th>日</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="calendar-table__row" v-for="(line, index) in dateList" :key="'line' + index">
                    <td v-for="count in line" :class="dayCls(index, count)" :key="'day' + count.value">
                      <div class="calendar-day" @click="handleSelectDate(index, count)">
                        <div class="calendar-day__inner">
                          <div class="calendar-day__inner-count">{{count.value}}</div>
                          <div class="calendar-day__plan">
                            <span>{{count.planStatus}}</span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref, reactive, computed, watch } from 'vue'
import { ElMessageBox } from 'element-plus'
import { pad, formatDateYYMMDDHasSplit } from '@/components/backend/assets/js/util'
import { useStore } from 'vuex'
import { getPlanListByMonth, updatePlanByDay } from '@/components/backend/assets/api/request'

const WEEK = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']

export default {
  name: 'RecordEditFrame',
  emits: ['update:show'],
  props: {
    show: Boolean
  },
  setup (props, context) {
    const store = useStore()
    const storePlan = computed(() => store.state.plan)
    const editFlag = ref(false)
    const editOptions = reactive({
      restOnLine: true,
      startTimeOnLine: '',
      endTimeOnLine: '',
      maxOnLine: '',
      restLive: true,
      startTimeLive: '',
      endTimeLive: '',
      maxLive: ''
    })
    const today = new Date()
    const date = reactive({
      currentDay: today.getDate(),
      currentMonth: today.getMonth() + 1,
      currentYear: today.getFullYear(),
      selectMonth: today.getMonth() + 1,
      selectYear: today.getFullYear(),
      selectDay: 25
    })
    const preMonthDays = computed(() => {
      return new Date(date.selectYear, date.selectMonth - 1, 0).getDate()
    })
    const currentDays = computed(() => {
      return new Date(date.selectYear, date.selectMonth, 0).getDate()
    })
    const offsetDay = computed(() => {
      return (new Date(date.selectYear, date.selectMonth - 1, 1).getDay() - 1 + 7) % 7
    })
    const selectDate = reactive({
      onLine: {},
      live: {}
    })
    const setting = reactive({
      onLine: {
        setting: true,
        time: [],
        num: 0
      },
      live: {
        setting: true,
        time: [],
        num: 0
      }
    })
    const disableMinutesAndSeconds = () => {
      const result = []
      for (let i = 1; i < 60; i++) {
        result.push(i)
      }
      return result
    }
    const disableHours = () => {
      const result = []
      for (let i = 0; i < 7; i++) {
        result.push(i)
      }
      for (let i = 13; i < 24; i++) {
        result.push(i)
      }
      return result
    }

    const planList = reactive({ data: [] })

    const planDateList = reactive([])

    // watch(() => planList.data, value => {
    //   planDateList.length = 0
    //   console.log(value)
    //   for (let i = 0; i < value.length; i++) {
    //     // planDateList.push(new Date(value[i].date).getTime())
    //     planDateList.push(value[i].dateTamp)
    //   }
    // })

    watch(() => props.show, value => {
      if (value) {
        handleGetPlanListByMonth()
      }
    })

    const dateList = computed(() => {
      const result = []
      const toDayTime = today.getTime()
      result[0] = []
      for (let i = 0; i < offsetDay.value; i++) {
        result[0].push({
          value: preMonthDays.value - i
        })
      }
      result[0].reverse()
      for (let i = 0; i < 7 - offsetDay.value; i++) {
        const lineOneTime = new Date(date.selectYear, date.selectMonth - 1, i + 1).getTime()
        const index = planDateList.indexOf(lineOneTime)
        if (index !== -1) {
          result[0].push({
            value: i + 1,
            plan: planList.data[index],
            planStatus: planList.data[index].rest ? '休息' : lineOneTime < toDayTime ? '已完成' : '已安排'
          })
        } else {
          result[0].push({
            value: i + 1,
            planStatus: lineOneTime < toDayTime ? '' : '未安排'
          })
        }
      }
      for (let i = 1; i < 4; i++) {
        result[i] = []
        for (let j = 1; j <= 7; j++) {
          const lineTwoToFourTime = new Date(date.selectYear, date.selectMonth - 1, j + (7 - offsetDay.value) + 7 * (i - 1)).getTime()
          const index = planDateList.indexOf(lineTwoToFourTime)
          if (index !== -1) {
            result[i].push({
              value: j + (7 - offsetDay.value) + 7 * (i - 1),
              plan: planList.data[index],
              planStatus: planList.data[index].rest ? '休息' : lineTwoToFourTime < toDayTime ? '已完成' : '已安排'
            })
          } else {
            result[i].push({
              value: j + (7 - offsetDay.value) + 7 * (i - 1),
              planStatus: lineTwoToFourTime < toDayTime ? '' : '未安排'
            })
          }
        }
      }
      result[4] = []
      const len = currentDays.value - ((7 - offsetDay.value) + 21) <= 7 ? currentDays.value - ((7 - offsetDay.value) + 21) : 7
      for (let i = 1; i <= len; i++) {
        const lineFiveTime = new Date(date.selectYear, date.selectMonth - 1, i + (7 - offsetDay.value) + 7 * 3).getTime()
        const index = planDateList.indexOf(lineFiveTime)
        if (index !== -1) {
          result[4].push({
            value: i + (7 - offsetDay.value) + 7 * 3,
            plan: planList.data[index],
            planStatus: planList.data[index].rest ? '休息' : lineFiveTime < toDayTime ? '已完成' : '已安排'
          })
        } else {
          result[4].push({
            value: i + (7 - offsetDay.value) + 7 * 3,
            planStatus: lineFiveTime < toDayTime ? '' : '未安排'
          })
        }
      }
      const lenFour = result[4].length
      if (lenFour !== 7) {
        for (let i = 1; i <= 7 - lenFour; i++) {
          const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth, i).getTime())
          if (index !== -1) {
            result[4].push({
              value: i,
              plan: planList.data[index]
            })
          } else {
            result[4].push({
              value: i
            })
          }
        }
        result[5] = []
        for (let i = 7 - lenFour + 1; i <= 7 + 7 - lenFour; i++) {
          result[5].push({
            value: i
          })
        }
      } else {
        result[5] = []
        for (let i = 5 * 7 - offsetDay.value + 1; i <= currentDays.value; i++) {
          const lineSixTime = new Date(date.selectYear, date.selectMonth - 1, i).getTime()
          const index = planDateList.indexOf(lineSixTime)
          if (index !== -1) {
            result[5].push({
              value: i,
              plan: planList.data[index],
              planStatus: planList.data[index].rest ? '休息' : lineSixTime < toDayTime ? '已完成' : '已安排'
            })
          } else {
            result[5].push({
              value: i,
              planStatus: lineSixTime < toDayTime ? '' : '未安排'
            })
          }
        }
        const len5 = result[5].length
        for (let i = 1; i <= 7 - len5; i++) {
          const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth, i).getTime())
          if (index !== -1) {
            result[5].push({
              value: i,
              plan: planList.data[index]
            })
          } else {
            result[5].push({
              value: i
            })
          }
        }
      }
      return result
    })

    const editPlanList = reactive({ data: [] })
    const editIds = reactive({ data: [] })

    function dayCls (index, count) {
      if (index === 0 && count.value > 7) {
        return 'prev'
      } else if ((index === 4 || index === 5) && count.value <= 14) {
        return 'next'
      } else {
        if (editFlag.value) {
          if (editPlanList.data.indexOf(new Date(date.selectYear, date.selectMonth - 1, count.value).getTime()) !== -1) {
            if (count.plan) {
              return 'current is-plan is-active'
            } else {
              return 'current is-active'
            }
          } else {
            return 'current'
          }
        } else {
          if (count.plan) {
            if (new Date(count.plan.date).getTime() === new Date(date.selectYear, date.selectMonth - 1, date.selectDay).getTime()) {
              if (new Date(count.plan.date) < today) {
                return 'current is-plan is-complete is-active'
              } else {
                return 'current is-plan is-active'
              }
            } else {
              if (new Date(count.plan.date) < today) {
                return 'current is-plan is-complete'
              } else {
                return 'current is-plan'
              }
            }
          } else {
            if (new Date(date.selectYear, date.selectMonth - 1, count.value) < today) {
              return 'current is-complete'
            } else {
              return 'current'
            }
          }
        }
      }
    }

    function handlePrevYear () {
      date.selectYear--
      handleGetPlanListByMonth()
    }

    function handleNextYear () {
      date.selectYear++
      handleGetPlanListByMonth()
    }

    function handlePrevMonth () {
      if (date.selectMonth === 1) {
        date.selectYear--
        date.selectMonth = 12
      } else {
        date.selectMonth--
      }
      handleGetPlanListByMonth()
    }

    function handleNextMonth () {
      if (date.selectMonth === 12) {
        date.selectYear++
        date.selectMonth = 1
      } else {
        date.selectMonth++
      }
      handleGetPlanListByMonth()
    }

    function handleSelectDate (line, count) {
      if (line === 0 && count.value > 7) {
        handlePrevMonth()
      } else if ((line === 4 || line === 5) && count.value <= 14) {
        handleNextMonth()
      } else {
        if (editFlag.value) {
          // 编辑预约设置
          const index = editPlanList.data.indexOf(new Date(date.selectYear, date.selectMonth - 1, count.value).getTime())
          if (index === -1) {
            let f = false
            for (let i = 0; i < planList.data.length; i++) {
              if (new Date(date.selectYear, date.selectMonth - 1, count.value).getTime() === planList.data[i].dateTamp) {
                editPlanList.data.push(planList.data[i].dateTamp)
                editIds.data.push(planList.data[i].ids)
                f = true
                break
              }
            }
            if (!f) {
              editPlanList.data.push(new Date(date.selectYear, date.selectMonth - 1, count.value).getTime())
              editIds.data.push([])
            }
          } else {
            editPlanList.data.splice(index, 1)
            editIds.data.splice(index, 1)
          }
        } else {
          const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth - 1, count.value).getTime())
          if (index === -1) {
            ElMessageBox.alert('请选择已安排了计划的日期', '提示', {
              confirmButtonText: '确定'
            })
          } else {
            date.selectDay = count.value
            selectDate.onLine.rest = count.plan.rest
            if (!count.plan.rest) {
              selectDate.onLine.time = count.plan.time
              selectDate.onLine.sum = count.plan.onLine
              selectDate.onLine.maxSum = count.plan.maxOnLine
            }
            selectDate.live.time = `${count.plan.startTimeLive}~${count.plan.endTimeLive}`
            selectDate.live.sum = count.plan.live
            selectDate.live.maxSum = count.plan.maxLive
          }
        }
      }
    }

    function handleOpenSetting () {
      if (editFlag.value) {
        if (editPlanList.data.length === 0) {
          ElMessageBox.confirm('您并未安排任何计划，确认退出？', '保存退出', {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(() => {
            editFlag.value = !editFlag.value
            initSetting()
          })
        } else {
          ElMessageBox.confirm('您已安排相关计划，请确认计划设置是否正确，确认提交？', '提交计划', {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(() => {
            const tamp1 = setting.onLine.time[0].getTime()
            const tamp2 = setting.onLine.time[1].getTime()
            const dist = tamp2 - tamp1
            const count = dist / (60 * 60 * 1000)
            const list = []

            for (let i = 0; i < editPlanList.data.length; i++) {
              let startTimeHour = setting.onLine.time[0].getHours()
              if (count === 0) {
                if (setting.live.num === 0) {
                  list.push({
                    planDate: editPlanList.data[i],
                    planId: storePlan.value.id,
                    enrollWay: storePlan.value.enrollWay,
                    state: setting.onLine.setting ? 1 : 0,
                    startTime: '',
                    endTime: '',
                    startTimeLive: '',
                    endTimeLive: '',
                    maxNumber: 0,
                    maxNumberLive: setting.live.num
                  })
                } else {
                  list.push({
                    planDate: editPlanList.data[i],
                    planId: storePlan.value.id,
                    enrollWay: storePlan.value.enrollWay,
                    state: setting.onLine.setting ? 1 : 0,
                    startTime: '',
                    endTime: '',
                    startTimeLive: `${pad(setting.live.time[0].getHours())}:00`,
                    endTimeLive: `${pad(setting.live.time[1].getHours())}:00`,
                    maxNumber: 0,
                    maxNumberLive: setting.live.num
                  })
                }
              } else {
                for (let j = 0; j < count; j++) {
                  if (setting.live.num === 0) {
                    list.push({
                      planDate: editPlanList.data[i],
                      planId: storePlan.value.id,
                      startTime: `${pad(startTimeHour)}:00`,
                      endTime: `${pad(startTimeHour + 1)}:00`,
                      enrollWay: storePlan.value.enrollWay,
                      startTimeLive: '',
                      endTimeLive: '',
                      maxNumber: Math.floor(setting.onLine.num / count),
                      maxNumberLive: setting.live.num,
                      state: setting.onLine.setting ? 1 : 0
                    })
                  } else {
                    list.push({
                      planDate: editPlanList.data[i],
                      planId: storePlan.value.id,
                      startTime: `${pad(startTimeHour)}:00`,
                      endTime: `${pad(startTimeHour + 1)}:00`,
                      enrollWay: storePlan.value.enrollWay,
                      startTimeLive: `${pad(setting.live.time[0].getHours())}:00`,
                      endTimeLive: `${pad(setting.live.time[1].getHours())}:00`,
                      maxNumber: Math.floor(setting.onLine.num / count),
                      maxNumberLive: setting.live.num,
                      state: setting.onLine.setting ? 1 : 0
                    })
                  }
                  startTimeHour++
                }
                list[(i + 1) * count - 1].maxNumber += setting.onLine.num % count
              }
            }
            const ids = []
            for (let i = 0; i < editIds.data.length; i++) {
              ids.push(...editIds.data[i])
            }
            const data = {
              ids: ids,
              list: list
            }
            handleUpdatePlanByDay(data)
            initSetting()
            editFlag.value = !editFlag.value
          })
        }
      } else {
        initSetting()
        editFlag.value = !editFlag.value
      }
    }

    function closeSetting () {
      initSetting()
      editFlag.value = false
      context.emit('update:show', false)
    }

    function handleGetPlanListByMonth () {
      const data = {
        month: `${date.selectYear}-${pad(date.selectMonth)}`,
        planId: storePlan.value.id
      }
      getPlanListByMonth(data, res => {
        planList.data = []
        const planDate = []
        planDateList.splice(0, planDateList.length)
        for (let i = 0; i < res.data.data.length; i++) {
          const index = planDate.indexOf(res.data.data[i].planDate)
          if (index === -1) {
            planDate.push(res.data.data[i].planDate)
            const startTime = new Date(`${formatDateYYMMDDHasSplit(res.data.data[i].planDate)} ${res.data.data[i].startTime}`).getTime()
            const endTime = new Date(`${formatDateYYMMDDHasSplit(res.data.data[i].planDate)} ${res.data.data[i].endTime}`)
            planList.data.push({
              dateTamp: res.data.data[i].planDate,
              date: formatDateYYMMDDHasSplit(res.data.data[i].planDate),
              rest: parseInt(res.data.data[i].state) === 0,
              time: `${res.data.data[i].startTime}~${res.data.data[i].endTime}`,
              startTime: res.data.data[i].startTime,
              endTime: res.data.data[i].endTime,
              startTimeTamp: startTime,
              endTimeTamp: endTime,
              maxLive: res.data.data[i].maxNumberLive,
              maxOnLine: res.data.data[i].maxNumber,
              startTimeLive: res.data.data[i].startTimeLive,
              endTimeLive: res.data.data[i].endTimeLive,
              ids: [res.data.data[i].id]
            })
          } else {
            const startTime = new Date(`${formatDateYYMMDDHasSplit(res.data.data[i].planDate)} ${res.data.data[i].startTime}`).getTime()
            const endTime = new Date(`${formatDateYYMMDDHasSplit(res.data.data[i].planDate)} ${res.data.data[i].endTime}`)
            if (startTime < planList.data[index].startTimeTamp) {
              planList.data[index].startTimeTamp = startTime
              planList.data[index].startTime = res.data.data[i].startTime
              planList.data[index].time = `${planList.data[index].startTime}~${planList.data[index].endTime}`
            }
            if (endTime > planList.data[index].endTimeTamp) {
              planList.data[index].endTimeTamp = endTime
              planList.data[index].endTime = res.data.data[i].endTime
              planList.data[index].time = `${planList.data[index].startTime}~${planList.data[index].endTime}`
            }
            planList.data[index].maxOnLine += res.data.data[i].maxNumber
            planList.data[index].ids.push(res.data.data[i].id)
          }
        }
        for (let i = 0; i < planList.data.length; i++) {
          // planDateList.push(new Date(value[i].date).getTime())
          planDateList.push(planList.data[i].dateTamp)
        }
        if (!editFlag.value) {
          JudgeToday()
        }
      })
    }

    function handleUpdatePlanByDay (data) {
      updatePlanByDay(data, res => {
        handleGetPlanListByMonth()
      })
    }

    function getFormatDate (dateStr) {
      const date = new Date(dateStr)
      return `${date.getFullYear()}年${pad(date.getMonth() + 1)}月${pad(date.getDate())}号 ${WEEK[date.getDay()]}`
    }

    function initSetting () {
      setting.onLine.setting = true
      setting.onLine.num = 0
      setting.onLine.time[0] = new Date(date.currentYear, date.currentMonth - 1, date.currentDay, 7, 0)
      setting.onLine.time[1] = new Date(date.currentYear, date.currentMonth - 1, date.currentDay, 11, 0)
      setting.live.setting = true
      setting.live.num = 0
      setting.live.time[0] = new Date(date.currentYear, date.currentMonth - 1, date.currentDay, 7, 0)
      setting.live.time[1] = new Date(date.currentYear, date.currentMonth - 1, date.currentDay, 11, 0)
      editPlanList.data = []
      editIds.data = []
    }
    function JudgeToday () {
      // 判断当天是否已安排
      date.selectDay = new Date().getDate()
      const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth - 1, date.selectDay).getTime())
      if (index !== -1) {
        selectDate.date = `${date.selectYear}年${pad(date.selectMonth)}月${pad(date.selectDay)}号 ${WEEK[new Date().getDay()]}`
        selectDate.onLine.rest = planList.data[index].rest
        if (!planList.data[index].rest) {
          selectDate.onLine.time = planList.data[index].time
          selectDate.onLine.sum = planList.data[index].onLine
          selectDate.onLine.maxSum = planList.data[index].maxOnLine
        }
        selectDate.live.time = `${planList.data[index].startTimeLive}~${planList.data[index].endTimeLive}`
        selectDate.live.sum = planList.data[index].live
        selectDate.live.maxSum = planList.data[index].maxLive
      }
    }

    function handleOnLineRest (value) {
      if (!value) {
        setting.onLine.num = 0
        setting.onLine.time[0] = new Date(date.currentYear, date.currentMonth - 1, date.currentDay, 7, 0)
        setting.onLine.time[1] = new Date(date.currentYear, date.currentMonth - 1, date.currentDay, 7, 0)
      }
    }

    function handleLiveRest (value) {
      if (!value) {
        setting.live.num = 0
        setting.live.time[0] = new Date(date.currentYear, date.currentMonth - 1, date.currentDay, 7, 0)
        setting.live.time[1] = new Date(date.currentYear, date.currentMonth - 1, date.currentDay, 7, 0)
      }
    }

    return {
      closeSetting,
      editFlag,
      editOptions,
      date,
      dateList,
      dayCls,
      handlePrevYear,
      handleNextYear,
      handlePrevMonth,
      handleNextMonth,
      handleSelectDate,
      selectDate,
      handleOpenSetting,
      setting,
      disableMinutesAndSeconds,
      disableHours,
      planList,
      editPlanList,
      editIds,
      getFormatDate,
      planDateList,
      handleOnLineRest,
      handleLiveRest
    }
  }
}
</script>

<style scoped>
.frame-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
  transition: all ease-in .5s;
}
.frame-wrapper__close {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0);
  transition: all ease-in .5s;
}
.frame {
  position: absolute;
  padding: 3%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  max-width: 90%;
  font-size: 15px;
  background: #EDEDED;
  transition: all ease-in .5s;
  box-sizing: border-box;
  overflow: scroll;
}
.frame__close {
  position: absolute;
  padding: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  max-height: 0;
  max-width: 0;
  transition: all ease-in .5s;
  overflow: scroll;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-button {
  color: #FFFFFF;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
}
.header-button__text {
  color: #136FD1;
}
.main {
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
}
.main-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-left-top, .main-left-bottom {
  padding: 0 25px;
  width: 350px;
  box-sizing: border-box;
  background: #FFFFFF;
}
.main-left-bottom {
  margin-top: 21px;
}
.box-item {
  margin: 30px 0;
  text-align: left;
}
.box-item {
  display: flex;
  align-items: center;
}
.box-title-wrapper {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-title {
  font-size: 14px;
  color: #757575;
}
.check-date {
  color: #0A2463;
}
.box-item__label {
  width: 112px;
  line-height: 1.7;
  font-size: 14px;
  color: #0A2463;
}
.box-item__value {
  display: flex;
  margin-left: 10px;
  max-width: 200px;
  align-items: center;
  color: #136FD1;
}
.box-item__value :deep(.el-input__inner) {
  height: 32px;
}
.box-item__value :deep(.el-input-group__append) {
  height: 30px;
  line-height: 32px;
}
.main-right {
  margin-left: 3%;
  display: flex;
  align-items: center;
}
.calendar-picker {
  width: 666px;
  height: 463px;
  padding: 30px;
  font-size: 15px;
  background-color: #FFFFFF;
}
.calendar__header {
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
}
.calendar__header-group {
  display: flex;
  align-items: center;
}
.refresh {
  padding: 5px 10px;
  font-size: 10px;
  border-radius: 2px;
  color: #136FD1;
  border: 1px solid #8491B1;
  letter-spacing: 2px;
}
.calendar__header-group__text {
  padding: 0 25px;
  color: #0A2463;
  font-size: 15px;
}
.calendar__header-group__button {
  width: 9px;
  height: 18px;
  cursor: pointer;
}
.icon-prev {
  background: url("../../../assets/images/icon__prev.png") no-repeat center center;
  background-size: 100%;
}
.icon-next {
  background: url("../../../assets/images/icon__next.png") no-repeat center center;
  background-size: 100%;
}
.calendar-table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}
.calendar-table thead th {
  padding: 12px 0;
  font-weight: 400;
  color: #0A2463;
}
.calendar__body .prev, .calendar__body .current, .calendar__body .next {
  padding: 8px;
  text-align: center;
}
.calendar-table .calendar-day {
  display: inline-block;
  box-sizing: border-box;
  line-height: 25px;
  vertical-align: middle;
  color: #8491B1;
}

.calendar-day .calendar-day__inner {
  height: 45px;
}
.calendar-day .calendar-day__inner-count {
  width: 25px;
  height: 25px;
  margin: 0 auto;
  border-radius: 2px;
  cursor: pointer;
}
.current.is-plan .calendar-day .calendar-day__inner-count {
  color: #136FD1;
}
.current .calendar-day .calendar-day__inner-count:hover {
  background: rgba(19, 111, 209, 0.8);
  color: #FFFFFF;
}
.is-active .calendar-day .calendar-day__inner-count {
  background: #136FD1;
  color: #FFFFFF !important;
}
</style>
