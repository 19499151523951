<template>
  <div class="plan-wrapper">
    <div class="plan">
      <header></header>
      <my-time-picker @changeMonth="handleChangeMonth" :plan="planList.data"></my-time-picker>
    </div>
  </div>
</template>

<script>
import MyTimePicker from '@/components/backend/components/MyTimePicker'
import { getPlanListByDay } from '@/components/backend/assets/api/request'
import { formatDateYYMMDDHasSplit, pad } from '@/components/backend/assets/js/util'
import { reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Plan',
  components: {
    MyTimePicker
  },
  setup () {
    onMounted(() => {
      const data = {
        month: `${new Date().getFullYear()}-${pad(new Date().getMonth() + 1)}`,
        planId: plan.value.id
      }
      handleGetPlanListByDay(data)
    })

    const store = useStore()
    const plan = computed(() => store.state.plan)
    const planList = reactive({ data: [] })

    function handleChangeMonth (value) {
      const data = {
        month: value,
        planId: plan.value.id
      }
      handleGetPlanListByDay(data)
    }

    function handleGetPlanListByDay (data) {
      getPlanListByDay(
        data,
        res => {
          planList.data = []
          for (let i = 0; i < res.data.data.length; i++) {
            planList.data.push({
              date: formatDateYYMMDDHasSplit(res.data.data[i].planDate),
              rest: parseInt(res.data.data[i]) === 0,
              time: `${res.data.data[i].startTime}~${res.data.data[i].endTime}`,
              live: res.data.data[i].sum,
              maxLive: res.data.data[i].maxNumber,
              onLine: res.data.data[i].sumLive,
              maxOnLine: res.data.data[i].maxNumberLive
            })
          }
        }
      )
    }
    return {
      handleChangeMonth,
      planList
    }
  }
}
</script>

<style scoped>
.plan-wrapper {
  width: 100%;
  overflow: hidden;
}
.plan {
  width: calc(100% + 16px);
  height: 100%;
  overflow-y: scroll;
}
</style>
