<template>
  <div class="record">
    <header class="header">
      <h3 class="component__title">关联家庭成员</h3>
      <div class="back" @click="back">
        <i class="icon icon__back"></i>
        返回
      </div>
    </header>
    <main class="main">
      <div class="table-wrapper">
        <div class="table">
          <el-table
            :data="tableData"
            height="100%"
          >
            <el-table-column type="index" label="序号" width="80" align="center" />
            <el-table-column property="uuid" label="用户Id" align="center"/>
            <el-table-column label="家庭关系" align="center">
              <template #default="scope">
                {{relationshipMap[parseInt(scope.row.relationship)]}}
              </template>
            </el-table-column>
            <el-table-column property="username" label="用户姓名" align="center"/>
            <el-table-column property="phone" label="联系方式" width="120" align="center"/>
            <el-table-column label="性别" align="center">
              <template #default="scope">
                {{parseInt(scope.row.sex) === 0 ? '女' : '男'}}
              </template>
            </el-table-column>
            <el-table-column property="nationality" label="民族" align="center"/>
            <el-table-column label="出生年月" align="center">
              <template #default="scope">
                {{formatDateYYMMDDHasSplit(scope.row.birthday)}}
              </template>
            </el-table-column>
            <el-table-column property="nativePlace" label="籍贯" align="center"/>
            <el-table-column property="habitation" label="现居地" align="center"/>
            <el-table-column property="address" label="现居地详址" align="center"/>
            <el-table-column label="添加时间" align="center">
              <template #default="scope">
                {{formatDateYYMMDDHHMMSS(scope.row.createTime)}}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template #default="scope">
                <el-button class="operate-button" @click="showRecord(scope.$index, scope.row)">筛查记录</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { getFamilyList } from '@/components/backend/assets/api/request'
import { formatDateYYMMDDHasSplit, formatDateYYMMDDHHMMSS } from '@/components/backend/assets/js/util'
import { relationshipMap } from '@/components/backend/assets/js/config'

export default {
  name: 'family',
  setup () {
    onMounted(() => {
      if (route.query.userId) {
        userId.value = route.query.userId
        handleGetFamilyList()
      } else {
        ElMessageBox.alert('参数错误！', '提示', {
          confirmButtonText: '确定'
        }).then(() => {
          router.push({
            name: 'userTotal'
          })
        })
      }
    })
    const route = useRoute()
    const router = useRouter()
    const userId = ref('')
    const tableData = reactive([])

    /**
     * 显示筛查记录
     * */
    function showRecord (index, scope) {
      router.push({
        name: 'userRecord',
        query: {
          uuid: scope.uuid,
          relationship: scope.relationship
        }
      })
    }

    /**
     * 点击返回上一页面
     */
    function back () {
      router.go(-1)
    }

    /**
     * 根据用户Id获取用户家庭成员列表
     */
    function handleGetFamilyList () {
      getFamilyList(
        {
          userId: userId.value
        },
        res => {
          tableData.splice(0, tableData.length)
          tableData.push(...res.data.data)
        }
      )
    }

    return {
      back,
      tableData,
      showRecord,
      formatDateYYMMDDHasSplit,
      formatDateYYMMDDHHMMSS,
      relationshipMap
    }
  }
}
</script>

<style scoped>
.record {
  height: calc(100% - 36px);
}
.component__title {
  text-align: left;
  color: rgba(10, 36, 99, 1);
  font-size: 16px;
  font-weight: 600;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  height: 36px;
  position: relative;
}
.back {
  position: absolute;
  top: 50%;
  right: 0;
  height: 34px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.icon__back {
  display: inline-block;
  width: 29px;
  height: 34px;
  margin-right: 8px;
  background: url("../../assets/images/icon__back.png") no-repeat center center;
  background-size: 85%;
}
.main {
  height: calc(100% - 156px);
  width: 100%;
}
.main .table-wrapper {
  height: 100%;
  overflow: hidden;
}
.main .table-wrapper .table {
  height: 100%;
  width: calc(100% + 16px);
}
.main :deep(.el-table th.el-table__cell) {
  font-size: 15px;
  color: #888888;
  background: #F2F3F7;
}
.main :deep(.el-table .el-table__body .cell) {
  /*height: 33px;*/
  line-height: 20px;
  font-size: 14px;
  color: #0A2463;
}
.main .operate-button {
  margin: 0;
  padding: 0 5px;
  height: 28px;
  font-size: 12px;
  background: #318AFF;
  color: #FFFFFF;
}
.record {
  position: relative;
}
</style>
