<template>
  <div class="component-main">
    <div class="record-navs" v-if="!noTabs">
      <div class="record-nav" :class="activeTab === 1 ? 'is-active' : ''" @click="goRoute(1)">已开启计划</div>
      <div class="record-nav" :class="activeTab === 2 ? 'is-active' : ''" @click="goRoute(2)">已结束计划</div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'Record',
  setup () {
    onMounted(() => {
      const childRoute = route.path.split('/')[3]
      if (childRoute === 'open') {
        goRoute(1)
      } else if (childRoute === 'end') {
        goRoute(2)
      }
    })
    const route = useRoute()
    const router = useRouter()
    const activeTab = ref(1)
    const routeMap = {
      1: 'recordOpen',
      2: 'recordEnd'
    }
    const noTabs = computed(() => {
      let result = false
      const childRoute = route.path.split('/')[3]
      if (childRoute && (childRoute === 'recordOperation' || childRoute === 'add')) {
        result = true
      }
      return result
    })
    watch(route, (newRoute) => {
      const childRoute = newRoute.path.split('/')[3]
      if (childRoute === 'open') {
        activeTab.value = 1
      } else if (childRoute === 'end') {
        activeTab.value = 2
      }
    })
    function goRoute (param) {
      activeTab.value = param
      router.push({
        name: routeMap[param]
      })
    }

    return {
      activeTab,
      noTabs,
      goRoute
    }
  }
}
</script>

<style scoped>
.component-main {
  height: 100%;
  background-color: #FBFBFB;
  padding: 16px 60px;
  box-sizing: border-box;
}
.component-main .record-navs {
  display: flex;
}
.component-main .record-navs .record-nav {
  width: 145px;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  border-left: 1px solid #136FD1;
  border-top: 1px solid #136FD1;
  border-bottom: 1px solid #136FD1;
  color: #136FD1;
  font-size: 15px;
  cursor: pointer;
}
.component-main .record-navs .record-nav:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.component-main .record-navs .record-nav:last-child {
  border-right: 1px solid #136FD1;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.component-main .record-navs .record-nav.is-active {
  background-color: #136FD1;
  color: #FFFFFF;
}
</style>
