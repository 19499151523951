<template>
  <div class="apply" v-loading="loading">
    <div class="screens">
      <div
        class="screen"
        :class="screen.orderState === '1' ? 'is-apply' : selectScreen.data && selectScreen.data.uuid === screen.uuid ? 'is-active' : ''"
        v-for="screen in screenList"
        :key="screen.uuid"
        @click="handleChangeRole(screen)"
      >
        <div class="screen__name">
          {{screen.username}}
          <span class="screen__name__self" v-if="screen.relationship === 0">默认本人</span>
        </div>
        <div class="screen-relation">与本人关系：{{screen.relationshipText}}</div>
      </div>
    </div>

    <el-button class="button__default" @click="handleSubmit">下一步</el-button>
    <my-dialog
      :dialog-visible="dialogVisible"
      title="提示"
      primary-btn-text="确认"
      @handlePrimary="handleDialogClose"
    >
      <p class="dialog__context__center">
        {{dialogText}}
      </p>
    </my-dialog>
    <my-dialog
      :dialog-visible="applyDialogVisible"
      title="提示"
      primary-btn-text="确认"
      @handlePrimary="handleBreakSelect"
    >
      <p class="dialog__context__center wider">
        该用户已预约{{selectScreen.data.pointName}}筛查，如需重新预约请前往个人筛查记录取消预约
      </p>
    </my-dialog>
    <my-dialog
      :dialog-visible="questionnaireDialogVisible"
      title="提示"
      :primary-btn-text="questionnaireDialogBtn"
      @handlePrimary="handleSubmitQuestionnaire"
    >
      <div class="dialog-content">
        <div class="section">
          <h4>
            一、预约筛查注意事项
          </h4>
          <p>
            1、筛查对象必须满18岁及以上，除孕产妇外的人群
          </p>
          <p>
            2、一个月内未服用抗生素、PPI等药物
          </p>
          <p>
            3、参与筛查当天必须早上空腹
          </p>
          <p>
            4、吞胶囊过程中有异物感，多喝水即可(300- 500ml )
          </p>
        </div>
        <div class="section">
          <h4>
            二、疫情防控要求
          </h4>
          <p>
            1、对筛查的顾客进行体温检测和核验健康码，体温和健康码正常者方可进入筛查区域
          </p>
          <p>
            2、顾客排队和筛查全程佩戴一次性使用医用口罩
          </p>
          <p>
            3、请严格按预约时间达到筛查地点，全程保持1米安全距离，避免人员聚集
          </p>
          <p>
            4、注意个人卫生，及时进行手卫生，避免用未清洁的手触摸口、眼、鼻，打喷嚏、咳嗽时用纸巾遮住口鼻或采用肘臂遮挡等。
          </p>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import MyDialog from '@/components/MyDialog'
import { getUserState, getPointPlan } from '@/assets/api/request'
import { relationShipMap } from '@/assets/js/config'
import Storage from '@/assets/js/LocalStorage'

export default {
  name: 'Appointment',
  components: {
    MyDialog
  },
  setup () {
    onMounted(() => {
      handleGetUserState(userInfo.userId)
    })
    const storage = new Storage()
    const userInfo = storage.getItem('__userInfo__')
    const loading = ref(false)
    const router = useRouter()
    const dialogVisible = ref(false)
    const applyDialogVisible = ref(false)
    const questionnaireDialogVisible = ref(false)
    const questionnaireDialogCount = ref(0)
    const questionnaireDialogBtn = computed(() => {
      return questionnaireDialogCount.value === 0 ? '确认' : `确认(${questionnaireDialogCount.value}s)`
    })
    const selectScreen = reactive({ data: {} })
    const screenList = reactive([])
    const dialogText = ref('暂未查询到报名信息，请先报名筛查~')

    function handleGetUserState (id) {
      loading.value = true
      getUserState(
        { id },
        res => {
          screenList.splice(0, screenList.length)
          if (res.data.data.user && parseInt(res.data.data.user.signState) === 1) {
            screenList.push({
              uuid: res.data.data.user.uuid,
              username: res.data.data.user.username,
              recordId: res.data.data.user.recordId,
              relationship: 0,
              relationshipText: relationShipMap[0],
              signState: res.data.data.user.signState,
              pointId: res.data.data.user.pointId,
              pointName: res.data.data.user.pointName,
              orderState: res.data.data.user.orderState,
              phone: res.data.data.user.phone
            })
            if (parseInt(screenList[0].orderState) !== 1) {
              selectScreen.data = screenList[0]
              handleGetPointPlan(res.data.data.user.pointId)
            }
          }
          if (res.data.data.family) {
            res.data.data.family.forEach(record => {
              if (parseInt(record.signState) === 1) {
                screenList.push({
                  uuid: record.uuid,
                  username: record.username,
                  recordId: record.recordId,
                  relationship: record.relationship,
                  relationshipText: relationShipMap[record.relationship],
                  signState: record.signState,
                  pointId: record.pointId,
                  pointName: record.pointName,
                  orderState: record.orderState,
                  phone: record.phone
                })
              }
            })
          }
          if (screenList.length === 0) {
            dialogText.value = '暂未查询到报名信息，请先报名筛查~'
            dialogVisible.value = true
          }
        }
      )
      loading.value = false
    }

    function handleGetPointPlan (pointId) {
      getPointPlan(
        { id: pointId },
        res => {
          if (!res.data.data.plan || !res.data.data.planByDays) {
            dialogText.value = '暂未开放预约系统,请耐心等候预约通知。'
            dialogVisible.value = true
          }
        }
      )
    }

    function handleChangeRole (screen) {
      handleGetPointPlan(screen.pointId)
      if (screen.orderState === '1') {
        applyDialogVisible.value = true
      }
      selectScreen.data = screen
    }

    function handleDialogClose () {
      if (screenList.length === 0) {
        router.push({
          path: '/apply'
        })
      } else {
        selectScreen.data = {}
      }
      dialogVisible.value = false
    }

    function handleBreakSelect () {
      applyDialogVisible.value = false
      selectScreen.data = {}
    }

    function handleSubmitQuestionnaire () {
      if (questionnaireDialogCount.value !== 0) {
        ElMessage({
          message: '请认真阅读注意事项!',
          type: 'error',
          duration: 2000,
          center: true
        })
      } else {
        router.push({
          path: '/appointmentDate',
          query: {
            recordId: selectScreen.data.recordId,
            pointId: selectScreen.data.pointId,
            uuid: selectScreen.data.uuid,
            username: selectScreen.data.username
          }
        })
      }
    }

    function handleSubmit () {
      if (selectScreen.data.uuid) {
        questionnaireDialogVisible.value = true
        questionnaireDialogCount.value = 10
        const SIV = setInterval(() => {
          if (questionnaireDialogCount.value !== 0) {
            questionnaireDialogCount.value--
          } else {
            clearInterval(SIV)
          }
        }, 1000)
      } else {
        ElMessage({
          message: '请选择需要预约的筛查人!',
          type: 'error',
          duration: 2000,
          center: true
        })
      }
    }

    return {
      loading,
      screenList,
      selectScreen,
      handleDialogClose,
      dialogVisible,
      handleChangeRole,
      handleSubmit,
      applyDialogVisible,
      handleBreakSelect,
      questionnaireDialogVisible,
      questionnaireDialogBtn,
      handleSubmitQuestionnaire,
      dialogText
    }
  }
}
</script>

<style scoped>
.apply {
  height: 100%;
  padding: 0 16px;
  box-sizing: border-box;
}
.screens {
  height: calc(100% - 146px);
  overflow-y: scroll;
}
.screen {
  margin: 20px 0;
  padding: 20px 24px;
  text-align: left;
  color: #8491B1;
  font-size: 14px;
  background: #FDFEFF;
  box-shadow: 0 1px 4px 0 rgba(10, 36, 99, 0.12), 0 0 8px 0 rgba(10, 36, 99, 0.06);
  border-radius: 8px;
  position: relative;
}
.screen:after {
  content: '';
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #F3F3F3;
  border-radius: 50%;
}
.screen.is-active:after {
  background: url("../../../assets/images/checked.png") no-repeat center center;
  background-size: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
.screen.is-apply:after {
  content: '已预约';
  width: 50px;
  right: 10px;
  color: #318AFF;
  font-size: 15px;
  box-shadow: none;
  border: 0;
}
.screen__name {
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 600;
  color: #0A2463;
}
.screen__name__self {
  margin-left: 10px;
  font-size: 14px;
  color: #8491B1;
}
.button__default {
  position: absolute;
  width: calc(100% - 32px);
  left: 16px;
  bottom: 60px;
}
.dialog__context__center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 60px;
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
  box-sizing: border-box;
  font-size: 15px;
  line-height: 22px;
  color: #0A2463;
}
.dialog__context__center.wider {
  padding: 0 10px;
}
.dialog-content {
  max-height: 300px;
  overflow-y: scroll;
}
.section {
  margin: 20px 0;
}
</style>
