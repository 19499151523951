import * as types from './mutation-types'
import Storage from '@/components/backend/assets/js/LocalStorage'
const storage = new Storage()

export const saveMonth = function ({ commit }, month) {
  commit(types.SET_MONTH, month)
}

export const savePlan = function ({ commit }, plan) {
  commit(types.SET_PLAN, plan)
}

export const saveUser = function ({ commit }, user) {
  console.log('actions: saveUser,user: ', user)
  storage.setItem(
    {
      name: '__user__',
      value: {
        user: user
      },
      expires: 7200000 // 有效时间3个小时
    }
  )
  commit(types.SET_USER, user)
}
