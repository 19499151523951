/**
 * 验证手机号码
 * @param number
 * @returns {boolean}
 */
export function verifyingPhone (number) {
  const regTel = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/
  if (regTel.test(number)) {
    return true
  } else {
    return false
  }
}

export function verifyingSpecialCharacter (str) {
  // eslint-disable-next-line no-useless-escape
  const reg = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、0-9]/
  return !reg.test(str)
}

/**
 * 补零函数
 *
 * */
export function pad (num, n = 2) {
  let len = num.toString().length
  while (len < n) {
    num = '0' + num
    len++
  }
  return num
}

function isVaildDate (date) {
  return date instanceof Date && !isNaN(date.getTime())
}

/**
 * 返回当天日期字符串，无时间，格式为'2021/01/01'
 * @param str
 * @returns {string}
 */
export function formatDateYYMMDDHasSplit (str) {
  const d = new Date(str)
  return isVaildDate(d) ? `${d.getFullYear()}/${pad(d.getMonth() + 1)}/${pad(d.getDate())}` : '——'
}

/**
 * 返回当天日期字符串，无时间，格式为'2021-01-01'
 * @param str
 * @returns {string}
 */
export function formatDateYYMMDDHasSplit2 (str) {
  const d = new Date(str)
  return isVaildDate(d) ? `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}` : '——'
}

/**
 * 返回时间戳对应时间，格式为'2021-01-01 xx:xx:xx'
 * @param str
 * @returns {string}
 */
export function formatDateYYMMDDHHMMSS (str) {
  const d = new Date(str)
  return isVaildDate(d) ? `${d.getFullYear()}/${pad(d.getMonth() + 1)}/${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}` : '——'
}

/**
 * 比较特定时间段是否大于现在时间
 * @returns {Boolean}
 */
export function JudgeTime (dateStr1) {
  return new Date(dateStr1).getTime() >= new Date().getTime()
}

export function judgeAgent () {
  const agent = navigator.userAgent

  const isAndroid = agent.indexOf('Android') !== -1 || agent.indexOf('Linux') !== -1

  const isIOS = !agent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

  return isAndroid ? 1 : isIOS ? 2 : 0
}

// GCJ-02 to BD-09
export function bdEncrypta (ggLon, ggLat) {
  const X_PI = Math.PI * 3000.0 / 180.0
  const x = ggLon
  const y = ggLat
  const z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI)
  const theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI)
  const bdLon = z * Math.cos(theta) + 0.0065
  const bdLat = z * Math.sin(theta) + 0.006
  return [bdLon, bdLat]
}

// BD-09 to GCJ-02
export function bMapTransWXMap (lng, lat) {
  const xPi = 3.14159265358979324 * 3000.0 / 180.0
  const x = lng - 0.0065
  const y = lat - 0.006
  const z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * xPi)
  const theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * xPi)
  const lngs = z * Math.cos(theta)
  const lats = z * Math.sin(theta)
  return [lngs, lats]
}
