import axios from 'axios'
import Storage from '@/assets/js/LocalStorage'
import { ElMessage } from 'element-plus'
import router from '@/router'

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = '/api'
}

const POST = 'POST'
// const GET = 'GET'
const storage = new Storage()

function auth () {
  const userInfo = storage.getItem('__userInfo__')
  // 获取token失败则登录token失效，重新登录
  if (!userInfo || !userInfo.userToken || !userInfo.userId) {
    // ElMessage.error('用户Token获取失败！')
    authorize(router.currentRoute.value.path)
    // router.push('login')
  }
  return {
    userToken: userInfo.userToken,
    userId: userInfo.userId
  }
}

function post (url, data, success, failure, authFlag) {
  let headers = {}
  if (authFlag) {
    headers = auth()
  }
  axios.post(url, data, {
    headers: headers
  })
    .then(res => {
      if (res.data.status === 200) {
        if (success) {
          success(res)
        }
      } else {
        if (url === '/screening/getPointPlan') {
          if (success) {
            success(res)
          }
        } else if (url === '/screening/getUserState' || url === '/user/getFamily') {
        } else {
          ElMessage({
            message: res.data.message,
            type: 'error',
            duration: 2000,
            center: true
          })
          if (failure) {
            failure()
          }
        }
      }
    })
    .catch(err => {
      console.log(err)
      ElMessage({
        message: '请求失败，请联系管理员处理！',
        type: 'error',
        duration: 2000,
        center: true
      })
      if (failure) {
        failure(err)
      }
    })
}

function get (url, data, success, failure, authFlag) {
  let headers = {}
  if (authFlag) {
    headers = auth()
  }

  axios.get(url, {
    params: data,
    headers: headers
  })
    .then(res => {
      if (res.data.status === 200) {
        if (success) {
          success(res)
        }
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error',
          duration: 2000,
          center: true
        })
      }
    })
    .catch(err => {
      console.log(err)
      if (failure) {
        failure(err)
      }
    })
}

function request (url, data, method, success, failure, auth = true) {
  if (method === POST) {
    post(url, data, success, failure, auth)
  } else {
    get(url, data, success, failure, auth)
  }
}

// export function authorize () {
//   storage.removeItem('__userInfo__')
//   storage.setItem(
//     {
//       name: '__userInfo__',
//       value: {
//         userId: 44,
//         userToken: '025a97a561aa76a710c93743ef2d3d12'
//       },
//       expires: 300000 // 有效时间5分钟
//     }
//   )
// }

export function authorize (data, success, failure) {
  const url = `/authorize?returnUrl=${data}`
  // console.log(url)
  axios.get(url)
    .then(res => {
      if (res.data.status === 200) {
        storage.removeItem('__userInfo__')
        window.location.href = res.data.data
      }
    })
    .catch(err => {
      console.log(err)
      if (failure) {
        failure(err)
      }
    })
}

/**
 * 获取登录验证码
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getVerCode (data, success, failure) {
  const url = '/user/sendVercode'
  request(url, data, POST, success, failure, false)
}

export function register (data, success, failure) {
  const url = '/user/register'
  request(url, data, POST, success, failure, false)
}

export function updateUserInfo (data, success, failure) {
  const url = '/user/updateUserInfo'
  request(url, data, POST, success, failure)
}

export function addFamily (data, success, failure) {
  const url = '/user/addFamily'
  request(url, data, POST, success, failure)
}

export function updateFamily (data, success, failure) {
  const url = '/user/updateFamily'
  request(url, data, POST, success, failure)
}

export function getFamily (data, success, failure) {
  const url = '/user/getFamily'
  request(url, data, POST, success, failure)
}

/**
 * 获取筛查人详情
 * @param data 包含两个参数id，relationship: 家人与主用户的关系, 其中id为主用户id时，relationship不传，
 * 传relationship时，默认为查主用户家人信息
 * @param success
 * @param failure
 */
export function getFamilyInfo (data, success, failure) {
  const url = '/user/getFamilyInfo'
  request(url, data, POST, success, failure)
}

/**
 * 删除筛查人
 * @param data 包含两个参数id，relationship: 家人与主用户的关系, 其中id为主用户id时，relationship不传，
 * 传relationship时，默认为查主用户家人信息
 * @param success
 * @param failure
 */
export function deleteFamily (data, success, failure) {
  const url = '/user/deleteFamily'
  request(url, data, POST, success, failure)
}

/**
 * 获取筛查人二维码
 * @param data
 * @returns {Promise<string>}
 */
export function getEncodeByName (data) {
  const headers = auth()
  const url = '/user/getEncodeByName'
  return axios.post(url, data, {
    responseType: 'arraybuffer',
    headers: headers
  })
    .then(res => {
      return 'data:image/png;base64,' + btoa(
        new Uint8Array(res.data)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      )
    })
    .catch(err => {
      console.log(err)
    })
}

/**
 * 获取筛查点列表
 * @param data 包含参数habitation：所在区，如南山区
 * @param success
 * @param failure
 */
export function getScreeningPoint (data, success, failure) {
  const url = '/screening/getScreeningPoint'
  request(url, data, POST, success, failure)
}

/**
 * 获取筛查人状态列表
 * @param data 包含参数id：主用户id
 * @param success
 * @param failure
 */
export function getUserState (data, success, failure) {
  const url = '/screening/getUserState'
  request(url, data, POST, success, failure)
}

/**
 * 筛查人报名
 * @param data
 * @param success
 * @param failure
 */
export function userSignUp (data, success, failure) {
  const url = '/screening/userSignUp'
  request(url, data, POST, success, failure)
}

/**
 * 筛查人预约
 * @param data
 * @param success
 * @param failure
 */
export function userOrder (data, success, failure) {
  const url = '/screening/userOrder'
  request(url, data, POST, success, failure)
}

/**
 * 查询筛查点的筛查计划
 * @param data
 * @param success
 * @param failure
 */
export function getPointPlan (data, success, failure) {
  const url = '/screening/getPointPlan'
  request(url, data, POST, success, failure)
}

/**
 * 获取用户报名预约筛查记录
 * @param data
 * @param success
 * @param failure
 */
export function getUserRecord (data, success, failure) {
  const url = '/screening/getUserRecord'
  request(url, data, POST, success, failure)
}

/**
 * 用户取消预约
 * @param data
 * @param success
 * @param failure
 */
export function userCancel (data, success, failure) {
  const url = '/screening/userCancel'
  request(url, data, POST, success, failure)
}

// let code = ''
//
// export function Authorize () {
//   const url = '/authorize'
//
//   return axios.get(url)
//     .then(res => {
//       console.log(res)
//       if (res.data.status === 200) {
//         code = getUrlCode().code
//         if (code === null || code === '' || code === undefined) {
//           window.location.href = res.data.data
//         } else {
//           console.log(code)
//         }
//       }
//     })
//     .catch(() => {
//       console.log('error')
//     })
// }
//
// function getUrlCode () {
//   const url = location.search
//   const theRequest = {}
//   if (url.indexOf('?') !== -1) {
//     const str = url.substr(1)
//     const strs = str.split('&')
//     for (let i = 0; i < strs.length; i++) {
//       theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1])
//     }
//   }
//   return theRequest
// }
