// import { ElMessageBox } from "element-plus";

export default class Storage {
  constructor () {
    this.name = 'storage'
  }

  setItem (params) {
    const obj = {
      name: '',
      value: '',
      expires: '',
      startTime: new Date().getTime() // 记录值存入的时间戳，毫秒级
    }
    const options = {}
    // obj与传入params合并
    Object.assign(options, obj, params)
    if (options.expires) {
      // console.log('设置了失效')
      // 以options.name为key, options为值写入localStorage
      localStorage.setItem(options.name, JSON.stringify(options))
    } else {
      // console.log('未设置失效')
      const type = Object.prototype.toString.call(options.value)
      if (type === '[object Object]' || type === '[object Array]') {
        options.value = JSON.stringify(options.value)
      }
      localStorage.setItem(options.name, options.value)
    }
  }

  getItem (name) {
    const item = localStorage.getItem(name)
    if (!item) {
      return false
    }
    let result
    try {
      result = JSON.parse(item)
    } catch (e) {
      result = item
    }
    if (result.startTime) {
      // 有startTime说明设置了失效时间
      const date = new Date().getTime()
      if (date - result.startTime > result.expires) {
        localStorage.removeItem(name)
        return false
      } else {
        // 每次获取值后更新写入时间
        result.startTime = date
        localStorage.setItem(result.name, JSON.stringify(result))
        return result.value
      }
    } else {
      // 没有设置失效时间，直接返回值
      return result
    }
  }

  removeItem (name) {
    localStorage.removeItem(name)
  }

  clear () {
    localStorage.clear()
  }
}
