// export const baseImgUrl = '/home'

// 侧边栏菜单
export const allMenu = [
  {
    id: 0, desc: '首页', src: '/backend/summary', name: 'summary', Authority: [0, 1, 2]
  },
  {
    id: 1, desc: '筛查计划管理', src: '/backend/record', name: 'record', Authority: [0, 1, 2]
  },
  {
    id: 2, desc: '用户管理', src: '/backend/user', name: 'user', Authority: [0, 1]
  },
  // {
  //   id: 3, desc: '角色与权限管理', src: '/backend/authority', name: 'authority', Authority: [0]
  // },
  {
    id: 4, desc: '筛查点管理', src: '/backend/point', name: 'point', Authority: [0, 1, 2]
  }
  // {
  //   id: 5, desc: '设备管理', src: '/backend/equip', name: 'equip', Authority: [0]
  // }
]

export const menuMap = {
  summary: '首页',
  record: '筛查计划管理',
  user: '用户管理',
  authority: '角色与权限管理',
  point: '筛查点管理',
  equip: '设备管理'
}

export const userTypeMap = [
  {
    label: '系统管理员',
    desc: '使用后台管理系统所有功能'
  },
  {
    label: '医院管理员',
    desc: '负责筛查执行医护和有关工作人员，使用登记APP，无法使用后台系统'
  },
  {
    label: '社康管理员',
    desc: '负责筛查执行医护和有关工作人员，使用登记APP，无法使用后台系统'
  },
  {
    label: '鸿美工作人员',
    desc: '负责制定筛查计划，可以使用登记APP，可使用后台管理系统筛查计划管理'
  }
]

export const relationshipMap = {
  0: '本人',
  1: '父母',
  2: '子女',
  3: '夫妻',
  4: '兄弟',
  5: '姐妹'
}

// 用户报名详情
export const reportMap = [
  {
    label: '用户ID',
    key: 'uuid'
  },
  {
    label: '用户姓名',
    key: 'username'
  },
  {
    label: '联系方式',
    key: 'phone'
  },
  {
    label: '报名状态',
    key: 'signState',
    transfer: {
      0: '取消报名',
      1: '已报名'
    }
  },
  {
    label: '报名时间',
    key: 'signTime'
  },
  {
    label: '是否现场筛查',
    key: 'isLive',
    transfer: {
      1: '现场',
      2: '线下'
    }
  },
  {
    label: '预约状态',
    key: 'orderState',
    transfer: {
      0: '未预约',
      1: '已预约',
      2: '取消预约'
    }
  },
  {
    label: '预约时间',
    key: 'orderTime'
  },
  {
    label: '预约筛查时间',
    key: 'checkDate'
  },
  {
    label: '筛查状态',
    key: 'checkState',
    transfer: {
      0: '未筛查',
      1: '已筛查',
      2: '已完成',
      3: '已逾期'
    }
  },
  {
    label: '筛查结果',
    key: 'result',
    transfer: {
      0: '阳性',
      1: '阴性'
    }
  },
  {
    label: '绑定试管时间',
    key: 'bindingTime'
  },
  {
    label: '绑定试管ID',
    key: 'bindingId'
  }
]

export const planMap = [
  {
    label: '筛查点',
    key: 'pointName'
  },
  {
    label: '所属地',
    key: 'habitation'
  },
  {
    label: '报名方式',
    key: 'enrollWay',
    transfer: {
      1: '仅线上报名',
      2: '支持线上及现场报名'
    }
  },
  {
    label: '计划ID',
    key: 'id'
  },
  {
    label: '计划名称',
    key: 'planName'
  },
  {
    label: '计划开启状态',
    key: 'state',
    transfer: {
      0: '结束',
      1: '开启'
    }
  },
  {
    label: '计划开启日期',
    key: 'startDay'
  },
  {
    label: '制定人员',
    key: 'creator'
  }
]
