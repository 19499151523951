<template>
  <div class="screen-search" v-loading="loading">
    <el-input
      class="search-input"
      v-model="searchText"
      placeholder="请输入地址或筛查点名称"
    >
      <template #prefix>
        <el-icon class="el-input__icon"><search /></el-icon>
      </template>
    </el-input>
    <div class="section">
      <h3 class="section-title">区域搜索</h3>
      <div class="section-districts">
        <div class="section-district-wrapper" v-for="district in districts" :key="district.value">
          <div class="district" @click="handleDistrictChange(district)">
            <div class="district__inner" :class="selectedDistrict === district.value ? 'active' : ''">
              {{district.label}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <h3 class="section-title">附近站点</h3>
      <div class="nearby-list">
        <div class="nearby" v-for="nearby in nearbyList.data" :key="nearby.id" @click="handleVisDrawer(nearby)">
          <div class="nearby__inner">
            <img class="nearby__inner-img" :src="nearby.img" @error="showDefaultImg(nearby)" alt="站点图片">
            <div class="nearby__inner-right">
              <div class="nearby__inner-right-top">{{nearby.name}}</div>
              <div class="nearby__inner-right-bottom">{{nearby.addr}}</div>
            </div>
          </div>
        </div>
        <div v-if="nearbyList.data.length === 0" style="text-align: center;margin: 20px 0">暂无站点~</div>
      </div>
        <el-drawer
          v-model="drawerVisible"
          title="请选择要打开的地图app"
          direction="btt"
        >
          <div class="drawer-li" @click="handleOpenMap(1)">百度地图</div>
          <div class="drawer-li" @click="handleOpenMap(2)">腾讯地图</div>
          <div class="drawer-li" @click="handleOpenMap(3)">高德地图</div>
        </el-drawer>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { Search } from '@element-plus/icons'
import { getScreeningPoint } from '@/assets/api/request'
import { judgeAgent, bMapTransWXMap } from '@/assets/js/util'

export default {
  name: 'ScreenSearch',
  components: {
    Search
  },
  setup () {
    onMounted(() => {
      handleGetScreeningPoint('')
    })
    const loading = ref(false)
    const searchText = ref('')
    const selectedDistrict = ref('')
    const districts = reactive([
      {
        label: '全部',
        code: 440301,
        value: ''
      },
      {
        label: '罗湖区',
        code: 440303,
        value: '罗湖区'
      },
      {
        label: '福田区',
        code: 440304,
        value: '福田区'
      },
      {
        label: '南山区',
        code: 440305,
        value: '南山区'
      },
      {
        label: '宝安区',
        code: 440306,
        value: '宝安区'
      },
      {
        label: '龙岗区',
        code: 440307,
        value: '龙岗区'
      },
      {
        label: '盐田区',
        code: 440308,
        value: '盐田区'
      },
      {
        label: '龙华区',
        code: 440309,
        value: '龙华区'
      },
      {
        label: '坪山区',
        code: 440310,
        value: '坪山区'
      },
      {
        label: '光明区',
        code: 440311,
        value: '光明区'
      },
      {
        label: '大鹏新区',
        code: 440312,
        value: '大鹏新区'
      }
    ])
    const nearbyList = reactive({
      data: [
        {
          id: 1,
          img: '',
          name: '官田社区健康服务中心',
          addr: '深圳市宝安区宝石东路273号',
          location: {
            lat: 22.685561,
            lng: 113.951285
          },
          drawerVisible: false
        },
        {
          id: 2,
          img: '',
          name: '沙头社区健康服务中心',
          addr: '深圳市宝安区沙井街道沙头居委沙头二路26号',
          location: {
            lat: 22.73517,
            lng: 113.81179
          },
          drawerVisible: false
        },
        {
          id: 3,
          img: '',
          name: '水田社区健康服务中心',
          addr: '宝安区石岩街道石龙大道水田石龙仔路口',
          location: {
            lat: 22.691265,
            lng: 113.970335
          },
          drawerVisible: false
        }
      ]
    })
    const selectedNearby = reactive({ data: {} })
    const drawerVisible = ref(false)

    function handleGetScreeningPoint (habitation) {
      loading.value = true
      getScreeningPoint(
        {
          habitation
        },
        res => {
          nearbyList.data = []
          res.data.data.screeningPoints.forEach(point => {
            nearbyList.data.push({
              id: point.id,
              img: point.imgSrc || '',
              name: point.name,
              addr: point.address,
              location: {
                lat: point.latitude,
                lng: point.longitude
              }
            })
          })
        }
      )
      loading.value = false
    }

    function handleDistrictChange (district) {
      selectedDistrict.value = district.value
      handleGetScreeningPoint(district.value)
    }
    function handleVisDrawer (nearby) {
      drawerVisible.value = true
      selectedNearby.data = nearby
    }
    function handleOpenMap (map) {
      // map: 1 百度地图, 2 腾讯地图, 3高德地图
      // nearby.drawerVisible = true
      let scheme = ''
      let schemeWeb = ''

      if (map === 1) {
        const queryStr = `?location=${selectedNearby.data.location.lat},${selectedNearby.data.location.lng}&title=${selectedNearby.data.name}&content=${selectedNearby.data.addr}&coord_type=bd09ll`

        schemeWeb = `http://api.map.baidu.com/marker${queryStr}&output=html`

        // 判断用户终端类型
        const agent = judgeAgent()

        console.log('agent:', agent)

        if (agent === 1) {
          // Android
          scheme = `bdapp://map/marker${queryStr}`
        } else if (agent === 2) {
          // IOS
          scheme = `baiduapp://map/marker${queryStr}`
        } else {
          // pc
          window.location.href = schemeWeb
          return
        }
      } else if (map === 2) {
        const agent = judgeAgent()
        const position = bMapTransWXMap(selectedNearby.data.location.lng, selectedNearby.data.location.lat)
        const queryStr = `?marker=coord:${position[1]},${position[0]};title:${selectedNearby.data.name};addr:${selectedNearby.data.addr}`
        // 腾讯地图IOS与Android协议一致
        scheme = `qqmap://map/marker${queryStr}`
        schemeWeb = `https://apis.map.qq.com/uri/v1/marker${queryStr}`
        if (agent === 3) {
          window.location.href = schemeWeb
          return
        }
      } else if (map === 3) {
        const position = bMapTransWXMap(selectedNearby.data.location.lng, selectedNearby.data.location.lat)
        const queryStr = `?position=${position[0]},${position[1]}&name=${selectedNearby.data.name}&callnative=1`
        window.location.href = `https://uri.amap.com/marker${queryStr}`
        return
      }

      window.location.href = scheme

      // 判断app是否打开
      const startTime = Date.now()
      let count = 0
      let endTime = 0
      const itv = setInterval(() => {
        count += 1
        endTime = Date.now() - startTime
        // 如果3秒后还未打开app，清楚定时器，打开浏览器地图
        if (endTime > 3000) {
          clearInterval(itv)
        }
        if (count < 150) return
        // 判断当前页面是否进入后台
        if (!(document.hidden || document.webkitHidden)) {
          window.location.href = schemeWeb
        }
      }, 20)
      window.onblur = () => {
        clearInterval(itv)
      }
    }
    function showDefaultImg (nearby) {
      nearby.img = require('../../assets/images/default.png')
    }
    return {
      loading,
      searchText,
      districts,
      nearbyList,
      selectedDistrict,
      showDefaultImg,
      handleDistrictChange,
      handleOpenMap,
      handleVisDrawer,
      drawerVisible
    }
  }
}
</script>

<style scoped>
.screen-search {
  min-height: 100%;
  box-sizing: border-box;
  padding: 16px;
  font-size: 15px;
  background-color: #F8FAFF;
}
.search-input {
  margin: 20px auto;
}
:deep(.el-input) {
  width: 311px;
  height: 48px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08), 0 0 1px 0 rgba(0, 0, 0, 0.08);
  border-radius: 30px;
}
:deep(.el-input__inner) {
  height: 100%;
  border-radius: 30px;
  padding-left: 56px;
}
/** 搜索框图标定位 */
:deep(.el-input__prefix) {
  left: 19px;
}
/** 搜索框图标样式 */
:deep(.el-input__icon) {
  color: #0A2463;
  font-size: 18px;
}
.section-title {
  margin-bottom: 8px;
  height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: #0A2463;
  line-height: 25px;
  text-align: left;
}
.section-districts {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.section-district-wrapper {
  width: 33%;
}
.district {
  display: flex;
  margin: 8px 0;
  align-items: center;
  justify-content: center;
}
.district .active {
  color: #FFFFFF;
  background-color: #8491B1;
}
.district:nth-child(3n+1) {
  justify-content: start;
}
.district:nth-child(3n) {
  justify-content: end;
}
.district__inner {
  width: 90px;
  height: 34px;
  line-height: 34px;
  color: #136FD1;
  border-radius: 30px;
  background-color: #FFFFFF;
}
.nearby {
  margin: 20px 0;
  background-color: #FFFFFF;
}
.nearby__inner {
  display: flex;
}
.nearby__inner-img {
  margin: 12px 20px;
  width: 72px;
  height: 72px;
  border-radius: 4px;
}
.nearby__inner-right {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding-right: 17px;
  text-align: left;
}
.nearby__inner-right-bottom {
  padding-left: 20px;
  font-size: 14px;
  color: #8491B1;
  position: relative;
}
.nearby__inner-right-bottom:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  background: url("../../assets/images/icon__addr.png") no-repeat center center;
  background-size: 100%;
}
.drawer-li {
  height: 48px;
  line-height: 48px;
  width: 100%;
  font-size: 15px;
  color: #136FD1;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.screen-search :deep(#el-drawer__title) {
  padding: 12px;
  margin: 0;
  background-color: #F8FAFF;
}
.screen-search :deep(.el-drawer__body) {
  border-top: 1px solid #cccccc;
  padding: 0;
  background-color: #F8FAFF;
}
</style>
