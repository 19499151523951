<template>
  <router-view/>
</template>

<!--<script>-->
<!--import { onMounted, ref } from 'vue'-->

<!--export default {-->
<!--  setup () {-->
<!--    onMounted(() => {-->
<!--      getCode()-->
<!--    })-->

<!--    const code = ref('')-->
<!--    const winUrl = ref('')-->

<!--    function getCode () {-->
<!--      code.value = ''-->
<!--      const local = window.location.href-->
<!--      const appid = 'wxb019053c0c0bd056'-->
<!--      console.log(local)-->
<!--      console.log(encodeURIComponent(local))-->
<!--      code.value = getUrlCode().code-->
<!--      if (code.value === null || code.value === '' || code.value === undefined) {-->
<!--        alert(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base#wechat_redirect`)-->
<!--        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base#wechat_redirect`-->
<!--      } else {-->
<!--        console.log(code)-->
<!--      }-->
<!--    }-->

<!--    function getUrlCode () {-->
<!--      const url = location.search-->
<!--      winUrl.value = url-->
<!--      const theRequest = {}-->
<!--      if (url.indexOf('?') !== -1) {-->
<!--        const str = url.substr(1)-->
<!--        const strs = str.split('&')-->
<!--        for (let i = 0; i < strs.length; i++) {-->
<!--          theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1])-->
<!--        }-->
<!--      }-->
<!--      return theRequest-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  max-width: 100%;
  background-color: #F8FAFF;
}
</style>
