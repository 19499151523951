<template>
  <div class="my-dialog">
    <el-dialog
      v-model="visible"
      :before-close="handleCancelByOutsize"
      :show-close="false"
    >
      <span>{{content}}</span>
      <slot></slot>
      <template #title>
        <div class="dialog-header">
          <span>{{title}}</span>
        </div>
      </template>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            v-if="cancelBtnText"
            class="dialog__footer-btn"
            @click="handleCancel"
          >{{cancelBtnText}}</el-button>
          <el-button
            v-if="primaryBtnText"
            class="dialog__footer-btn dialog__footer-btn_primary"
            @click="handlePrimary"
          >{{primaryBtnText}}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, watch } from 'vue'

export default {
  name: 'MyDialog',
  emits: ['handlePrimary', 'handleCancel', 'handleCancelByOutsize'],
  props: {
    dialogVisible: Boolean,
    content: String,
    title: String,
    primaryBtnText: String,
    cancelBtnText: String
  },
  setup (props, context) {
    const visible = ref(false)
    watch(() => props.dialogVisible, (val) => {
      // console.log(val)
      visible.value = val
    })
    function handlePrimary () {
      context.emit('handlePrimary')
    }
    function handleCancel () {
      context.emit('handleCancel')
    }
    function handleCancelByOutsize () {
      context.emit('handleCancelByOutsize')
    }
    return {
      visible,
      handlePrimary,
      handleCancel,
      handleCancelByOutsize
    }
  }
}
</script>

<style scoped>
.my-dialog :deep(.el-dialog) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 80px);
  padding: 0;
  margin: 0;
  /*margin-left: 40px;*/
  /*margin-right: 40px;*/
  border-radius: 6px;
}
.my-dialog :deep(.el-dialog__header) {
  padding: 16px 0;
  font-weight: 700;
  text-align: center;
}
.my-dialog :deep(.el-dialog__body) {
  padding: 0 16px;
  text-align: justify;
  text-align-last: left;
  color: #0A2463;
  line-height: 20px;
  min-height: 100px;
  position: relative;
}
.my-dialog :deep(.el-dialog__footer) {
  padding: 0;
  margin-top: 13px;
}
.my-dialog :deep(.el-button+.el-button) {
  margin-left: 0;
}
.dialog-footer {
  display: flex;
  /*border-radius: 0 0 6px 6px;*/
}
.dialog__footer-btn {
  flex-basis: 50%;
  height: 50px;
  flex-grow: 1;
  color: #136FD1;
  border: 0;
  border-radius: 0 0 6px 6px;
  /*border-top-left-radius: 0;*/
  /*border-top-right-radius: 0;*/
  /*border-bottom-left-radius: 4px;*/
  /*border-bottom-right-radius: 4px;*/
  border-top: 1px solid #F1F1F1;
}
.dialog__footer-btn + .dialog__footer-btn {
  border-bottom-left-radius: 0;
}
.dialog-header span {
  color: #0A2463;
  font-size: 15px;
  font-weight: 600;
}
.dialog__footer-btn_primary {
  color: #FFFFFF;
  background-color: #136FD1;
}
.my-dialog {
  font-size: 15px;
}
</style>
