<template>
  <div class="login" v-loading="loading">
    <el-form
      class="login-box"
      ref="loginRef"
      :model="loginForm"
      :rules="loginRule"
    >
      <el-form-item prop="mobile">
        <el-input
          v-model="loginForm.mobile"
          @keydown.enter="checkLoginForm"
          placeholder="请输入手机号"
          clearable
        >
          <template #prepend>手机号</template>
          <template #append>
            <el-button size="mini" @click="handleGetVerCode">{{verificationText}}</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="verCode">
        <el-input
          v-model="loginForm.verCode"
          @keydown.enter="checkLoginForm"
          placeholder="请输入验证码"
          maxlength="4"
        >
          <template #prepend>验证码</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="login-btn" @click="checkLoginForm">确认</el-button>
      </el-form-item>
    </el-form>
    <div class="login-tip">
      <i class="icon__agree active"></i>同意<span class="agreement"  @click="dealVisible = true">《用户信息与隐私协议》</span>
    </div>
    <my-dialog
      :dialog-visible="dialogVisible"
      title="用户信息与隐私协议提示"
      primary-btn-text="同意"
      cancel-btn-text="不同意"
      @handlePrimary="dialogVisible = false"
      @handleCancel="handleRefuse"
      @handleCancelByOutsize="handleRefuse"
    >
      <p>
        请您在注册幽门螺杆菌筛查项目前点击 <span class="dialog-agreement" @click="dealVisible = true">《用户信息与隐私协议》</span>并仔细阅读。 如您同意 <span class="dialog-agreement" @click="dealVisible = true">《用户信息与隐私协议》</span>的全部内容，请点击“同意”开始使用我们的服务。
      </p>
    </my-dialog>
    <deal-dialog :dialog-visible="dealVisible" @handle-primary="dealVisible = false" @handleCancel="dealVisible = false"></deal-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { verifyingPhone } from '@/assets/js/util'
import { ElMessage } from 'element-plus'
import { getVerCode, register, authorize } from '@/assets/api/request'
import Storage from '@/assets/js/LocalStorage'
import MyDialog from '@/components/MyDialog'
import DealDialog from '@/components/DealDialog'

export default {
  name: 'Login',
  components: {
    MyDialog,
    DealDialog
  },
  setup () {
    onMounted(() => {
      dialogVisible.value = true
      // console.log(route.path)
      // storage.removeItem('__userInfo__')
      if (route.query.openId) {
        openId.value = route.query.openId
      } else {
        authorize('/login')
      }
    })
    const loginRef = ref(null)
    const loading = ref(false)
    const openId = ref('')
    const storage = new Storage()
    const route = useRoute()
    const router = useRouter()
    const loginForm = reactive({
      mobile: '',
      verCode: ''
    })
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入联系电话！'))
      } else if (!verifyingPhone(value)) {
        callback(new Error('请输入正确的联系电话！'))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入验证码！'))
      } else if (value.length !== 4) {
        callback(new Error('验证码为4位数字！'))
      } else {
        callback()
      }
    }
    const loginRule = reactive({
      mobile: [{
        required: true,
        trigger: 'blur',
        validator: validatePhone
      }],
      verCode: [{
        required: true,
        trigger: 'blur',
        validator: validateCode
      }]
    })
    const dialogVisible = ref(false)
    const dealVisible = ref(false)

    const getting = ref(false)
    const verificationText = ref('获取验证码')

    function startTimeout () {
      let time = 60
      getting.value = true
      verificationText.value = ('剩余' + time + 's')
      const countDown = setInterval(() => {
        time--
        verificationText.value = ('剩余' + time + 's')
        if (time <= 0) {
          verificationText.value = '重新获取验证码'
          getting.value = false
          clearInterval(countDown)
        }
      }, 1000)
    }

    function handleGetVerCode () {
      if (getting.value) {
        ElMessage({
          message: '请勿频繁请求验证码',
          type: 'error',
          duration: 2000,
          center: true
        })
        return
      }
      loading.value = true
      if (verifyingPhone(loginForm.mobile)) {
        /**
         * 获取验证码
         * */
        getVerCode({ phone: loginForm.mobile }, res => {
          // console.log(res)
          if (res.data.status === 200) {
            ElMessage({
              message: '验证码发送成功',
              type: 'success',
              duration: 2000,
              center: true
            })
          } else {
            ElMessage({
              message: res.data.message,
              type: 'success',
              duration: 2000,
              center: true
            })
          }
          startTimeout()
        })
        // startTimeout()
        loading.value = false
      } else {
        loading.value = false
        ElMessage({
          message: '请输入正确的手机号',
          type: 'error',
          duration: 2000,
          center: true
        })
      }
    }

    function checkLoginForm () {
      loginRef.value.validate((valid) => {
        if (valid) {
          login()
        } else {
          ElMessage({
            message: '请完整输入手机号/验证码',
            type: 'error',
            duration: 2000,
            center: true
          })
          return false
        }
      })
    }

    function login () {
      // 登录函数api
      loading.value = true
      register(
        {
          phone: loginForm.mobile,
          verifyCode: loginForm.verCode,
          openId: openId.value
        },
        res => {
          // console.log(res)
          storage.setItem(
            {
              name: '__userInfo__',
              value: {
                userId: res.data.data.userId,
                userToken: res.data.data.userToken
              },
              expires: 300000 // 有效时间5分钟
            }
          )
          router.push({
            path: '/screenInfo',
            query: {
              role: 0,
              phone: loginForm.mobile,
              first: 1
            }
          })
          loading.value = false
        },
        () => {
          loading.value = false
        }
      )
    }

    /**
     * 用户拒绝注册
     */
    function handleRefuse () {
      ElMessage({
        message: '很抱歉，后续内容需要同意协议才能使用，如不同意可关闭当前页面',
        type: 'error',
        duration: 2000,
        center: true
      })
    }

    return {
      loading,
      loginRef,
      loginForm,
      loginRule,
      handleGetVerCode,
      verificationText,
      checkLoginForm,
      login,
      dialogVisible,
      dealVisible,
      handleRefuse
    }
  }
}
</script>

<style scoped>
.login {
  padding-top: 20px;
  box-sizing: border-box;
  min-height: 100%;
  background-color: #F8FAFF;
}

.login-box :deep(.el-input-group__prepend), .login-box :deep(.el-input-group__append) {
  font-weight: 600;
  font-size: 15px;
  color: #0A2463;
  background-color: transparent;
  border: 0;
}

.login-box :deep(.el-button) {
  /*width: 90px;*/
  height: 30px;
  padding: 0 10px;
  color: #318AFF;
  border: 1px solid #318AFF;
  /*background-color: #318AFF;*/
  box-sizing: border-box;
  border-radius: 1000px;
}

.login-box :deep(.el-button:hover) {
  height: 30px;
  padding: 0 10px;
  color: #FFF;
  background-color: #318AFF;
  box-sizing: border-box;
  border-radius: 1000px;
}

.login-box :deep(.el-form-item) {
  height: 64px;
  box-sizing: border-box;
  padding-right: 10px;
  border-bottom: 1px solid rgba(10, 36, 99, .1);
}

.login-box :deep(.el-form-item:last-child) {
  height: 0;
  border: 0;
}

.login-box :deep(.el-input__inner) {
  color: #318AFF;
  border: 0;
  background-color: transparent;
}

.login-box :deep(.el-form-item__error) {
  padding-top: 8px;
  padding-left: 16px;
}

.login .login-box .login-btn {
  position: fixed;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 34px);
  height: 56px;
  /*font-size: 16px;*/
  color: #FFFFFF;
  border-radius: 56px;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
}
.login-tip {
  display: flex;
  align-items: center;
  padding-left: 16px;
  text-align: left;
  color: #849181;
  font-size: 14px;
}
.icon__agree {
  display: inline-block;
  margin-right: 3px;
  width: 22px;
  height: 22px;
  background: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #F3F3F3;
  border-radius: 50%;
  position: relative;
}
.active:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #5AFF25;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
.dialog-agreement {
  color: #318AFF;
}
</style>
