<template>
  <div class="record">
    <h3 class="component__title">用户列表</h3>
    <header class="header">
      <div class="header-search">
        <el-input
          v-model="searchText"
          clearable
          placeholder="用户ID/手机号"
          @change="handleSearchChange"
        >
          <template #suffix>
            <i class="icon icon-search"></i>
          </template>
        </el-input>
      </div>
    </header>
    <main class="main">
      <div class="table-wrapper">
        <div class="table">
          <el-table
            :data="tableData"
            height="100%"
          >
            <el-table-column type="index" label="序号" width="80" align="center" />
            <el-table-column property="uuid" label="用户Id" align="center"/>
            <el-table-column property="username" label="用户姓名" align="center"/>
            <el-table-column property="phone" label="联系方式" width="120" align="center"/>
            <el-table-column label="性别" align="center">
              <template #default="scope">
                {{parseInt(scope.row.sex) === 0 ? '女' : '男'}}
              </template>
            </el-table-column>
            <el-table-column property="nationality" label="民族" align="center"/>
            <el-table-column label="出生年月" align="center">
              <template #default="scope">
                {{formatDateYYMMDDHasSplit(scope.row.birthday)}}
              </template>
            </el-table-column>
            <el-table-column property="nativePlace" label="籍贯" align="center"/>
            <el-table-column property="habitation" label="现居地" align="center"/>
            <el-table-column property="address" label="现居地详址" align="center"/>
            <el-table-column property="sum" label="家庭成员数（人）" align="center"/>
            <el-table-column label="添加时间" align="center">
              <template #default="scope">
                {{formatDateYYMMDDHHMMSS(scope.row.createTime)}}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template #default="scope">
                <el-button class="operate-button" @click="showDetail(scope.$index, scope.row)">成员信息</el-button>
                <el-button class="operate-button" @click="showRecord(scope.$index, scope.row)">筛查记录</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </main>
    <div class="pagination">
      <span>显示第{{(pagination.currentPage - 1) * pagination.pageSize + 1}}到{{pagination.currentPage * pagination.pageSize}}条记录，总共{{pagination.total}}条记录，每页显示{{pagination.pageSize}}条记录</span>
      <el-pagination
        v-model:currentPage="pagination.currentPage"
        :page-size="pagination.pageSize"
        background
        layout="prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { getUserList } from '@/components/backend/assets/api/request'
import { formatDateYYMMDDHasSplit, formatDateYYMMDDHHMMSS } from '@/components/backend/assets/js/util'

const PAGESIZE = 8

export default {
  name: 'Total',
  setup () {
    onMounted(() => {
      handleGetUserList()
    })
    const router = useRouter()
    const tableData = reactive([])
    const searchText = ref('')
    const searchDate = reactive([])
    const pagination = reactive({
      currentPage: 1,
      pageSize: PAGESIZE,
      total: 100
    })

    /**
     * 显示家人列表
     * */
    function showDetail (index, scope) {
      router.push({
        name: 'userFamily',
        query: {
          userId: scope.id
        }
      })
    }

    /**
     * 显示筛查记录
     * */
    function showRecord (index, scope) {
      router.push({
        name: 'userRecord',
        query: {
          userId: scope.id
        }
      })
    }

    /**
     * 搜索时触发
     * */
    function handleSearchChange () {
      handleGetUserList()
    }

    /**
     * 用户表格翻页时触发
     * */
    function handleCurrentChange (page) {
      pagination.currentPage = page
      handleGetUserList()
    }

    /**
     * 获取用户列表
     */
    function handleGetUserList () {
      const data = {
        searchValue: searchText.value,
        pageNum: pagination.currentPage,
        pageSize: pagination.pageSize
      }
      getUserList(data, res => {
        pagination.total = parseInt(res.data.data.total)
        tableData.splice(0, tableData.length)
        tableData.push(...res.data.data.list)
      })
    }

    return {
      tableData,
      showDetail,
      showRecord,
      searchText,
      searchDate,
      handleSearchChange,
      pagination,
      handleCurrentChange,
      formatDateYYMMDDHasSplit,
      formatDateYYMMDDHHMMSS
    }
  }
}
</script>

<style scoped>
.record {
  height: calc(100% - 36px);
}
.component__title {
  margin-bottom: 20px;
  text-align: left;
  color: rgba(10, 36, 99, 1);
  font-size: 16px;
  font-weight: 600;
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  height: 36px;
}
.header .header-search {
  height: 36px;
  width: 225px;
}
.header :deep(.el-input) {
  height: 100%;
}
.header :deep(.el-input__inner) {
  height: 100%;
}
.header .icon-search {
  display: inline-block;
  width: 19px;
  height: 100%;
  vertical-align: top;
  background: url("../../assets/images/icon__search.png") no-repeat center;
  background-size: 100%;
}
.main {
  height: calc(100% - 156px);
  width: 100%;
}
.main .table-wrapper {
  height: 100%;
  overflow: hidden;
}
.main .table-wrapper .table {
  height: 100%;
  width: calc(100% + 16px);
}
.main :deep(.el-table th.el-table__cell) {
  font-size: 15px;
  color: #888888;
  background: #F2F3F7;
}
.main :deep(.el-table .el-table__body .cell) {
  line-height: 20px;
  font-size: 14px;
  color: #0A2463;
}
.main .operate-button {
  margin: 0;
  padding: 0 5px;
  height: 28px;
  font-size: 12px;
  background: #318AFF;
  color: #FFFFFF;
}
.pagination {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #7D7D7D;
}
.record {
  position: relative;
}
</style>
