<template>
  <div>
    <header class="header">
      <span class="header-block">计划ID：{{plan.id}}</span>
      <span class="header-block">计划名称：{{plan.planName}}</span>
      <span class="header-block">制定人员：{{plan.creator}}</span>
      <span class="header-block">计划状态：{{plan.state === 0 ? '结束' : '开启'}}</span>
      <span class="header-block">计划开启日期：{{formatDateYYMMDDHasSplit(plan.startDay)}}</span>
      <span class="header-block" v-if="plan.endDate">计划截止日期：{{formatDateYYMMDDHasSplit(plan.endDate)}}</span>
      <span class="header-block">共报名人数：{{plan.sum}}人</span>
    </header>
    <div class="navs">
      <div class="nav" :class="activeTab === 1 ? 'is-active' : ''" @click="goRoute(1)">预约计划</div>
      <div class="nav" :class="activeTab === 2 ? 'is-active' : ''" @click="goRoute(2)">报名用户详情</div>
    </div>
    <router-view class="main"></router-view>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ElMessageBox } from 'element-plus'
import { formatDateYYMMDDHasSplit } from '@/components/backend/assets/js/util'

export default {
  name: 'RecordView',
  setup () {
    onMounted(() => {
      const childRoute = route.path.split('/')[5]
      if (!childRoute) {
        goRoute(1)
        if (!plan.value.id) {
          ElMessageBox.alert('筛查点Id不能为空！', '提示', {
            confirmButtonText: '确定'
          }).then(() => {
            router.push({
              name: 'recordOpen'
            })
          })
        }
      } else {
        if (!plan.value.id) {
          ElMessageBox.alert('筛查点Id不能为空！', '提示', {
            confirmButtonText: '确定'
          }).then(() => {
            router.push({
              name: 'recordOpen'
            })
          })
        }
        if (childRoute === 'plan') {
          activeTab.value = 1
        } else {
          activeTab.value = 2
        }
      }
    })
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const plan = computed(() => store.state.plan)
    const activeTab = ref(1)
    const routeMap = {
      1: 'RecordOperationViewPlan',
      2: 'RecordOperationViewUser'
    }

    function goRoute (param) {
      activeTab.value = param
      router.push({
        name: routeMap[param]
      })
    }

    return {
      plan,
      activeTab,
      goRoute,
      formatDateYYMMDDHasSplit
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  height: 62px;
  align-items: center;
  justify-content: space-between;
}
.navs {
  display: flex;
}
.nav {
  width: 145px;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  border-left: 1px solid #136FD1;
  border-top: 1px solid #136FD1;
  border-bottom: 1px solid #136FD1;
  color: #136FD1;
  font-size: 15px;
  cursor: pointer;
}
.nav:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.nav:last-child {
  border-right: 1px solid #136FD1;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.nav.is-active {
  background-color: #136FD1;
  color: #FFFFFF;
}
.main {
  height: calc(100% - 98px);
}
</style>
