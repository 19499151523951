<template>
  <el-cascader
    :options="areaOptions"
    v-model="value.data"
    @change="handleChangeArea"
  ></el-cascader>
</template>

<script>
import { reactive, watch, onMounted } from 'vue'
import { areaOptions } from '@/components/backend/assets/js/ChinaAreaData'
export default {
  name: 'ChinaAreaData',
  emits: ['update:modelValue', 'change'],
  props: {
    modelValue: Array
  },
  setup (props, context) {
    onMounted(() => {
      value.data = props.modelValue
    })

    const value = reactive({ data: [] })

    watch(() => props.modelValue, value1 => {
      value.data = value1
    })
    watch(value, value1 => {
      context.emit('update:modelValue', value1.data)
    })

    function handleChangeArea (area) {
      context.emit('update:modelValue', area)
      context.emit('change', area)
    }

    return {
      areaOptions,
      value,
      handleChangeArea
    }
  }
}
</script>

<style scoped>

</style>
