<template>
  <div class="apply" v-loading="loading">
    <div class="screens">
      <div
        class="screen"
        :class="screen.signState === '1' ? 'is-apply' : selectScreen.data && selectScreen.data.uuid === screen.uuid ? 'is-active' : ''"
        v-for="screen in screenList"
        :key="screen.uuid"
        @click="handleChangeRole(screen)"
      >
        <div class="screen__name">
          {{screen.username}}
          <span class="screen__name__self" v-if="screen.relationship === 0">默认本人</span>
        </div>
        <div class="screen-relation">与本人关系：{{screen.relationshipText}}</div>
      </div>
    </div>

    <el-button class="button__default" @click="handleSubmit">下一步</el-button>
    <my-dialog
      :dialog-visible="dialogVisible"
      title="提示"
      primary-btn-text="确认"
      @handlePrimary="handleDialogClose"
    >
      <p class="dialog__context__center">
        暂未查询到筛查人信息，请先录入筛查人信息~
      </p>
    </my-dialog>
    <my-dialog
      :dialog-visible="applyDialogVisible"
      title="提示"
      primary-btn-text="否"
      cancel-btn-text="是"
      @handlePrimary="handleCancelCancelApply"
      @handleCancel="handleCancelApply"
      @handleCancelByOutsize="handleCancelCancelApply"
    >
      <p class="dialog__context__center wider">
        该用户已在{{selectScreen.data.pointName}}报名筛查，是否需要重新报名？
        若已有报名或预约筛查，将会一起取消。
      </p>
    </my-dialog>
    <my-dialog
      :dialog-visible="questionnaireDialogVisible"
      title="幽门螺杆菌筛查问卷调查"
      primary-btn-text="确定"
      @handlePrimary="handleSubmitQuestionnaire"
      @handleCancel="questionnaireDialogVisible = false"
      @handleCancelByOutsize="questionnaireDialogVisible = false"
    >
      <div class="form-box">
        <div class="form-item">
          <p>1、是否满18岁及以上，且非孕产妇和哺乳期妇女之外的人群？</p>
          <el-radio-group v-model="questionnaire[0].answer">
            <el-radio :label=true>是</el-radio>
            <el-radio :label=false>否</el-radio>
          </el-radio-group>
        </div>
        <div class="form-item">
          <p>2、是否在一个月内未服用抗生素、PPI等药物？</p>
          <el-radio-group v-model="questionnaire[1].answer">
            <el-radio :label=true>是</el-radio>
            <el-radio :label=false>否</el-radio>
          </el-radio-group>
        </div>
        <div class="agree" @click="questionnaire[2].answer = !questionnaire[2].answer ">
          <i class="icon__radio" :class="questionnaire[2].answer ? 'is-checked' : ''"></i>
          <p class="agree__inner">
            本人承诺对上述信息内容的真实性和完整性负责。如果信息不实或缺失引起不良后果，本人愿意承担由此带来的全部风险责任。
          </p>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import MyDialog from '@/components/MyDialog'
import { getUserState, userCancel, getFamilyInfo } from '@/assets/api/request'
import { relationShipMap } from '@/assets/js/config'
import Storage from '@/assets/js/LocalStorage'

export default {
  name: 'Apply',
  components: {
    MyDialog
  },
  setup () {
    onMounted(() => {
      handleGetUserState(userInfo.userId)
    })
    const storage = new Storage()
    const userInfo = storage.getItem('__userInfo__')
    const loading = ref(false)
    const router = useRouter()
    const dialogVisible = ref(false)
    const applyDialogVisible = ref(false)
    const questionnaireDialogVisible = ref(false)
    const selectScreen = reactive({ data: {} })
    const screenList = reactive([])
    const questionnaire = reactive([
      {
        answer: undefined
      },
      {
        answer: undefined
      },
      {
        answer: false
      }
    ])

    function handleGetUserState (id) {
      loading.value = true
      getUserState(
        { id },
        res => {
          screenList.splice(0, screenList.length)
          if (res.data.data.user) {
            screenList.push({
              id: res.data.data.user.id,
              uuid: res.data.data.user.uuid,
              username: res.data.data.user.username,
              recordId: res.data.data.user.recordId,
              relationship: 0,
              relationshipText: relationShipMap[0],
              signState: res.data.data.user.signState,
              pointId: res.data.data.user.pointId,
              pointName: res.data.data.user.pointName,
              orderState: res.data.data.user.orderState,
              phone: res.data.data.user.phone
            })
            if (parseInt(screenList[0].signState) !== 1) {
              selectScreen.data = screenList[0]
            }
          }
          if (res.data.data.family) {
            res.data.data.family.forEach(record => {
              screenList.push({
                id: record.id,
                uuid: record.uuid,
                username: record.username,
                recordId: record.recordId,
                relationship: record.relationship,
                relationshipText: relationShipMap[record.relationship],
                signState: record.signState,
                pointId: record.pointId,
                pointName: record.pointName,
                orderState: record.orderState,
                phone: record.phone
              })
            })
          }
          if (screenList.length === 0) {
            dialogVisible.value = true
          }
        }
      )
      loading.value = false
    }

    function handleUserCancel (id) {
      const data = {
        id,
        signState: 0
      }
      userCancel(
        data,
        () => {
          ElMessage({
            message: '取消成功',
            type: 'success',
            duration: 2000,
            center: true
          })
          handleGetUserState(userInfo.userId)
        }
      )
    }

    function handleChangeRole (screen) {
      if (screen.signState === '1') {
        applyDialogVisible.value = true
      }
      selectScreen.data = screen
    }

    function handleDialogClose () {
      // 手机号码暂时固定，上线需要通过授权获取返回的手机号
      getFamilyInfo(
        { id: userInfo.userId },
        res => {
          router.push({
            path: '/ScreenInfo',
            query: {
              role: 0,
              phone: res.data.data.phone
            }
          })
        }
      )
    }

    function handleCancelApply () {
      // console.log('确定')
      applyDialogVisible.value = false
      handleUserCancel(selectScreen.data.recordId)
    }

    function handleCancelCancelApply () {
      // console.log('取消')
      applyDialogVisible.value = false
      selectScreen.data = screenList[0]
    }

    function handleSubmitQuestionnaire () {
      if (questionnaire[0].answer === undefined || questionnaire[1].answer === undefined || !questionnaire[2].answer) {
        ElMessage({
          message: '请完成问卷调查！',
          type: 'error',
          duration: 2000,
          center: true
        })
      } else if (!questionnaire[0].answer || !questionnaire[1].answer) {
        questionnaireDialogVisible.value = false
        ElMessage({
          message: '对不起，您不符合幽门螺杆菌筛查条件!',
          type: 'error',
          duration: 2000,
          center: true
        })
      } else {
        questionnaireDialogVisible.value = false
        router.push({
          path: '/site',
          query: {
            id: selectScreen.data.id,
            uuid: selectScreen.data.uuid,
            phone: selectScreen.data.phone,
            username: selectScreen.data.username,
            relationship: selectScreen.data.relationship
          }
        })
      }
    }

    function handleSubmit () {
      if (selectScreen.data.uuid) {
        questionnaireDialogVisible.value = true
      } else {
        ElMessage({
          message: '请选择需要报名的筛查人!',
          type: 'error',
          duration: 2000,
          center: true
        })
      }
    }

    return {
      loading,
      screenList,
      selectScreen,
      handleDialogClose,
      dialogVisible,
      handleChangeRole,
      handleSubmit,
      handleCancelApply,
      applyDialogVisible,
      handleCancelCancelApply,
      questionnaireDialogVisible,
      handleSubmitQuestionnaire,
      questionnaire
    }
  }
}
</script>

<style scoped>
.apply {
  height: 100%;
  padding: 0 16px;
  box-sizing: border-box;
}
.screens {
  height: calc(100% - 146px);
  overflow-y: scroll;
}
.screen {
  margin: 20px 0;
  padding: 20px 24px;
  text-align: left;
  color: #8491B1;
  font-size: 14px;
  background: #FDFEFF;
  box-shadow: 0 1px 4px 0 rgba(10, 36, 99, 0.12), 0 0 8px 0 rgba(10, 36, 99, 0.06);
  border-radius: 8px;
  position: relative;
}
.screen:after {
  content: '';
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #F3F3F3;
  border-radius: 50%;
}
.screen.is-active:after {
  background: url("../../../assets/images/checked.png") no-repeat center center;
  background-size: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
.screen.is-apply:after {
  content: '已报名';
  width: 50px;
  right: 10px;
  color: #318AFF;
  font-size: 15px;
  box-shadow: none;
  border: 0;
}
.screen__name {
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 600;
  color: #0A2463;
}
.screen__name__self {
  margin-left: 10px;
  font-size: 14px;
  color: #8491B1;
}
.button__default {
  position: absolute;
  width: calc(100% - 32px);
  left: 16px;
  bottom: 60px;
}
.dialog__context__center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 60px;
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
  box-sizing: border-box;
  font-size: 15px;
  line-height: 22px;
  color: #0A2463;
}
.dialog__context__center.wider {
  padding: 0 10px;
}
.agree {
  display: flex;
  align-items: center;
  font-size: 12px;
}
.agree__inner::before {
  content: '*';
  display: inline-block;
  height: 15px;
  font-size: 20px;
  color: #C91616;
  vertical-align: middle;
}
.icon__radio {
  display: block;
  margin-right: 8px;
  min-width: 24px;
  height: 24px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #F3F3F3;
  border-radius: 50%;
  position: relative;
}
.form-box {
  font-size: 15px;
  line-height: 22px;
}
.form-box :deep(.el-radio .el-radio__inner) {
  width: 24px;
  height: 24px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #F3F3F3;
}
.form-box :deep(.el-radio + .el-radio) {
  margin-left: 60px;
}
.form-box :deep(.el-radio__input.is-checked .el-radio__inner) {
  background-color: inherit;
  border-color: transparent;
}
.form-box :deep(.el-radio .is-checked .el-radio__inner::after) {
  width: 10px;
  height: 10px;
  background-color: #5AFF25;
  box-shadow: 0 1px 3px 0 rgba(90, 255, 37, 0.5);
}
.icon__radio.is-checked::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #5AFF25;
  box-shadow: 0 1px 3px 0 rgba(90, 255, 37, 0.5);
  border: 1px solid #F3F3F3;
  border-radius: 50%;
}
</style>
