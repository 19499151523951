<template>
  <div class="record" v-loading="loading">
    <header class="header">
      <div class="date-picker">
        <span>日期：</span>
        <el-date-picker
          v-model="searchDate"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="handleDateChange"
        >
        </el-date-picker>
      </div>
      <div class="header-search">
        <el-input
          v-if="user.roleFlag !== 2"
          v-model="searchText"
          clearable
          placeholder="请输入计划ID或筛查点"
          @change="handleSearchChange"
        >
          <template #suffix>
            <i class="icon icon-search"></i>
          </template>
        </el-input>
      </div>
    </header>
    <main class="main">
      <div class="table-wrapper">
        <div class="table">
          <el-table
            :data="tableData"
            height="100%"
          >
            <el-table-column type="index" label="序号" width="80" align="center" />
            <el-table-column property="pointName" label="筛查点" align="center"/>
            <el-table-column property="id" label="计划ID" align="center"/>
            <el-table-column property="planName" label="计划名称" align="center"/>
            <el-table-column property="habitation" label="所属地" align="center"/>
            <el-table-column label="计划开启日期" align="center">
              <template #default="scope">
                {{formatDateYYMMDDHHMMSS(scope.row.createTime)}}
              </template>
            </el-table-column>
            <el-table-column label="状态" align="center">
              <template #default="scope">
                {{parseInt(scope.row.state) === 1 ? '开启' : '已结束'}}
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template #default="scope">
                <i class="operate-icon operate-icon-detail" @click="showDetail(scope.$index, scope.row)"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </main>
    <div class="pagination">
      <span>显示第{{(pagination.currentPage - 1) * pagination.pageSize + 1}}到{{pagination.currentPage * pagination.pageSize}}条记录，总共{{pagination.total}}条记录，每页显示{{pagination.pageSize}}条记录</span>
      <el-pagination
        v-model:currentPage="pagination.currentPage"
        :page-size="pagination.pageSize"
        background
        layout="prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getPlanList } from '@/components/backend/assets/api/request'
import { formatDateYYMMDDHHMMSS, formatDateYYMMDDHasSplit2 } from '@/components/backend/assets/js/util'

const PAGESIZE = 10

export default {
  name: 'RecordEnd',
  setup () {
    onMounted(() => {
      store.dispatch('savePlan', {})
      if (parseInt(user.value.roleFlag) === 2) {
        searchText.value = user.value.pointName
      }
      handleGetPlanList()
    })
    const loading = ref(false)
    const router = useRouter()
    const store = useStore()
    const user = computed(() => store.state.user)
    const tableData = reactive([])
    const searchText = ref('')
    const searchDate = ref('')
    const pagination = reactive({
      currentPage: 1,
      pageSize: PAGESIZE,
      total: 100
    })

    /**
     * 获取筛查计划列表
     * */
    function handleGetPlanList () {
      loading.value = true
      let startTime, endTime
      if (searchDate.value[0]) {
        startTime = `${formatDateYYMMDDHasSplit2(searchDate.value[0].getTime())} 00:00:00`
        endTime = `${formatDateYYMMDDHasSplit2(searchDate.value[1].getTime())} 23:59:59`
      }
      const data = {
        searchValue: searchText.value,
        startTime: startTime,
        endTime: endTime,
        pageNum: pagination.currentPage,
        pageSize: pagination.pageSize,
        state: 0
      }
      try {
        getPlanList(
          data,
          res => {
            if (res.data && res.data.data && res.data.data.list) {
              tableData.length = 0
              tableData.push(...res.data.data.list)
              pagination.total = parseInt(res.data.data.total)
            } else {
              tableData.length = 0
              pagination.total = 0
            }
            loading.value = false
          },
          () => {
            loading.value = false
          }
        )
      } catch (e) {
        loading.value = false
      }
    }

    /**
     * 显示计划详情
     * */
    function showDetail (index, scope) {
      store.dispatch('savePlan', scope)
      router.push({
        name: 'recordView'
      })
    }

    /**
     * 搜索时触发
     * */
    function handleSearchChange () {
      handleGetPlanList()
    }

    /**
     * 日期变化时触发
     * */
    function handleDateChange () {
      handleGetPlanList()
    }

    /**
     * 用户表格翻页时触发
     * */
    function handleCurrentChange (page) {
      pagination.currentPage = page
      handleGetPlanList()
    }

    return {
      loading,
      tableData,
      showDetail,
      searchText,
      searchDate,
      handleSearchChange,
      handleDateChange,
      pagination,
      handleCurrentChange,
      formatDateYYMMDDHHMMSS,
      user
    }
  }
}
</script>

<style scoped>
.record {
  margin-top: 30px;
  height: calc(100% - 66px);
}
.header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  height: 36px;
  position: relative;
}
.header .date-picker {
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
}
.header .header-search {
  height: 36px;
  width: 225px;
  margin-left: 20px;
}
.header :deep(.el-input) {
  height: 100%;
}
.header :deep(.el-input__inner) {
  height: 100%;
}
.header .icon-search {
  display: inline-block;
  width: 19px;
  height: 100%;
  vertical-align: top;
  background: url("../../assets/images/icon__search.png") no-repeat center;
  background-size: 100%;
}
.header .report {
  position: absolute;
  right: 0;
}
.main {
  height: calc(100% - 120px);
  width: 100%;
}
.main .table-wrapper {
  height: 100%;
  overflow: hidden;
}
.main .table-wrapper .table {
  height: 100%;
  width: calc(100% + 16px);
}
.main :deep(.el-table th.el-table__cell) {
  font-size: 15px;
  color: #888888;
  background: #F2F3F7;
}
.main :deep(.el-table .el-table__body .cell) {
  height: 33px;
  line-height: 33px;
  font-size: 14px;
  color: #0A2463;
}
.main .operate-icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 0 8px;
  vertical-align: middle;
  cursor: pointer;
}
.main .operate-icon-detail {
  background: url("../../assets/images/icon__operate-detail.png");
}
.main .operate-icon-edit {
  background: url("../../assets/images/icon__operate-edit.png");
}
.main .operate-icon-delete {
  background: url("../../assets/images/icon__operate-delete.png");
}
.pagination {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: #7D7D7D;
}
.record {
  position: relative;
}
</style>
