import * as types from './mutation-types'

const mutations = {
  [types.SET_MONTH] (state, month) {
    state.month = month
  },
  [types.SET_PLAN] (state, plan) {
    state.plan = plan
  },
  [types.SET_USER] (state, user) {
    state.user = user
  }
}

export default mutations
