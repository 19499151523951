<template>
  <div class="screen-record" v-loading="loading">
    <div class="header" @click="handleChangeScreen">
      筛查人：{{role.username}}
      <i class="icon__change"></i>
    </div>
    <div class="tabs-wrapper">
      <el-tabs v-model="activeName" @tab-click="handleTabsChange">
        <el-tab-pane
          v-for="tab in tabs"
          :key="tab.id"
          :label="tab.label"
          :name="tab.value"
        >
          <div class="screen__none" v-if="!displayList.length">
            <img class="screen__none-bg" src="../../../../assets/images/screen__none.png" alt="">
            <span class="screen__none__inner" v-if="activeName === '1'">暂未报名幽门螺杆菌筛查</span>
            <span class="screen__none__inner" v-else-if="activeName === '2'">暂未预约</span>
            <span class="screen__none__inner" v-else-if="activeName === '3'">暂无筛查记录</span>
          </div>
          <div class="record-wrapper">
            <div class="record" v-for="record in displayList" :key="record.id">
              <div class="record-top">
                <div class="record-top__time">
                  <span>{{activeName === '1' ? '报名' : activeName === '2' ? '预约' : '筛查'}}时间:{{record.applyTime}}</span>
                  <span class="status" v-if="activeName === '1'">{{record.applyStatus === 1 ? '已报名' : '未报名'}}</span>
                  <span class="status" v-if="activeName === '3'">已完成</span>
                </div>
                <div class="record-top__name">
                  <span>筛查点：{{record.screenName}}</span>
                  <span class="status" v-if="activeName === '1'">{{record.appointmentStatus === 1 ? '已预约' : '未预约'}}</span>
                </div>
              </div>
              <div class="record-bottom">
                <div class="record-bottom__address">
                  地址：{{record.address}}
                </div>
                <div class="record-bottom__btn">
                  <el-button @click="handleClickRecord(record)" v-if="activeName === '1' || activeName === '2'">取消</el-button>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <my-dialog
      :dialog-visible="dialogVisible"
      title="提示"
      primary-btn-text="否"
      cancel-btn-text="是"
      @handlePrimary="dialogVisible = false"
      @handleCancel="handleCancelApply"
      @handleCancelByOutsize="dialogVisible = false"
    >
      <div class="dialog__context__center">
        <span v-if="activeName === '1'">是否取消当前报名？<span style="color: #FF0000">若已预约筛查，会连同预约筛查一起取消！</span></span>
        <span v-if="activeName === '2'">是否取消当前预约？</span>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getFamily, getUserRecord, userCancel } from '@/assets/api/request'
import { ElMessage } from 'element-plus'
import Storage from '@/assets/js/LocalStorage'
import { formatDateYYMMDDHHMMSS, formatDateYYMMDDHasSplit } from '@/assets/js/util'
import MyDialog from '@/components/MyDialog'

export default {
  name: 'ScreenRecord',
  components: {
    MyDialog
  },
  setup () {
    onMounted(() => {
      if (!route.query.uuid) {
        const userInfo = storage.getItem('__userInfo__')
        getFamily(
          {
            id: userInfo.userId
          },
          res => {
            if (res.data.data.user.length > 0) {
              role.uuid = res.data.data.user[0].uuid
              role.username = res.data.data.user[0].username
              handleGetUserRecord(res.data.data.user[0].uuid)
            } else {
              ElMessage({
                message: '未录入用户信息，请先填写用户信息!',
                type: 'error',
                duration: 2000,
                center: true
              })
              router.push('/screenInfo')
            }
          }
        )
      } else {
        role.uuid = route.query.uuid
        role.username = route.query.username
        handleGetUserRecord(route.query.uuid)
      }
    })
    const loading = ref(false)
    const router = useRouter()
    const route = useRoute()
    const storage = new Storage()
    const dialogVisible = ref(false)
    const role = reactive({
      username: '程晓明',
      uuid: 1
    })
    const activeName = ref('1')
    const tabs = reactive([
      {
        value: '1',
        label: '报名记录'
      },
      {
        value: '2',
        label: '预约记录'
      },
      {
        value: '3',
        label: '筛查记录'
      }
    ])
    const selectRecordId = ref(null)
    const dataList = reactive([])
    const displayList = computed(() => {
      const result = []
      if (activeName.value === '1') {
        dataList.forEach(data => {
          if (data.signState === 1) {
            result.push({
              id: data.id,
              applyTime: data.signTime,
              screenName: data.pointName,
              applyStatus: data.signState,
              appointmentStatus: data.orderState,
              address: data.address
            })
          }
        })
      } else if (activeName.value === '2') {
        dataList.forEach(data => {
          if (data.orderState === 1) {
            result.push({
              id: data.id,
              applyTime: `${data.checkDate} ${data.checkStartTime}~${data.checkEndTime}`,
              screenName: data.pointName,
              appointmentStatus: data.orderState,
              address: data.address
            })
          }
        })
      } else if (activeName.value === '3') {
        dataList.forEach(data => {
          if (data.checkState === 2) {
            result.push({
              id: data.id,
              applyTime: `${data.checkDate} ${data.checkStartTime}~${data.checkEndTime}`,
              screenName: data.pointName,
              address: data.address
            })
          }
        })
      }
      return result
    })

    // const applyList = reactive([
    //   {
    //     id: 1,
    //     applyTime: '2021-11-01 16:01',
    //     screenName: '沙头社区健康服务中心',
    //     address: '深圳市南山区前海桂湾五路128号前海时代广场6栋01层2号房、02层2号房',
    //     applyStatus: 1,
    //     appointmentStatus: 1
    //   },
    //   {
    //     id: 2,
    //     applyTime: '2021-11-01 16:01',
    //     screenName: '官田社区健康服务中心',
    //     address: '深圳市宝安区宝石东路27',
    //     applyStatus: 1,
    //     appointmentStatus: 0
    //   },
    //   {
    //     id: 3,
    //     applyTime: '2021-11-01 16:01',
    //     screenName: '官田社区健康服务中心',
    //     address: '深圳市宝安区宝石东路27',
    //     applyStatus: 1,
    //     appointmentStatus: 0
    //   },
    //   {
    //     id: 4,
    //     applyTime: '2021-11-01 16:01',
    //     screenName: '官田社区健康服务中心',
    //     address: '深圳市宝安区宝石东路27',
    //     applyStatus: 1,
    //     appointmentStatus: 0
    //   },
    //   {
    //     id: 5,
    //     applyTime: '2021-11-01 16:01',
    //     screenName: '官田社区健康服务中心',
    //     address: '深圳市宝安区宝石东路27',
    //     applyStatus: 1,
    //     appointmentStatus: 0
    //   }
    // ])

    // const appointmentList = reactive([
    //   {
    //     id: 1,
    //     applyTime: '2021-11-01 上午10:00~11:00',
    //     screenName: '沙头社区健康服务中心',
    //     address: '深圳市南山区前海桂湾五路128号前海时代广场6栋01层2号房、02层2号房',
    //     applyStatus: 1,
    //     appointmentStatus: 1
    //   },
    //   {
    //     id: 2,
    //     applyTime: '2021-11-01 上午10:00~11:00',
    //     screenName: '官田社区健康服务中心',
    //     address: '深圳市宝安区宝石东路27',
    //     applyStatus: 1,
    //     appointmentStatus: 0
    //   },
    //   {
    //     id: 3,
    //     applyTime: '2021-11-01 上午10:00~11:00',
    //     screenName: '官田社区健康服务中心',
    //     address: '深圳市宝安区宝石东路27',
    //     applyStatus: 1,
    //     appointmentStatus: 0
    //   },
    //   {
    //     id: 4,
    //     applyTime: '2021-11-01 上午10:00~11:00',
    //     screenName: '官田社区健康服务中心',
    //     address: '深圳市宝安区宝石东路27',
    //     applyStatus: 1,
    //     appointmentStatus: 0
    //   },
    //   {
    //     id: 5,
    //     applyTime: '2021-11-01 上午10:00~11:00',
    //     screenName: '官田社区健康服务中心',
    //     address: '深圳市宝安区宝石东路27',
    //     applyStatus: 1,
    //     appointmentStatus: 0
    //   }
    // ])

    // const screenList = reactive([
    //   {
    //     id: 1,
    //     applyTime: '2021-11-01 上午10:00~11:00',
    //     screenName: '官田社区健康服务中心',
    //     address: '深圳市宝安区宝石东路27',
    //     applyStatus: 1,
    //     appointmentStatus: 0
    //   }
    // ])

    function handleTabsChange (tab, event) {
      // console.log(tab, event)
    }

    function handleClickRecord (record) {
      selectRecordId.value = record.id
      dialogVisible.value = true
    }

    function handleCancelApply () {
      handleUserCancel(selectRecordId.value)
      dialogVisible.value = false
    }

    function handleChangeScreen () {
      const params = {
        uuid: route.query.uuid
      }
      router.push({
        name: 'ScreenChange',
        params: params
      })
    }

    function handleGetUserRecord (uuid) {
      loading.value = true
      getUserRecord(
        {
          uuid: uuid
        },
        res => {
          // console.log(res)
          dataList.splice(0, dataList.length)
          res.data.data.forEach(data => {
            if (data.signTime) {
              data.signTime = formatDateYYMMDDHHMMSS(data.signTime)
            }
            if (data.orderTime) {
              data.orderTime = formatDateYYMMDDHHMMSS(data.orderTime)
            }
            if (data.bindingTime) {
              data.bindingTime = formatDateYYMMDDHHMMSS(data.bindingTime)
            }
            if (data.checkDate) {
              data.checkDate = formatDateYYMMDDHasSplit(data.checkDate)
            }
            dataList.push(data)
          })
        }
      )
      loading.value = false
    }

    function handleUserCancel (id) {
      let data = {}
      if (activeName.value === '1') {
        data = {
          id: id,
          signState: 0
        }
      } else if (activeName.value === '2') {
        data = {
          id: id,
          orderState: 2
        }
      }
      userCancel(
        data,
        () => {
          ElMessage({
            message: '取消成功',
            type: 'success',
            duration: 2000,
            center: true
          })
          handleGetUserRecord(role.uuid)
        }
      )
    }

    return {
      loading,
      dialogVisible,
      role,
      tabs,
      activeName,
      displayList,
      handleClickRecord,
      handleTabsChange,
      handleCancelApply,
      handleChangeScreen,
      handleUserCancel
    }
  }
}
</script>

<style scoped>
.screen-record {
  height: 100%;
}
.header {
  width: 100%;
  height: 56px;
  padding: 20px 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 15px;
  color: #0A2463;
  background-color: #FFFFFF;
}
.icon__change {
  width: 18px;
  height: 18px;
  background: url("../../../../assets/images/icon__change.png") no-repeat center center;
  background-size: 100%;
}
.tabs-wrapper :deep(.el-tabs__nav) {
  width: calc(100% - 24px);
  margin: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabs-wrapper :deep(.el-tabs__item) {
  width: 100%;
  margin: 0;
  padding: 0;
  color: #136FD1;
}
.tabs-wrapper :deep(.el-tabs__item.is-active) {
  color: #0A2463;
}
.tabs-wrapper :deep(.el-tabs__active-bar) {
  height: 4px;
}
.tabs-wrapper {
  height: calc(100% - 56px);
}
.tabs-wrapper :deep(.el-tabs) {
  height: 100%;
}
:deep(.el-tabs__content) {
  height: calc(100% - 66px);
}
:deep(.el-tabs__content .el-tab-pane) {
  height: 100%;
  overflow-y: scroll;
  position: relative;
}
.screen__none {
  position: absolute;
  top: 40%;
  left: 50%;
  width: calc(100% - 40px);
  transform: translate(-50%, -50%);
  text-align: center;
}
.screen__none__inner {
  font-size: 15px;
  color: #0A2463;
}
.screen__none-bg {
  display: block;
  margin: 0 auto 32px;
}
:deep(.el-tabs__nav-wrap::after) {
  height: 0;
}
.record {
  margin: 20px 0;
  text-align: left;
  line-height: 20px;
  color: #0A2463;
  background: #FDFEFF;
  box-shadow: 0 1px 4px 0 rgba(10, 36, 99, 0.12), 0 0 8px 0 rgba(10, 36, 99, 0.06);
  border-radius: 8px;
}
.record-top {
  padding: 14px 20px 20px;
  border-bottom: 1px solid #EDEDED;
}
.record-top__time {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.record-top__name {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
}
.status {
  font-size: 14px;
  color: #8491B1;
}
.record-bottom {
  padding: 0 20px 14px;
}
.record-bottom__address {
  margin-top: 10px;
  padding-left: 20px;
}
.record-bottom__address:before {
  content: '';
  display: inline-block;
  margin-left: -20px;
  margin-right: 8px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #5AFF25;
}
.record-bottom__btn {
  margin-top: 10px;
  text-align: right;
}
.record-wrapper .record-bottom :deep(.el-button) {
  min-height: 27px;
  width: 71px;
  padding: 0;
  color: #136FD1;
  font-size: 15px;
  border-color: #136FD1;
  border-radius: 100px;
  background-color: #FFFFFF;
}
.record-wrapper {
  padding: 0 20px;
}
.dialog__context__center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
  box-sizing: border-box;
  font-size: 15px;
  color: #0A2463;
}
</style>
