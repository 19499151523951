<template>
  <div class="calendar-picker">
    <div class="calendar__header">
      <div class="calendar__header-group">
        <div class="prev-month calendar__header-button" :class="monthCls(date.currentYear, date.currentMonth - 1)" @click="handleGetPrevMonth">上一个月</div>
        <div class="current-month calendar__header-button" :class="monthCls(date.currentYear, date.currentMonth)" @click="refresh">本月</div>
        <div class="next-month calendar__header-button" :class="monthCls(date.currentYear, date.currentMonth + 1)" @click="handleGetNextMonth">下一个月</div>
      </div>
      <el-date-picker
        v-model="monthSelectValue"
        type="month"
        @change="handleMonthSelectChange"
        :clearable="false"
      >
      </el-date-picker>
      <slot name="header-slot"></slot>
    </div>
    <div class="calendar__body">
      <table class="calendar-table">
        <thead>
        <tr>
          <th>一</th>
          <th>二</th>
          <th>三</th>
          <th>四</th>
          <th>五</th>
          <th>六</th>
          <th>日</th>
        </tr>
        </thead>
        <tbody>
        <tr class="calendar-table__row" v-for="(line, index) in dateList" :key="'line' + index">
          <td v-for="count in line" :class="dayCls(index, count)" :key="'day' + count.value">
            <div class="calendar-day">
              <div class="calendar-day__inner">
                <span>{{count.value}}</span>
                <div class="calendar-day__plan">
                  <div v-if="count.plan">
                    <div>{{count.plan.time}}</div>
                    <div>线上预约：<span style="color: #C91616">{{count.plan.onLine}}</span>/{{count.plan.maxOnLine}}</div>
                    <div>现场预约：<span style="color: #C91616">{{count.plan.live}}</span>/{{count.plan.maxLive}}</div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, onMounted, watch } from 'vue'
import { pad } from '@/assets/js/util'

/**
 *对比plan中的日期，在日历中展示计划
 */

// import { plan } from '@/components/backend/assets/js/data'

export default {
  name: 'MyTimePicker',
  emits: ['changeMonth'],
  props: {
    plan: Object
  },
  setup (props, context) {
    onMounted(() => {
    })
    const today = new Date()
    const date = reactive({
      currentDay: today.getDate(),
      currentMonth: today.getMonth() + 1,
      currentYear: today.getFullYear(),
      selectMonth: today.getMonth() + 1,
      selectYear: today.getFullYear(),
      selectDay: ''
    })
    const monthSelectValue = ref(new Date(date.currentYear, date.currentMonth - 1, 1))
    const preMonthDays = computed(() => {
      return new Date(date.selectYear, date.selectMonth - 1, 0).getDate()
    })
    const currentDays = computed(() => {
      return new Date(date.selectYear, date.selectMonth, 0).getDate()
    })
    const offsetDay = computed(() => {
      return (new Date(date.selectYear, date.selectMonth - 1, 1).getDay() - 1 + 7) % 7
    })

    const dateList = computed(() => {
      const result = []
      result[0] = []
      for (let i = 0; i < offsetDay.value; i++) {
        const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth - 2, preMonthDays.value - i).getTime())
        if (index !== -1) {
          result[0].push({
            value: preMonthDays.value - i,
            plan: props.plan[index]
          })
        } else {
          result[0].push({
            value: preMonthDays.value - i
          })
        }
      }
      result[0].reverse()
      for (let i = 0; i < 7 - offsetDay.value; i++) {
        const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth - 1, i + 1).getTime())
        if (index !== -1) {
          result[0].push({
            value: i + 1,
            plan: props.plan[index]
          })
        } else {
          result[0].push({
            value: i + 1
          })
        }
      }
      for (let i = 1; i < 4; i++) {
        result[i] = []
        for (let j = 1; j <= 7; j++) {
          const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth - 1, j + (7 - offsetDay.value) + 7 * (i - 1)).getTime())
          if (index !== -1) {
            result[i].push({
              value: j + (7 - offsetDay.value) + 7 * (i - 1),
              plan: props.plan[index]
            })
          } else {
            result[i].push({
              value: j + (7 - offsetDay.value) + 7 * (i - 1)
            })
          }
        }
      }
      result[4] = []
      const len = currentDays.value - ((7 - offsetDay.value) + 21) <= 7 ? currentDays.value - ((7 - offsetDay.value) + 21) : 7
      for (let i = 1; i <= len; i++) {
        const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth - 1, i + (7 - offsetDay.value) + 7 * 3).getTime())
        if (index !== -1) {
          result[4].push({
            value: i + (7 - offsetDay.value) + 7 * 3,
            plan: props.plan[index]
          })
        } else {
          result[4].push({
            value: i + (7 - offsetDay.value) + 7 * 3
          })
        }
      }
      const lenFour = result[4].length
      if (lenFour !== 7) {
        for (let i = 1; i <= 7 - lenFour; i++) {
          const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth, i).getTime())
          if (index !== -1) {
            result[4].push({
              value: i,
              plan: props.plan[index]
            })
          } else {
            result[4].push({
              value: i
            })
          }
        }
        result[5] = []
        for (let i = 7 - lenFour + 1; i <= 7 + 7 - lenFour; i++) {
          const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth, i).getTime())
          if (index !== -1) {
            result[5].push({
              value: i,
              plan: props.plan[index]
            })
          } else {
            result[5].push({
              value: i
            })
          }
        }
      } else {
        result[5] = []
        for (let i = 5 * 7 - offsetDay.value + 1; i <= currentDays.value; i++) {
          const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth - 1, i).getTime())
          if (index !== -1) {
            result[5].push({
              value: i,
              plan: props.plan[index]
            })
          } else {
            result[5].push({
              value: i
            })
          }
        }
        const len5 = result[5].length
        for (let i = 1; i <= 7 - len5; i++) {
          const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth, i).getTime())
          if (index !== -1) {
            result[5].push({
              value: i,
              plan: props.plan[index]
            })
          } else {
            result[5].push({
              value: i
            })
          }
        }
      }
      return result
    })

    const planDateList = reactive([])

    watch(() => props.plan, value => {
      planDateList.length = 0
      for (let i = 0; i < value.length; i++) {
        planDateList.push(new Date(value[i].date).getTime())
      }
    })

    // const planDateList = props.plan.map(p => {
    //   console.log(p)
    //   console.log(new Date(p.date))
    //   return new Date(p.date).getTime()
    // })

    watch(date, value => {
      monthSelectValue.value = new Date(value.selectYear, value.selectMonth - 1, 1)
      context.emit('changeMonth', `${date.selectYear}-${pad(date.selectMonth)}`)
    })

    function dayCls (index, count) {
      if (index === 0 && count.value > 7) {
        return 'prev'
      } else if ((index === 4 || index === 5) && count.value <= 14) {
        return 'next'
      } else {
        if (count.plan) {
          return 'current is-plan'
        } else {
          return 'current'
        }
      }
    }

    function monthCls (year, month) {
      return new Date(year, month - 1, 1).getTime() === new Date(date.selectYear, date.selectMonth - 1, 1).getTime() ? 'is-active' : ''
    }

    function handleGetPrevMonth () {
      if (date.currentMonth === 1) {
        date.selectYear = date.currentYear - 1
        date.selectMonth = 12
      } else {
        date.selectMonth = date.currentMonth - 1
        date.selectYear = date.currentYear
      }
    }

    function handleGetNextMonth () {
      if (date.currentMonth === 12) {
        date.selectYear = date.currentYear + 1
        date.selectMonth = 1
      } else {
        date.selectMonth = date.currentMonth + 1
        date.selectYear = date.currentYear
      }
    }

    function refresh () {
      date.selectMonth = date.currentMonth
      date.selectYear = date.currentYear
    }

    function handleMonthSelectChange (value) {
      date.selectYear = value.getFullYear()
      date.selectMonth = value.getMonth() + 1
    }

    return {
      date,
      monthSelectValue,
      dateList,
      dayCls,
      handleGetPrevMonth,
      handleGetNextMonth,
      refresh,
      monthCls,
      handleMonthSelectChange,
      planDateList
    }
  }
}
</script>

<style scoped>
.calendar-picker {
}
.calendar-picker {
  font-size: 15px;
  background-color: #FBFBFB;
}
.calendar__header {
  display: flex;
  padding: 16px 0;
  align-items: center;
}
.calendar__header :deep(.el-input__inner) {
  height: 36px;
}
.calendar__header-group {
  display: flex;
  align-items: center;
  margin-right: 17px;
}
.calendar__header-button {
  width: 134px;
  height: 36px;
  line-height: 36px;
  box-sizing: border-box;
  color: #136FD1;
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
  border-top: 1px solid #136FD1;
  border-left: 1px solid #136FD1;
  border-bottom: 1px solid #136FD1;
}
.calendar__header-button:last-child {
  border-right: 1px solid #136FD1;
}
.calendar__header-button.is-active {
  color: #FFFFFF;
  background: #136FD1;
}
.calendar-table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}
.calendar-table thead th {
  padding: 12px 0;
  font-weight: 400;
  color: #0A2463;
  /*background: #8491B1;*/
  /*border: 1px solid #8491B1;*/
}
.calendar__body .prev,.calendar__body .current,.calendar__body .next {
  padding: 8px;
  text-align: center;
  color: #8491B1;
  /*border: 1px solid #8491B1;*/
}
.calendar__body .current.is-plan {
  color: #136FD1;
}
.calendar-table .calendar-day {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
}
.calendar-table .calendar-day .calendar-day__inner {
  font-size: 14px;
  line-height: 16px;
}
.calendar-table .calendar-day .calendar-day__inner .calendar-day__plan {
  height: 54px;
}
.current.is-active .calendar-day{
  color: #136FD1;
}
.current.is-active.is-checked .calendar-day {
  background-color: #136FD1;
  color: #FFFFFF;
  border-radius: 2px;
}
</style>
