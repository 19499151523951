<template>
  <div class="sidebar">
    <div class="sidebar-header">
      <h3>幽门螺杆菌筛查后台管理系统</h3>
    </div>
    <div class="menu">
      <router-link
        class="menu-item"
        v-for="item in menu"
        :key="item.id"
        :class="routerLinkCls(item.name)"
        :to="item.src"
      >
        <i class="icon" :style="iconSty(item.name)"></i>
        {{item.desc}}
      </router-link>
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'
import { allMenu } from '@/components/backend/assets/js/config'
import { useStore } from 'vuex'

export default {
  name: 'Sidebar',
  setup () {
    onMounted(() => {
      // menu.data = allMenu.filter(item => { return item.Authority.indexOf(Authority.value) !== -1 })
      // if (Authority === 2) {
      //   menu.data = allMenu.filter(item => { return item.Authority === Authority })
      // } else {
      //   menu.data = allMenu
      // }
    })
    const route = useRoute()
    const store = useStore()
    const Authority = computed(() => parseInt(store.state.user.roleFlag))
    const menu = computed(() => {
      return allMenu.filter(item => { return item.Authority.indexOf(Authority.value) !== -1 })
    })
    function routerLinkCls (name) {
      const path = route.path.split('/')[2]
      if (name === path) {
        return 'menu-item-active'
      }
    }
    function iconSty (name) {
      const path = route.path.split('/')[2]
      let img
      if (name === path) {
        img = require(`../assets/images/icon_${name}_s.png`)
      } else {
        img = require(`../assets/images/icon_${name}_n.png`)
      }
      return {
        background: `url(${img}) no-repeat center`,
        backgroundSize: '100% 100%'
      }
    }
    return {
      menu,
      routerLinkCls,
      iconSty,
      Authority
    }
  }
}
</script>

<style scoped>
.sidebar {
  font-size: 16px;
  box-shadow: 2px 0 15px 4px rgba(208, 208, 208, 0.5);
}
.sidebar .sidebar-header {
  display: flex;
  height: 68px;
  padding-left: 40px;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #FFF;
  background-color: #318AFF;
}

.sidebar .sidebar-header .sidebar-header-logo {
  height: 24px;
  width: 75px;
  margin-right: 15px;
}
.sidebar .menu .menu-item {
  display: flex;
  height: 70px;
  align-items: center;
  color: #757575;
  cursor: pointer;
}
.sidebar .menu .icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin: 0 29px 0 81px;
  font-size: 22px;
  filter: drop-shadow(0 0 #ffffff);
}
.sidebar .menu .menu-item-active {
  background: #136FD1;
  color: #FFF;
}
</style>
