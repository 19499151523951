<template>
  <teleport to="#main">
    <div :class="show ? 'frame-wrapper' : 'frame-wrapper__close'">
      <div :class="show ? 'frame' : 'frame__close'">
        <header class="header">
          {{parseInt(operate) === 0 ? '查看筛查点' : parseInt(pointId) === -1 ? '新增筛查点' : '编辑筛查点'}}
          <el-button type="text" class="header-button__text" @click="close">关闭</el-button>
        </header>
        <el-form
          ref="pointRef"
          :model="point"
          :rules="pointRules"
          class="main"
          v-if="parseInt(operate) === 1">
          <div class="main-row">
            <el-form-item prop="id" class="main-cell">
              <span class="cell-label">筛查点ID：</span>
              <el-input class="cell-value" v-model="point.id" disabled></el-input>
            </el-form-item>
            <el-form-item prop="name" class="main-cell">
              <span class="cell-label">筛查点名称：</span>
              <el-input class="cell-value" v-model="point.name"></el-input>
            </el-form-item>
          </div>
          <div class="main-row">
            <el-form-item prop="state" class="main-cell">
              <span class="cell-label">状态：</span>
              <el-select class="cell-value" v-model="point.state" placeholder="请选择筛查点类型">
                <el-option
                  v-for="item in stateOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="typeId" class="main-cell">
              <span class="cell-label">筛查点类型：</span>
              <el-select class="cell-value" v-model="point.typeId" placeholder="请选择筛查点类型">
                <el-option
                  v-for="item in typeIdOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="main-row">
            <el-form-item prop="habitation" class="main-cell">
              <span class="cell-label">所属地：</span>
              <el-input class="cell-value" v-model="point.habitation"></el-input>
            </el-form-item>
            <el-form-item prop="enrollWay" class="main-cell">
              <span class="cell-label">报名方式：</span>
              <el-select class="cell-value" v-model="point.enrollWay" placeholder="请选择报名方式">
                <el-option
                  v-for="item in enrollWayOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="main-row">
            <el-form-item prop="address" class="main-cell">
              <span class="cell-label">详细地址：</span>
              <el-input class="cell-value" v-model="point.address"></el-input>
            </el-form-item>
          </div>
          <div class="main-row">
            <el-form-item prop="longitude" class="main-cell">
              <span class="cell-label">经度：</span>
              <el-input class="cell-value" oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+7)}" v-model="point.longitude"></el-input>
            </el-form-item>
            <el-form-item prop="latitude" class="main-cell">
              <span class="cell-label">纬度：</span>
              <el-input class="cell-value" oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+7)}" v-model="point.latitude"></el-input>
            </el-form-item>
          </div>
          <div class="main-row">
            <el-form-item prop="describe" class="main-cell">
              <span class="cell-label">描述：</span>
              <el-input class="cell-value" v-model="point.describe"></el-input>
            </el-form-item>
          </div>
          <div class="main-box">
            <div class="main-box-title">
              筛查点负责人
              <el-button class="main-box-title-cell" @click="handleAddPrincipal"><i class="icon-add"></i>新增</el-button>
            </div>
            <div class="main-box-li" v-for="principal in displayPrincipals" :key="principal.id">
              <div class="main-box-li-cell">
                姓名: {{principal.name}}
              </div>
              <div class="main-box-li-cell">
                联系方式: {{principal.phone}}
              </div>
              <i class="icon-delete" @click="handleDeletePrincipal(principal.id)"></i>
            </div>
            <div class="main-box-li__add" v-for="(newPrincipal, index) in newPrincipals" :key="index">
              <div class="main-box-li-cell">
                <span class="main-box-li-cell-label">姓名：</span>
                <el-input class="main-box-li-cell-value" v-model="newPrincipal.name"></el-input>
              </div>
              <div class="main-box-li-cell">
                <span class="main-box-li-cell-label">联系方式：</span>
                <el-input class="main-box-li-cell-value" v-model="newPrincipal.phone"></el-input>
              </div>
              <i class="icon-delete" @click="handleDeleteNewPrincipal(index)"></i>
            </div>
          </div>
        </el-form>
        <div class="main" v-else>
          <div class="main-row">
            <div class="main-cell">
              <span class="cell-label">筛查点ID：</span>
              <span class="cell-value">{{point.id}}</span>
            </div>
            <div class="main-cell">
              <span class="cell-label">筛查点名称：</span>
              <span class="cell-value">{{point.name}}</span>
            </div>
          </div>
          <div class="main-row">
            <div class="main-cell">
              <span class="cell-label">状态：</span>
              <span class="cell-value">{{parseInt(point.state) === 0 ? '停用' : '正常' }}</span>
            </div>
            <div class="main-cell">
              <span class="cell-label">筛查点类型：</span>
              <span class="cell-value">{{parseInt(point.state) === 1 ? '医院' : '社康' }}</span>
            </div>
          </div>
          <div class="main-row">
            <div class="main-cell">
              <span class="cell-label">所属地：</span>
              <span class="cell-value">{{point.habitation}}</span>
            </div>
            <div class="main-cell">
              <span class="cell-label">报名方式：</span>
              <span class="cell-value">{{parseInt(point.state) === 1 ? '仅支持线上报名' : '支持线上及现场报名' }}</span>
            </div>
          </div>
          <div class="main-row">
            <div class="main-cell">
              <span class="cell-label">详细地址：</span>
              <span class="cell-value">{{point.address}}</span>
            </div>
          </div>
          <div class="main-row">
            <div class="main-cell">
              <span class="cell-label">经度：</span>
              <span class="cell-value">{{point.longitude}}</span>
            </div>
            <div class="main-cell">
              <span class="cell-label">纬度：</span>
              <span class="cell-value">{{point.latitude}}</span>
            </div>
          </div>
          <div class="main-row">
            <div class="main-cell">
              <span class="cell-label">描述：</span>
              <span class="cell-value">{{point.describe}}</span>
            </div>
          </div>
          <div class="main-box">
            <div class="main-box-title">
              筛查点负责人
              <el-button class="main-box-title-cell" v-if="parseInt(operate) === 1" @click="handleAddPrincipal"><i class="icon-add"></i>新增</el-button>
            </div>
            <div class="main-box-li" v-for="principal in displayPrincipals" :key="principal.id">
              <div class="main-box-li-cell">
                姓名: {{principal.name}}
              </div>
              <div class="main-box-li-cell">
                联系方式: {{principal.phone}}
              </div>
              <i class="icon-delete" v-if="parseInt(operate) === 1" @click="handleDeletePrincipal(principal.id)"></i>
            </div>
            <div class="main-box-li__add" v-for="(newPrincipal, index) in newPrincipals" :key="index">
              <div class="main-box-li-cell">
                <span class="main-box-li-cell-label">姓名：</span>
                <el-input class="main-box-li-cell-value" v-model="newPrincipal.name"></el-input>
              </div>
              <div class="main-box-li-cell">
                <span class="main-box-li-cell-label">联系方式：</span>
                <el-input class="main-box-li-cell-value" v-model="newPrincipal.phone"></el-input>
              </div>
              <i class="icon-delete" v-if="parseInt(operate) === 1" @click="handleDeleteNewPrincipal(index)"></i>
            </div>
          </div>
        </div>
        <div class="footer">
          <el-button class="footer__btn" @click="submit">确定</el-button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { ref, reactive, computed, watch } from 'vue'
import { ElMessageBox } from 'element-plus'
import { getScreeningPointInfoById, addScreeningPoint, updateScreeningPointInfo } from '@/components/backend/assets/api/request'
import { verifyingPhone, verifyingSpecialCharacter } from '@/assets/js/util'

export default {
  name: 'PointFrame',
  emits: ['update:show', 'frameClose'],
  props: {
    show: Boolean,
    pointId: Number,
    operate: Number
  },
  setup (props, context) {
    const pointRef = ref(null)
    const point = reactive({
      id: '',
      name: '',
      state: '',
      typeId: '',
      habitation: '',
      enrollWay: '',
      address: '',
      longitude: '',
      latitude: '',
      describe: '',
      principals: []
    })
    const pointRules = reactive({
      name: [
        {
          required: true,
          message: '请输入筛查点名称',
          trigger: 'blur'
        }
      ],
      state: [
        {
          required: true,
          message: '请选择筛查点状态',
          trigger: 'blur'
        }
      ],
      typeId: [
        {
          required: true,
          message: '请选择筛查点类型',
          trigger: 'blur'
        }
      ],
      habitation: [
        {
          required: true,
          message: '请输入筛查点所属地',
          trigger: 'blur'
        }
      ],
      enrollWay: [
        {
          required: true,
          message: '请选择筛查点报名方式',
          trigger: 'blur'
        }
      ],
      address: [
        {
          required: true,
          message: '请输入筛查点地址',
          trigger: 'blur'
        }
      ],
      longitude: [
        {
          required: true,
          message: '请输入筛查点经度',
          trigger: 'blur'
        }
      ],
      latitude: [
        {
          required: true,
          message: '请输入筛查点纬度',
          trigger: 'blur'
        }
      ]
    })
    const newPrincipals = reactive([])
    const displayPrincipals = computed(() => {
      return point.principals.filter(item => { return item.enableFlag === 1 })
    })
    const principals = computed(() => {
      return {
        count: displayPrincipals.value.length + newPrincipals.length,
        principals: point.principals.concat(newPrincipals)
      }
    })
    const stateOptions = reactive([
      {
        value: 1,
        label: '正常'
      },
      {
        value: 0,
        label: '停用'
      }
    ])
    const typeIdOptions = reactive([
      {
        value: 1,
        label: '医院'
      },
      {
        value: 2,
        label: '社康'
      }
    ])
    const enrollWayOptions = reactive([
      {
        value: 1,
        label: '仅支持线上报名'
      },
      {
        value: 2,
        label: '支持现场和线上报名'
      }
    ])

    watch(() => props.show, value => {
      if (value) {
        if (props.pointId !== -1) {
          handleGetScreeningPointInfoById(props.pointId)
        }
      }
    })

    function close () {
      point.id = ''
      point.name = ''
      point.state = ''
      point.typeId = ''
      point.habitation = ''
      point.enrollWay = ''
      point.address = ''
      point.longitude = ''
      point.latitude = ''
      point.describe = ''
      point.principals = []
      newPrincipals.length = 0
      context.emit('frameClose')
      context.emit('update:show', false)
    }

    /**
     * 获取筛查点详细信息
     * */
    function handleGetScreeningPointInfoById (id) {
      getScreeningPointInfoById(
        {
          id: id
        },
        res => {
          point.id = res.data.data.screeningPoint.id
          point.name = res.data.data.screeningPoint.name
          point.state = res.data.data.screeningPoint.state
          point.typeId = res.data.data.screeningPoint.typeId
          point.habitation = res.data.data.screeningPoint.habitation
          point.enrollWay = res.data.data.screeningPoint.enrollWay
          point.address = res.data.data.screeningPoint.address
          point.longitude = res.data.data.screeningPoint.longitude
          point.latitude = res.data.data.screeningPoint.latitude
          point.describe = res.data.data.screeningPoint.describe
          for (let i = 0; i < res.data.data.list.length; i++) {
            point.principals.push({
              id: res.data.data.list[i].id,
              name: res.data.data.list[i].name,
              phone: res.data.data.list[i].phone,
              enableFlag: res.data.data.list[i].enableFlag
            })
          }
        }
      )
    }

    function handleAddPrincipal () {
      newPrincipals.push({
        name: '',
        phone: '',
        enableFlag: 1
      })
    }

    function handleDeletePrincipal (id) {
      if (principals.value.count > 1) {
        for (let i = 0; i < point.principals.length; i++) {
          if (point.principals[i].id === id) {
            point.principals[i].enableFlag = 0
          }
        }
      } else {
        ElMessageBox.alert('请至少保持一个负责人！', '提示', {
          confirmButtonText: '确定'
        })
      }
    }

    function handleDeleteNewPrincipal (index) {
      if (principals.value.count > 1) {
        newPrincipals.splice(index, 1)
      } else {
        ElMessageBox.alert('请至少保持一个负责人！', '提示', {
          confirmButtonText: '确定'
        })
      }
    }

    function handleAddScreeningPoint (point) {
      addScreeningPoint(point, res => {
      })
    }

    function handlUpdateScreeningPointInfo (point) {
      updateScreeningPointInfo(point, res => {
        // console.log(res)
      })
    }

    function submit () {
      if (parseInt(props.operate) === 1) {
        pointRef.value.validate((valid) => {
          if (valid) {
            if (props.pointId === -1) {
              const list = []
              for (let i = 0; i < principals.value.count; i++) {
                if (!principals.value.principals[i].name || !principals.value.principals[i].phone) {
                  ElMessageBox.alert('请您完善所有负责人信息！', '提示', {
                    confirmButtonText: '确定'
                  })
                  return false
                } else {
                  if (!verifyingPhone(principals.value.principals[i].phone)) {
                    ElMessageBox.alert('请您输入正确的联系电话！', '提示', {
                      confirmButtonText: '确定'
                    })
                    return false
                  }
                  if (!verifyingSpecialCharacter(principals.value.principals[i].name)) {
                    ElMessageBox.alert('姓名输入请勿包含数字和特殊字符！', '提示', {
                      confirmButtonText: '确定'
                    })
                    return false
                  }
                }
                list.push({
                  name: principals.value.principals[i].name,
                  phone: principals.value.principals[i].phone
                })
              }
              if (list.length === 0) {
                ElMessageBox.alert('请您至少填写一个负责人信息！', '提示', {
                  confirmButtonText: '确定'
                })
                return false
              }
              const data = {
                screeningPoint: {
                  name: point.name,
                  typeId: point.typeId,
                  state: point.state,
                  enrollWay: point.enrollWay,
                  habitation: point.habitation,
                  address: point.address,
                  longitude: point.longitude,
                  latitude: point.latitude,
                  describe: point.describe
                },
                list: list
              }
              handleAddScreeningPoint(data)
            } else {
              const list = []
              for (let i = 0; i < principals.value.principals.length; i++) {
                if (principals.value.principals[i].enableFlag && (!principals.value.principals[i].name || !principals.value.principals[i].phone)) {
                  ElMessageBox.alert('请您完善所有负责人信息！', '提示', {
                    confirmButtonText: '确定'
                  })
                  return false
                } else {
                  if (principals.value.principals[i].enableFlag && !verifyingPhone(principals.value.principals[i].phone)) {
                    ElMessageBox.alert('请您输入正确的联系电话！', '提示', {
                      confirmButtonText: '确定'
                    })
                    return false
                  }
                  if (principals.value.principals[i].enableFlag && !verifyingSpecialCharacter(principals.value.principals[i].name)) {
                    ElMessageBox.alert('姓名输入请勿包含数字和特殊字符！', '提示', {
                      confirmButtonText: '确定'
                    })
                    return false
                  }
                }
                list.push({
                  id: principals.value.principals[i].id,
                  name: principals.value.principals[i].name,
                  phone: principals.value.principals[i].phone,
                  enableFlag: principals.value.principals[i].enableFlag
                })
              }
              if (list.length === 0) {
                ElMessageBox.alert('请您至少填写一个负责人信息！', '提示', {
                  confirmButtonText: '确定'
                })
                return false
              }
              const data = {
                screeningPoint: {
                  id: point.id,
                  name: point.name,
                  typeId: point.typeId,
                  state: point.state,
                  enrollWay: point.enrollWay,
                  habitation: point.habitation,
                  address: point.address,
                  longitude: point.longitude,
                  latitude: point.latitude,
                  describe: point.describe
                },
                list: list
              }
              handlUpdateScreeningPointInfo(data)
            }
          } else {
            ElMessageBox.alert('请您按要求填写正确信息！', '提示', {
              confirmButtonText: '确定'
            })
          }
        })
      } else {
        close()
      }
    }

    return {
      pointRef,
      close,
      point,
      typeIdOptions,
      enrollWayOptions,
      stateOptions,
      displayPrincipals,
      newPrincipals,
      handleAddPrincipal,
      handleDeletePrincipal,
      handleDeleteNewPrincipal,
      submit,
      pointRules,
      principals
    }
  }
}
</script>

<style scoped>
.frame-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
  transition: all ease-in .3s;
}
.frame-wrapper__close {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0);
  transition: all ease-in .3s;
}
.frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  max-width: 90%;
  font-size: 15px;
  background: #EDEDED;
  transition: all ease-in .5s;
  box-sizing: border-box;
  overflow: hidden;
}
.frame__close {
  position: absolute;
  padding: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  max-height: 0;
  max-width: 0;
  transition: all ease-in .5s;
  overflow: hidden;
}
.header {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  color: #FFFFFF;
  background: #136FD1;
  position: relative;
}
.header-button__text {
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
}
.main {
  padding: 15px 30px;
  background: #FFFFFF;
}
.main .main-row {
  display: flex;
  align-items: center;
}
.main .main-cell {
  display: flex;
  align-items: center;
  margin: 16px 0;
}
.main .main-cell + .main-cell {
  margin-left: 40px;
}
.main .main-cell .cell-label {
  display: inline-block;
  width: 150px;
  text-align: left;
  color: #757575;
}
.main .main-cell .cell-value {
  width: 180px;
  text-align: left;
  line-height: 20px;
}
.main .main-box-title {
  display: flex;
  align-items: center;
  color: #757575;
}
.main .main-box-title .main-box-title-cell {
  width: 115px;
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  margin-left: 12px;
  color: #FFFFFF;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
  border-radius: 17px;
}
.main .main-box-title .main-box-title-cell :deep(span) {
  display: flex;
  align-items: center;
}
.main .main-box-title .icon-add {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background: url("../../assets/images/icon__add.png") no-repeat center center;
  background-size: 100%;
}
.main .main-box-li, .main .main-box-li__add {
  display: flex;
  height: 45px;
  align-items: center;
  border-bottom: 1px solid #DDDDDD;
  position: relative;
}
.main .main-box .main-box-li-cell {
  display: flex;
  min-width: 160px;
  text-align: left;
}
.main .main-box .main-box-li__add {
  margin-top: 8px;
  border-bottom: 0;
}
.main .main-box .main-box-li__add .main-box-li-cell {
  min-width: inherit;
}
.main .main-box .main-box-li__add .main-box-li-cell + .main-box-li-cell {
  margin-left: 32px;
}
.main .main-box .main-box-li-cell .main-box-li-cell-label {
  align-items: center;
  white-space: nowrap;
  line-height: 36px;
}
.main .main-box .icon-delete {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  cursor: pointer;
  background: url("../../assets/images/icon__operate-delete.png");
}
/*.main .main-cell :deep(.el-input) {*/
/*  width: 235px;*/
/*}*/
.footer {
  height: 50px;
  line-height: 50px;
  background: #F2F3F7;
}
.footer__btn {
  color: #FFFFFF;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
}
</style>
