<template>
  <div class="screens">
    <div
      class="screen"
      :class="role.uuid === screen.uuid ? 'is-active' : ''"
      v-for="screen in screenList"
      :key="screen.uuid"
      @click="handleChangeRole(screen)"
    >
      <div class="screen__name">
        {{screen.username}}
        <span class="screen__name__self"  v-if="screen.relationship === 0">默认本人</span>
      </div>
      <div class="screen-relation">与本人关系：{{screen.relation}}</div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { getFamily } from '@/assets/api/request'
import Storage from '@/assets/js/LocalStorage'
import { relationShipMap } from '@/assets/js/config'

export default {
  name: 'ScreenChange',
  setup () {
    onMounted(() => {
      const userInfo = storage.getItem('__userInfo__')
      const uuid = route.params.uuid || undefined
      getFamily(
        {
          id: userInfo.userId
        },
        res => {
          // console.log(res)
          screenList.splice(0, screenList.length)
          if (res.data.data.user.length > 0) {
            screenList.push({
              id: res.data.data.user[0].id,
              uuid: res.data.data.user[0].uuid,
              username: res.data.data.user[0].username,
              relation: '本人',
              relationship: 0,
              phone: res.data.data.user[0].phone
            })
            role.uuid = res.data.data.user[0].uuid
            role.username = res.data.data.user[0].username
          }
          res.data.data.family.forEach(family => {
            screenList.push({
              id: family.id,
              uuid: family.uuid,
              username: family.username,
              relation: relationShipMap[family.relationship],
              relationship: family.relationship,
              phone: family.phone
            })
            if (uuid && family.uuid === uuid) {
              role.uuid = family.uuid
              role.username = family.username
            }
          })
        }
      )
    })
    const role = reactive({})
    const router = useRouter()
    const route = useRoute()
    const storage = new Storage()
    const screenList = reactive([])

    function handleChangeRole (screen) {
      role.uuid = screen.uuid
      role.username = screen.username
      router.push({
        path: '/screenRecord',
        query: {
          uuid: screen.uuid,
          username: screen.username
        }
      })
    }

    return {
      screenList,
      role,
      handleChangeRole
    }
  }
}
</script>

<style scoped>
.screen {
  margin: 20px 0;
  padding: 20px 24px;
  text-align: left;
  color: #8491B1;
  font-size: 14px;
  background: #FDFEFF;
  box-shadow: 0 1px 4px 0 rgba(10, 36, 99, 0.12), 0 0 8px 0 rgba(10, 36, 99, 0.06);
  border-radius: 8px;
  position: relative;
}
.screen:after {
  content: '';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #F3F3F3;
  border-radius: 50%;
}
.screen.is-active:after {
  background: url("../../../../assets/images/checked.png") no-repeat center center;
  background-size: 100%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
.screen__name {
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 600;
  color: #0A2463;
}
.screen__name__self {
  margin-left: 10px;
  font-size: 14px;
  color: #8491B1;
}
.screens {
  height: 100%;
  padding: 0 16px;
  overflow-y: scroll;
}
</style>
