<template>
  <teleport to="#main">
    <div :class="show ? 'frame-wrapper' : 'frame-wrapper__close'">
      <div :class="show ? 'frame' : 'frame__close'">
        <header class="header">
          新增用户
          <el-button type="text" class="header-button__text" @click="close">关闭</el-button>
        </header>
        <div class="main">
          <div class="main-cell">
            <span class="cell-label">用户类型：</span>
            <el-select v-model="userInfo.userType" placeholder="请选择用户类型">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="main-cell">
            <span class="cell-label">筛查点：</span>
            <el-select v-model="userInfo.point" placeholder="请选择筛查点">
              <el-option
                v-for="item in pointOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="main-cell">
            <span class="cell-label">用户名：</span>
            <el-input v-model="userInfo.username"></el-input>
          </div>
          <div class="main-cell">
            <span class="cell-label">手机号：</span>
            <el-input v-model="userInfo.phone"></el-input>
          </div>
          <div class="main-cell">
            <span class="cell-label">密码：</span>
            <el-input v-model="userInfo.password" type="password"></el-input>
          </div>
          <div class="main-cell">
            <span class="cell-label">确认密码：</span>
            <el-input v-model="userInfo.rePassword" type="password"></el-input>
          </div>
        </div>
        <div class="footer">
          <el-button class="footer__btn">确定</el-button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { reactive } from 'vue'

export default {
  name: 'Add',
  emits: ['update:show'],
  props: {
    show: Boolean
  },
  setup (props, context) {
    const userInfo = reactive({
      userType: 0,
      point: '',
      username: '',
      phone: '',
      password: '',
      rePassword: ''
    })
    const typeOptions = reactive([
      {
        value: 0,
        label: '系统管理员'
      },
      {
        value: 1,
        label: '医院管理员'
      },
      {
        value: 2,
        label: '社康管理员'
      },
      {
        value: 3,
        label: '鸿美工作人员'
      }
    ])
    const pointOptions = reactive([
      {
        value: 1,
        label: '前海时代社区健康服务中心'
      },
      {
        value: 2,
        label: '深大总医院'
      }
    ])

    function close () {
      context.emit('update:show', false)
    }

    return {
      close,
      userInfo,
      typeOptions,
      pointOptions
    }
  }
}
</script>

<style scoped>
.frame-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9;
  transition: all ease-in .3s;
}
.frame-wrapper__close {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0);
  transition: all ease-in .3s;
}
.frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  max-width: 90%;
  font-size: 15px;
  background: #EDEDED;
  transition: all ease-in .5s;
  box-sizing: border-box;
  overflow: hidden;
}
.frame__close {
  position: absolute;
  padding: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  max-height: 0;
  max-width: 0;
  transition: all ease-in .5s;
  overflow: hidden;
}
.header {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  color: #FFFFFF;
  background: #136FD1;
  position: relative;
}
.header-button__text {
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
}
.main {
  padding: 15px 150px;
  background: #FFFFFF;
}
.main .main-cell {
  display: flex;
  align-items: center;
  margin: 16px 0;
}
.main .main-cell .cell-label {
  /*display: inline-block;*/
  width: 100px;
  text-align: left;
}
.main .main-cell :deep(.el-input) {
  width: 235px;
}
.footer {
  height: 50px;
  line-height: 50px;
  background: #F2F3F7;
}
.footer__btn {
  color: #FFFFFF;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
}
</style>
