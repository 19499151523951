<template>
  <header class="header">
    <div class="header-top">
      <div class="header-block">
        筛查点：
        <el-select v-model="planInfo.pointName" @change="handleChangePoint" placeholder="请选择筛查点">
          <el-option
            v-for="item in pointList.data"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <span class="header-block">所属地：{{planInfo.pointName ? planInfo.habitation : '请选择筛查点'}}</span>
      <span class="header-block">报名方式：{{planInfo.pointName ? parseInt(planInfo.enrollWay) ? '仅支持线上报名' : '支持现场和线上报名' : '请选择筛查点'}}</span>
      <div class="back" @click="back">
        <i class="icon icon__back"></i>
        返回
      </div>
    </div>
    <div class="header-bottom">
      <div class="header-block">
        计划名称：
        <el-input v-model="planInfo.planName" placeholder="请输入计划名称"></el-input>
      </div>
      <div class="header-block">
        制定人员：
        <el-input v-model="planInfo.creator" placeholder="请输入制定人员姓名"></el-input>
      </div>
      <div class="header-block">
        计划状态：
        <el-switch
          v-model="planInfo.state"
          active-color="#136FD1"
          inactive-color="#898989"
        />
        {{planInfo.state ? '开启' : '结束'}}
      </div>
      <div class="header-block">
        计划开启日期：
        <el-date-picker
          v-model="planInfo.startDay"
          type="date"
          :disabled-date="(date) => {return date.getTime() < new Date().getTime()}"
          :clearable="false"
        >
        </el-date-picker>
      </div>
    </div>
  </header>
  <div class="main-title" v-if="planInfo.startDay">
    <span>预约计划</span>
<!--    <el-button class="header-button" @click="handleOpenSetting">{{editFlag ? '保存设置' : '开启设置'}}</el-button>-->
  </div>
  <main class="main" v-if="planInfo.startDay">
    <div class="main-left">
      <div class="main-left-top">
        <div class="box-title-wrapper">
          <span class="box-title">线上预约设置</span>
          <span class="check-date" v-if="editPlanList.data.length === 1">{{getFormatDate(editPlanList.data[0])}}</span>
        </div>
        <div>
          <div class="box-item">
            <span class="box-item__label">上午：</span>
            <div class="box-item__value">
              <span>{{setting.onLine.setting ? '筛查 ' : '休息 '}}</span>
              <el-switch
                v-model="setting.onLine.setting"
                @change="handleOnLineRest"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
            </div>
          </div>
          <div class="box-item" v-if="setting.onLine.setting">
            <span class="box-item__label">起止时段：</span>
            <div class="box-item__value">
              <el-time-picker
                v-model="setting.onLine.time"
                is-range
                :disabled-hours="disableHours"
                :disabled-minutes="disableMinutesAndSeconds"
                :disabled-seconds="disableMinutesAndSeconds"
                range-separator="~"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              >
              </el-time-picker>
            </div>
          </div>
          <div class="box-item" v-if="setting.onLine.setting">
            <span class="box-item__label">可预约筛查人数：</span>
            <el-input class="box-item__value" v-model="setting.onLine.num">
              <template #append>人</template>
            </el-input>
          </div>
        </div>
      </div>
      <div class="main-left-bottom">
        <div class="box-title-wrapper">
          <span class="box-title">现场报名预约设置</span>
        </div>
        <div>
          <div class="box-item">
            <span class="box-item__label">上午：</span>
            <span>{{setting.live.setting ? '筛查 ' : '休息 '}}</span>
            <el-switch
              v-model="setting.live.setting"
              @change="handleLiveRest"
              active-color="#13ce66"
              inactive-color="#ff4949"
            />
          </div>
          <div class="box-item" v-if="setting.live.setting">
            <span class="box-item__label">起止时段：</span>
            <div class="box-item__value">
              <el-time-picker
                v-model="setting.live.time"
                is-range
                :disabled-hours="disableHours"
                :disabled-minutes="disableMinutesAndSeconds"
                :disabled-seconds="disableMinutesAndSeconds"
                range-separator="~"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              >
              </el-time-picker>
            </div>
          </div>
          <div class="box-item" v-if="setting.live.setting">
            <span class="box-item__label">可现场报名预约筛查人数：</span>
            <el-input class="box-item__value" v-model="setting.live.num">
              <template #append>人</template>
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="main-right">
      <div class="calendar-picker">
        <div class="calendar__header">
          <div class="calendar__header-group">
            <i class="calendar__header-group__button icon-prev" @click="handlePrevYear"></i>
            <span class="calendar__header-group__text">{{date.selectYear}}年</span>
            <i class="calendar__header-group__button icon-next" @click="handleNextYear"></i>
          </div>
          <div class="calendar__header-group">
            <i class="calendar__header-group__button icon-prev" @click="handlePrevMonth"></i>
            <span class="calendar__header-group__text">{{date.selectMonth}}月</span>
            <i class="calendar__header-group__button icon-next" @click="handleNextMonth"></i>
          </div>
        </div>
        <div class="calendar__body">
          <table class="calendar-table">
            <thead>
            <tr>
              <th>一</th>
              <th>二</th>
              <th>三</th>
              <th>四</th>
              <th>五</th>
              <th>六</th>
              <th>日</th>
            </tr>
            </thead>
            <tbody>
            <tr class="calendar-table__row" v-for="(line, index) in dateList" :key="'line' + index">
              <td v-for="count in line" :class="dayCls(index, count)" :key="'day' + count.value">
                <div class="calendar-day" @click="handleSelectDate(index, count)">
                  <div class="calendar-day__inner">
                    <div class="calendar-day__inner-count">{{count.value}}</div>
                    <div class="calendar-day__plan">
                      <span>{{count.planStatus}}</span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
  <el-button class="button__submit" @click="handleSubmit">确认</el-button>
</template>

<script>
import { reactive, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { pad, verifyingSpecialCharacter } from '@/components/backend/assets/js/util'
import { getScreeningPointList, addPlan } from '@/components/backend/assets/api/request'
import { ElMessageBox } from 'element-plus'

const WEEK = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']

export default {
  name: 'recordAdd',
  setup () {
    onMounted(() => {
      handleGetScreeningPointList()
    })
    const router = useRouter()
    const editPlanList = reactive({ data: [] })
    const setting = reactive({
      onLine: {
        setting: true,
        time: [],
        num: 0
      },
      live: {
        setting: true,
        time: [],
        num: 0
      }
    })
    const disableMinutesAndSeconds = () => {
      const result = []
      for (let i = 1; i < 60; i++) {
        result.push(i)
      }
      return result
    }
    const disableHours = () => {
      const result = []
      for (let i = 0; i < 7; i++) {
        result.push(i)
      }
      for (let i = 13; i < 24; i++) {
        result.push(i)
      }
      return result
    }
    const planInfo = reactive({
      pointName: '',
      habitation: '',
      enrollWay: '',
      pointId: '',
      planName: '',
      creator: '',
      state: false,
      startDay: ''
    })
    const pointList = reactive({ data: [] })
    let pointListNameMap = []
    const today = computed(() => {
      return planInfo.startDay ? planInfo.startDay : new Date()
    })
    const date = reactive({
      currentDay: today.value.getDate(),
      currentMonth: today.value.getMonth() + 1,
      currentYear: today.value.getFullYear(),
      selectMonth: today.value.getMonth() + 1,
      selectYear: today.value.getFullYear(),
      selectDay: 25
    })
    const preMonthDays = computed(() => {
      return new Date(date.selectYear, date.selectMonth - 1, 0).getDate()
    })
    const currentDays = computed(() => {
      return new Date(date.selectYear, date.selectMonth, 0).getDate()
    })
    const offsetDay = computed(() => {
      return (new Date(date.selectYear, date.selectMonth - 1, 1).getDay() - 1 + 7) % 7
    })

    const planList = reactive({ data: [] })

    const planDateList = reactive([])

    const dateList = computed(() => {
      const result = []
      const toDayTime = today.value.getTime()
      result[0] = []
      for (let i = 0; i < offsetDay.value; i++) {
        result[0].push({
          value: preMonthDays.value - i
        })
      }
      result[0].reverse()
      for (let i = 0; i < 7 - offsetDay.value; i++) {
        const lineOneTime = new Date(date.selectYear, date.selectMonth - 1, i + 1).getTime()
        const index = planDateList.indexOf(lineOneTime)
        if (index !== -1) {
          result[0].push({
            value: i + 1,
            plan: planList.data[index],
            planStatus: planList.data[index].rest ? '休息' : lineOneTime < toDayTime ? '已完成' : '已安排'
          })
        } else {
          result[0].push({
            value: i + 1,
            planStatus: lineOneTime < toDayTime ? '' : '未安排'
          })
        }
      }
      for (let i = 1; i < 4; i++) {
        result[i] = []
        for (let j = 1; j <= 7; j++) {
          const lineTwoToFourTime = new Date(date.selectYear, date.selectMonth - 1, j + (7 - offsetDay.value) + 7 * (i - 1)).getTime()
          const index = planDateList.indexOf(lineTwoToFourTime)
          if (index !== -1) {
            result[i].push({
              value: j + (7 - offsetDay.value) + 7 * (i - 1),
              plan: planList.data[index],
              planStatus: planList.data[index].rest ? '休息' : lineTwoToFourTime < toDayTime ? '已完成' : '已安排'
            })
          } else {
            result[i].push({
              value: j + (7 - offsetDay.value) + 7 * (i - 1),
              planStatus: lineTwoToFourTime < toDayTime ? '' : '未安排'
            })
          }
        }
      }
      result[4] = []
      const len = currentDays.value - ((7 - offsetDay.value) + 21) <= 7 ? currentDays.value - ((7 - offsetDay.value) + 21) : 7
      for (let i = 1; i <= len; i++) {
        const lineFiveTime = new Date(date.selectYear, date.selectMonth - 1, i + (7 - offsetDay.value) + 7 * 3).getTime()
        const index = planDateList.indexOf(lineFiveTime)
        if (index !== -1) {
          result[4].push({
            value: i + (7 - offsetDay.value) + 7 * 3,
            plan: planList.data[index],
            planStatus: planList.data[index].rest ? '休息' : lineFiveTime < toDayTime ? '已完成' : '已安排'
          })
        } else {
          result[4].push({
            value: i + (7 - offsetDay.value) + 7 * 3,
            planStatus: lineFiveTime < toDayTime ? '' : '未安排'
          })
        }
      }
      const lenFour = result[4].length
      if (lenFour !== 7) {
        for (let i = 1; i <= 7 - lenFour; i++) {
          const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth, i).getTime())
          if (index !== -1) {
            result[4].push({
              value: i,
              plan: planList.data[index]
            })
          } else {
            result[4].push({
              value: i
            })
          }
        }
        result[5] = []
        for (let i = 7 - lenFour + 1; i <= 7 + 7 - lenFour; i++) {
          result[5].push({
            value: i
          })
        }
      } else {
        result[5] = []
        for (let i = 5 * 7 - offsetDay.value + 1; i <= currentDays.value; i++) {
          const lineSixTime = new Date(date.selectYear, date.selectMonth - 1, i).getTime()
          const index = planDateList.indexOf(lineSixTime)
          if (index !== -1) {
            result[5].push({
              value: i,
              plan: planList.data[index],
              planStatus: planList.data[index].rest ? '休息' : lineSixTime < toDayTime ? '已完成' : '已安排'
            })
          } else {
            result[5].push({
              value: i,
              planStatus: lineSixTime < toDayTime ? '' : '未安排'
            })
          }
        }
        const len5 = result[5].length
        for (let i = 1; i <= 7 - len5; i++) {
          const index = planDateList.indexOf(new Date(date.selectYear, date.selectMonth, i).getTime())
          if (index !== -1) {
            result[5].push({
              value: i,
              plan: planList.data[index]
            })
          } else {
            result[5].push({
              value: i
            })
          }
        }
      }
      return result
    })

    function dayCls (index, count) {
      if (index === 0 && count.value > 7) {
        return 'prev'
      } else if ((index === 4 || index === 5) && count.value <= 14) {
        return 'next'
      } else {
        if (editPlanList.data.indexOf(new Date(date.selectYear, date.selectMonth - 1, count.value).getTime()) !== -1) {
          if (count.plan) {
            return 'current is-plan is-active'
          } else {
            return 'current is-active'
          }
        } else {
          return 'current'
        }
      }
    }

    function handleOnLineRest (value) {
      if (!value) {
        setting.onLine.num = 0
        setting.onLine.time[0] = new Date(date.currentYear, date.currentMonth - 1, date.currentDay, 7, 0)
        setting.onLine.time[1] = new Date(date.currentYear, date.currentMonth - 1, date.currentDay, 7, 0)
      }
    }

    function handleLiveRest (value) {
      if (!value) {
        setting.live.num = 0
        setting.live.time[0] = new Date(date.currentYear, date.currentMonth - 1, date.currentDay, 7, 0)
        setting.live.time[1] = new Date(date.currentYear, date.currentMonth - 1, date.currentDay, 7, 0)
      }
    }

    function handlePrevYear () {
      date.selectYear--
    }

    function handleNextYear () {
      date.selectYear++
    }

    function handlePrevMonth () {
      if (date.selectMonth === 1) {
        date.selectYear--
        date.selectMonth = 12
      } else {
        date.selectMonth--
      }
    }

    function handleNextMonth () {
      if (date.selectMonth === 12) {
        date.selectYear++
        date.selectMonth = 1
      } else {
        date.selectMonth++
      }
    }

    function handleSelectDate (line, count) {
      if (line === 0 && count.value > 7) {
        handlePrevMonth()
      } else if ((line === 4 || line === 5) && count.value <= 14) {
        handleNextMonth()
      } else {
        // 编辑预约设置
        const index = editPlanList.data.indexOf(new Date(date.selectYear, date.selectMonth - 1, count.value).getTime())
        if (index === -1) {
          let f = false
          for (let i = 0; i < planList.data.length; i++) {
            if (new Date(date.selectYear, date.selectMonth - 1, count.value).getTime() === planList.data[i].dateTamp) {
              editPlanList.data.push(planList.data[i].dateTamp)
              f = true
              break
            }
          }
          if (!f) {
            editPlanList.data.push(new Date(date.selectYear, date.selectMonth - 1, count.value).getTime())
          }
        } else {
          editPlanList.data.splice(index, 1)
        }
      }
    }

    function getFormatDate (dateStr) {
      const date = new Date(dateStr)
      return `${date.getFullYear()}年${pad(date.getMonth() + 1)}月${pad(date.getDate())}号 ${WEEK[date.getDay()]}`
    }

    /**
     * 获取筛查点列表
     */
    function handleGetScreeningPointList () {
      getScreeningPointList(
        {
          pageNum: 1,
          pageSize: 999
        },
        res => {
          pointList.data.push(...res.data.data.list)
          pointListNameMap = pointList.data.map(point => point.name)
        }
      )
    }

    /**
     * 选择筛查点时触发，更改筛查点信息
     */
    function handleChangePoint (value) {
      const index = pointListNameMap.indexOf(value)
      if (index !== -1) {
        planInfo.habitation = pointList.data[index].habitation
        planInfo.enrollWay = pointList.data[index].enrollWay
        planInfo.pointId = pointList.data[index].id
      } else {
        ElMessageBox.alert('筛查点信息有误，请重试！', '错误', {
          confirmButtonText: '确定',
          type: 'error'
        }).then(() => {
          handleGetScreeningPointList()
        })
      }
    }

    /**
     * 提交新增
     */
    function handleSubmit () {
      if (!planInfo.startDay) {
        ElMessageBox.alert('请设置计划开启时间！', '错误', {
          confirmButtonText: '确定',
          type: 'error'
        })
        return
      }
      if (!planInfo.planName) {
        ElMessageBox.alert('请完善计划名称！', '错误', {
          confirmButtonText: '确定',
          type: 'error'
        })
        return
      } else {
        if (!verifyingSpecialCharacter(planInfo.planName)) {
          ElMessageBox.alert('计划名称请勿携带特殊字符！', '错误', {
            confirmButtonText: '确定',
            type: 'error'
          })
          return
        }
      }
      if (!planInfo.creator) {
        ElMessageBox.alert('请完善制定人员！', '错误', {
          confirmButtonText: '确定',
          type: 'error'
        })
        return
      } else {
        if (!verifyingSpecialCharacter(planInfo.creator)) {
          ElMessageBox.alert('制定人员名称请勿携带特殊字符！', '错误', {
            confirmButtonText: '确定',
            type: 'error'
          })
          return
        }
      }
      if (!planInfo.pointName) {
        ElMessageBox.alert('请选择筛查点！', '错误', {
          confirmButtonText: '确定',
          type: 'error'
        })
      }

      ElMessageBox.confirm('是否确认开启该计划！', '开启筛查计划提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        const screeningPlan = { ...planInfo }
        screeningPlan.state = screeningPlan.state ? 1 : 0
        screeningPlan.startDay = screeningPlan.startDay.getTime()

        const tamp1 = setting.onLine.time[0].getTime()
        const tamp2 = setting.onLine.time[1].getTime()
        const dist = tamp2 - tamp1
        const count = dist / (60 * 60 * 1000)
        const list = []

        for (let i = 0; i < editPlanList.data.length; i++) {
          let startTimeHour = setting.onLine.time[0].getHours()
          if (count === 0) {
            if (setting.live.num === 0) {
              list.push({
                planDate: editPlanList.data[i],
                enrollWay: planInfo.enrollWay,
                state: setting.onLine.setting ? 1 : 0,
                startTime: '',
                endTime: '',
                startTimeLive: '',
                endTimeLive: '',
                maxNumber: 0,
                maxNumberLive: setting.live.num
              })
            } else {
              list.push({
                planDate: editPlanList.data[i],
                enrollWay: planInfo.enrollWay,
                state: setting.onLine.setting ? 1 : 0,
                startTime: '',
                endTime: '',
                startTimeLive: `${pad(setting.live.time[0].getHours())}:00`,
                endTimeLive: `${pad(setting.live.time[1].getHours())}:00`,
                maxNumber: 0,
                maxNumberLive: setting.live.num
              })
            }
          } else {
            for (let j = 0; j < count; j++) {
              list.push({
                planDate: editPlanList.data[i],
                startTime: `${pad(startTimeHour)}:00`,
                endTime: `${pad(startTimeHour + 1)}:00`,
                enrollWay: planInfo.enrollWay,
                startTimeLive: `${pad(setting.live.time[0].getHours())}:00`,
                endTimeLive: `${pad(setting.live.time[1].getHours())}:00`,
                maxNumber: Math.floor(setting.onLine.num / count),
                maxNumberLive: setting.live.num,
                state: setting.onLine.setting ? 1 : 0
              })
              startTimeHour++
            }
            list[(i + 1) * count - 1].maxNumber += setting.onLine.num % count
          }
        }
        const data = {
          screeningPlan,
          list
        }
        if (list.length === 0) {
          ElMessageBox.confirm('您并没有对日期安排计划，确认新增该筛查计划？', '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            handleAddPlan(data)
          }).catch(() => {})
        } else {
          handleAddPlan(data)
        }
      }).catch(() => {})
    }

    /**
     * 新增筛查计划
     * @param data
     */
    function handleAddPlan (data) {
      addPlan(data, res => {
        ElMessageBox.alert('新增计划成功！即将返回计划列表', '开启成功', {
          confirmButtonText: '确定',
          type: 'success'
        }).then(() => {
          back()
        })
      })
    }

    function back () {
      router.push({
        name: 'recordOpen'
      })
    }

    return {
      editPlanList,
      setting,
      disableMinutesAndSeconds,
      disableHours,
      planInfo,
      pointList,
      date,
      dateList,
      dayCls,
      handleOnLineRest,
      handleLiveRest,
      handlePrevYear,
      handleNextYear,
      handlePrevMonth,
      handleNextMonth,
      handleSelectDate,
      getFormatDate,
      handleChangePoint,
      handleSubmit,
      back
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  margin-top: -30px;
  flex-direction: column;
  /*align-items: center;*/
  /*justify-content: space-between;*/
}
.header-top {
  position: relative;
  border-bottom: 1px solid #DDDDDD;
}
.header-top, .header-bottom {
  display: flex;
  height: 62px;
  align-items: center;
  /*justify-content: space-between;*/
}
.header-block {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.header-block :deep(.el-input__inner) {
  color: #136FD1;
}
.header-block + .header-block {
  margin-left: 80px;
}
.back {
  position: absolute;
  top: 50%;
  right: 0;
  height: 34px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.icon__back {
  display: inline-block;
  width: 29px;
  height: 34px;
  margin-right: 8px;
  background: url("../../../assets/images/icon__back.png") no-repeat center center;
  background-size: 85%;
}
.main-title {
  display: flex;
  align-items: center;
}
.main-title span {
  font-size: 16px;
  font-weight: 700;
  color: #0A2463;
}
.header-button {
  min-height: 36px;
  line-height: 36px;
  padding: 0 20px;
  margin-left: 100px;
  color: #FFFFFF;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
}
.main {
  margin-top: 2%;
  display: flex;
  /*justify-content: space-between;*/
}
.main-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.main-left-top, .main-left-bottom {
  padding: 0 25px;
  width: 350px;
  box-sizing: border-box;
  background: #FFFFFF;
}
.main-left-bottom {
  margin-top: 21px;
}
.box-item {
  margin: 30px 0;
  text-align: left;
}
.box-item {
  display: flex;
  align-items: center;
}
.box-title-wrapper {
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-title {
  font-size: 14px;
  color: #757575;
}
.check-date {
  color: #0A2463;
}
.box-item__label {
  width: 112px;
  line-height: 1.7;
  font-size: 14px;
  color: #0A2463;
}
.box-item__value {
  display: flex;
  margin-left: 10px;
  max-width: 200px;
  align-items: center;
  color: #136FD1;
}
.box-item__value :deep(.el-input__inner) {
  height: 32px;
}
.box-item__value :deep(.el-input-group__append) {
  height: 30px;
  line-height: 32px;
}
.main-right {
  margin-left: 3%;
  display: flex;
  align-items: center;
}
.calendar-picker {
  width: 777px;
  height: 463px;
  padding: 30px;
  font-size: 15px;
  background-color: #FFFFFF;
}
.calendar__header {
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
}
.calendar__header-group {
  display: flex;
  align-items: center;
}
.refresh {
  padding: 5px 10px;
  font-size: 10px;
  border-radius: 2px;
  color: #136FD1;
  border: 1px solid #8491B1;
  letter-spacing: 2px;
}
.calendar__header-group__text {
  padding: 0 25px;
  color: #0A2463;
  font-size: 15px;
}
.calendar__header-group__button {
  width: 9px;
  height: 18px;
  cursor: pointer;
}
.icon-prev {
  background: url("../../../assets/images/icon__prev.png") no-repeat center center;
  background-size: 100%;
}
.icon-next {
  background: url("../../../assets/images/icon__next.png") no-repeat center center;
  background-size: 100%;
}
.calendar-table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}
.calendar-table thead th {
  padding: 12px 0;
  font-weight: 400;
  color: #0A2463;
}
.calendar__body .prev, .calendar__body .current, .calendar__body .next {
  padding: 8px;
  text-align: center;
}
.calendar-table .calendar-day {
  display: inline-block;
  box-sizing: border-box;
  line-height: 25px;
  vertical-align: middle;
  color: #8491B1;
}

.calendar-day .calendar-day__inner {
  height: 45px;
}
.calendar-day .calendar-day__inner-count {
  width: 25px;
  height: 25px;
  margin: 0 auto;
  border-radius: 2px;
  cursor: pointer;
}
.current.is-plan .calendar-day .calendar-day__inner-count {
  color: #136FD1;
}
.current .calendar-day .calendar-day__inner-count:hover {
  background: rgba(19, 111, 209, 0.8);
  color: #FFFFFF;
}
.is-active .calendar-day .calendar-day__inner-count {
  background: #136FD1;
  color: #FFFFFF !important;
}
.button__submit {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 301px;
  height: 36px;
  background: linear-gradient(315deg, #136FD1 0%, #53A4FA 100%);
  box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 15px;
}
</style>
